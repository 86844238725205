import { SubtitleType } from '../interfaces/subtitle';
/**
 * @description
 * The subtitles with the highest priority for translation are those
 * uploaded, then transcribed and finally translated
 */
export const chooseTheSubtitleToBeTranslated = (subtitles) => {
    return (subtitles.find((subtitle) => subtitle.type === SubtitleType.UPLOAD) ||
        subtitles.find((subtitle) => subtitle.type === SubtitleType.TRANSCRIPTION) ||
        subtitles.find((subtitle) => subtitle.type === SubtitleType.TRANSLATION));
};
