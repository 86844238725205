import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Checkbox } from 'src/ui/Checkbox';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
export const DeleteItemsModal = ({ title, description, checkConfirmDescription, buttons, onDismiss, }) => {
    const [isConfirmDeleteChecked, setIsConfirmDeleteChecked] = useState(false);
    const modalRef = useRef(null);
    const handleButtonOnClick = (callback) => {
        callback?.();
        modalRef.current?.close();
    };
    return (_jsxs(Modal, { onCloseModal: onDismiss, opened: true, ref: modalRef, children: [_jsx(Heading, { slot: 'heading', size: 'small', children: title }), _jsx(Paragraph, { slot: 'description', size: 'micro', children: description }), _jsx(Paragraph, { slot: 'description', size: 'micro', children: _jsx(Checkbox, { id: 'confirmation_folder_delete_checkbox', onChange: (e) => setIsConfirmDeleteChecked(e.target.checked), label: checkConfirmDescription }) }), _jsx(Button, { slot: 'controls', variation: 'tertiary', context: 'neutral', onClick: () => handleButtonOnClick(buttons.no?.onClick), children: buttons.no.title }), _jsx(Button, { slot: 'controls', variation: 'primary', context: 'danger', disabled: !isConfirmDeleteChecked, onClick: () => {
                    handleButtonOnClick(buttons.yes.onClick);
                }, "data-testid": 'modal-confirm-delete-button', children: buttons.yes.title })] }));
};
