// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G6RI0x6sYgKcvtcWBN17{--hc-p-margin: 0;display:flex;flex-direction:column;gap:.75rem;margin-bottom:1.125rem}.G6RI0x6sYgKcvtcWBN17 .H3uhZ6SbSkcrY4FnCA6w{display:flex;gap:3.25rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/SubtitleTypeSelector/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,sBAAA,CAEA,4CACE,YAAA,CACA,WAAA","sourcesContent":[".container {\n  --hc-p-margin: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n  margin-bottom: 1.125rem;\n\n  .radios {\n    display: flex;\n    gap: 3.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `G6RI0x6sYgKcvtcWBN17`,
	"radios": `H3uhZ6SbSkcrY4FnCA6w`
};
export default ___CSS_LOADER_EXPORT___;
