import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import MediaService from 'src/domain/Media/services/MediaService';
export const useMediaUrl = (media, isEditMode = false) => {
    const generateMediaUrl = async () => {
        const url = new URL(`${process.env.APP_PLAYER_EMBED}/embed/${media.code}`);
        const searchParams = new URLSearchParams({
            cur: media.name,
        });
        if (isEditMode)
            searchParams.append('playerControllerEditMode', 'true');
        if (!media.isPublic) {
            const { token, appCode } = await MediaService.getSignature({
                code: media.code,
            });
            searchParams.append('applicationCode', appCode);
            searchParams.append('jwtToken', token);
        }
        return `${url}?${searchParams}`;
    };
    return useQuery({
        queryKey: [QueryKeys.MEDIA_URL, isEditMode, media.code],
        queryFn: () => generateMediaUrl(),
        staleTime: 60 * 1000 * 30, // 30 min
    });
};
