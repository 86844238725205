import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import { faFolderOpen, faObjectsColumn, } from '@fortawesome/pro-light-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem } from 'src/ui/cosmos/Structure';
const SideBar = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Link, { to: '/', onClick: () => {
                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.PAGE_HOME);
                }, children: _jsx(MenuItem, { "data-testid": 'side-menu-item-panel', active: pathname === '/', icon: faObjectsColumn, text: t('sidebar.menu.panel') }) }), _jsx(Link, { to: '/library', onClick: () => {
                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.PAGE_LIBRARY);
                }, children: _jsx(MenuItem, { "data-testid": 'side-menu-item-library', active: pathname.startsWith('/library'), icon: faFolderOpen, text: t('sidebar.menu.library') }) })] }));
};
export default SideBar;
