import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { Loading } from 'src/ui/cosmos/Loading';
import AuthService from 'src/infrastructure/services/Auth';
import styles from './styles.module.scss';
export const AuthContext = createContext({});
export default function AuthProvider({ children }) {
    const [userAuth, setUserAuth] = useState(undefined);
    const onSubscribe = (user) => {
        setUserAuth((old) => {
            if (!old) {
                return user;
            }
            return old;
        });
    };
    useEffect(() => {
        AuthService.subscribe(onSubscribe).then((bootstrap) => {
            bootstrap.start();
        });
    }, []);
    if (!userAuth)
        return (_jsx("div", { className: styles.container, children: _jsx(Loading, { full: true }) }));
    return (_jsx(AuthContext.Provider, { value: userAuth, children: children }));
}
