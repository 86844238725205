// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zkMqXPNdjDJuPxLQJfmQ{display:flex;flex:1;align-items:center;gap:var(--hc-size-spacing-4);padding:var(--hc-size-spacing-1) var(--hc-size-spacing-4);opacity:.4}`, "",{"version":3,"sources":["webpack://./src/domain/Folder/containers/Modal/MoveModal/MediaItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,4BAAA,CACA,yDAAA,CACA,UAAA","sourcesContent":[".media {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  gap: var(--hc-size-spacing-4);\n  padding: var(--hc-size-spacing-1) var(--hc-size-spacing-4);\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"media": `zkMqXPNdjDJuPxLQJfmQ`
};
export default ___CSS_LOADER_EXPORT___;
