// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FfeSyax_dbV8LFmwtkba{display:flex;justify-content:flex-start}.FfeSyax_dbV8LFmwtkba span{font-size:var(--hc-size-text-5extralarge);font-weight:var(--hc-font-weight-light);line-height:var(--hc-font-line-height-large)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartContentText/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CAEA,2BACE,yCAAA,CACA,uCAAA,CACA,4CAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-start;\n\n  span {\n    font-size: var(--hc-size-text-5extralarge);\n    font-weight: var(--hc-font-weight-light);\n    line-height: var(--hc-font-line-height-large);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FfeSyax_dbV8LFmwtkba`
};
export default ___CSS_LOADER_EXPORT___;
