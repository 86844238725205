import { jsx as _jsx } from "react/jsx-runtime";
import '@hotmart-org-ca/cosmos/dist/alert/variations/info.css';
import '@hotmart-org-ca/cosmos/dist/alert/variations/success.css';
import '@hotmart-org-ca/cosmos/dist/alert/variations/danger.css';
import '@hotmart-org-ca/cosmos/dist/alert/variations/warning.css';
export const Toast = () => {
    return _jsx("hot-toast", {});
};
const buildToastPayload = ({ message, position = 'top right', dismissible = true, }) => ({
    message,
    position,
    dismissible,
});
Toast.danger = async ({ ...props }) => {
    const ToastUtils = (await import('@hotmart-org-ca/cosmos/dist/toast/utils'))
        .default;
    ToastUtils.danger(buildToastPayload({ ...props }));
};
Toast.error = Toast.danger;
Toast.info = async ({ ...props }) => {
    const ToastUtils = (await import('@hotmart-org-ca/cosmos/dist/toast/utils'))
        .default;
    ToastUtils.info(buildToastPayload({ ...props }));
};
Toast.success = async ({ ...props }) => {
    const ToastUtils = (await import('@hotmart-org-ca/cosmos/dist/toast/utils'))
        .default;
    ToastUtils.success(buildToastPayload({ ...props }));
};
Toast.warning = async ({ ...props }) => {
    const ToastUtils = (await import('@hotmart-org-ca/cosmos/dist/toast/utils'))
        .default;
    ToastUtils.warning(buildToastPayload({ ...props }));
};
