import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { Toast } from 'src/ui/cosmos/Toast';
import ApiError from 'src/infrastructure/services/Api/ApiError';
import { useTranslation } from 'react-i18next';
import FoldersService from '../../services/FoldersService';
const useRenameFolder = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: ({ folder, name, }) => FoldersService.updateFolder({ code: folder.code, name }),
        onSuccess: (response, { folder, name }) => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.LIBRARY],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SEARCH],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_ITEMS],
            });
            const message = t('library.modal.rename_item.notify.folder', {
                old_name: folder.name,
                new_name: name,
            });
            Toast.info({ message });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
};
export default useRenameFolder;
