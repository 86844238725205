import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
const BasicAlertModal = NiceModal.create(({ title, description, buttons, onDismiss }) => {
    const modal = useModal();
    return (_jsxs(Modal, { onCloseModal: () => {
            modal.remove();
            onDismiss?.();
        }, opened: modal.visible, role: 'dialog', "data-testid": 'basic-alert-modal', children: [_jsx(Heading, { slot: 'heading', size: 'small', children: title }), _jsx(Paragraph, { slot: 'description', size: 'micro', children: description }), _jsx(Button, { slot: 'controls', variation: buttons.ok.color, context: buttons.ok.context, onClick: () => {
                    modal.hide();
                }, children: buttons.ok.title })] }));
});
export default BasicAlertModal;
