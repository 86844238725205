import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Loading } from 'src/ui/cosmos/Loading';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useFolderLatest } from 'src/domain/Folder/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../components/Card';
import { CardList } from '../../components/CardList';
export const FoldersPreview = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isLoading, isSuccess, data: folders } = useFolderLatest();
    const isEmpty = !isLoading && folders?.length === 0;
    return (_jsxs(CardList.Root, { children: [_jsxs(CardList.Header, { children: [_jsx(CardList.Header.Title, { children: t('home.buttons.recent_folders') }), _jsx(CardList.Header.RightButton, { onClick: () => {
                            navigate('library?filter=folders');
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SHOW_ALL);
                        }, children: t(isEmpty ? 'home.buttons.go_to_library' : 'home.buttons.see_all') })] }), isLoading && _jsx(Loading, { full: true }), isEmpty && (_jsx(Paragraph, { children: t('home.latest_updates_preview.empty') })), isSuccess && !isEmpty && (_jsx(CardList.CardsWrapper, { children: folders.map((folder) => (_jsx(Card, { item: folder, type: 'folder' }, folder.code))) }))] }));
};
