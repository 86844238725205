import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faSpinnerThird } from 'src/application/icons/fontAwesome/regular';
import audioPlayImg from 'public/assets/svg/audio_play.svg';
import styles from './styles.module.scss';
const AudioImage = () => {
    return (_jsx("div", { className: styles.audioThumb, children: _jsx("img", { src: audioPlayImg, alt: '', width: 16, height: 16 }) }));
};
const ThumbnailImage = ({ isLoading = false, file, mediaCode, type, }) => {
    const currentImage = `${process.env.NEXT_PUBLIC_API_THUMBNAIL}/content/${mediaCode}`;
    const image = file?.name ? URL.createObjectURL(file) : currentImage;
    const [hasNoImage, setHasNoImage] = useState(false);
    if (hasNoImage && type === 'AUDIO')
        return _jsx(AudioImage, {});
    return (_jsx("div", { className: styles.mediaThumb, children: !isLoading ? (_jsx("img", { alt: 'thumbnail', src: image, "data-testid": 'media-thumb', onError: () => setHasNoImage(true) })) : (_jsx("div", { className: styles.loading, children: _jsx(FontAwesomeIcon, { color: '#F7F9FA', icon: faSpinnerThird, scale: 20, spin: true, size: '2x', "data-testid": 'loading' }) })) }));
};
export default ThumbnailImage;
