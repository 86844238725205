import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
export const MenuItem = ({ active, children, className, onClick, ...rest }) => {
    return (_jsx(Link, { className: classNames(styles.button, { [styles.active]: active }, className), onClick: (event) => {
            event.stopPropagation();
            onClick?.(event);
        }, ...rest, children: children }));
};
