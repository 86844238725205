import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import MediasService from '../../services/MediaService';
export const useMediaLatest = () => {
    const getLatestMedias = useCallback(async () => {
        const data = await MediasService.getLatestMedias();
        return data;
    }, []);
    return useQuery({
        queryKey: [QueryKeys.MEDIA_LATEST],
        queryFn: () => getLatestMedias(),
    });
};
