// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pYdQPEPevTiBKA619jNk{display:flex;align-items:center;justify-content:space-between;padding:var(--hc-size-spacing-2) var(--hc-size-spacing-4);border:1px solid var(--gray-200);border-radius:var(--hc-size-spacing-1);background:var(--white)}.pYdQPEPevTiBKA619jNk span{font-size:var(--hc-size-spacing-4);color:var(--hc-colors-neutral-900)}.pYdQPEPevTiBKA619jNk button{background:none;cursor:pointer;border:none;outline:none}.pYdQPEPevTiBKA619jNk button svg{height:var(--hc-size-spacing-3);width:var(--hc-size-spacing-3)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Domain/components/Domain/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,yDAAA,CACA,gCAAA,CACA,sCAAA,CACA,uBAAA,CAEA,2BACE,kCAAA,CACA,kCAAA,CAGF,6BACE,eAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CAEA,iCACE,+BAAA,CACA,8BAAA","sourcesContent":[".domain {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: var(--hc-size-spacing-2) var(--hc-size-spacing-4);\n  border: 1px solid var(--gray-200);\n  border-radius: var(--hc-size-spacing-1);\n  background: var(--white);\n\n  span {\n    font-size: var(--hc-size-spacing-4);\n    color: var(--hc-colors-neutral-900);\n  }\n\n  button {\n    background: none;\n    cursor: pointer;\n    border: none;\n    outline: none;\n\n    svg {\n      height: var(--hc-size-spacing-3);\n      width: var(--hc-size-spacing-3);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"domain": `pYdQPEPevTiBKA619jNk`
};
export default ___CSS_LOADER_EXPORT___;
