// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kKJGEH2yKU4vNnEAvUc0{display:flex;height:100%}.kKJGEH2yKU4vNnEAvUc0 iframe{border-radius:var(--hc-size-border-radius-large)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Media/Settings/containers/PlaybackControlCustomizer/components/PlayerPreview/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,6BACE,gDAAA","sourcesContent":[".playerContainer {\n  display: flex;\n  height: 100%;\n\n  iframe {\n    border-radius: var(--hc-size-border-radius-large);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerContainer": `kKJGEH2yKU4vNnEAvUc0`
};
export default ___CSS_LOADER_EXPORT___;
