// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZpuEvUwDTEUjrCac2_XR{width:100%}.ZpuEvUwDTEUjrCac2_XR .qL1_GNGXVtyqSCR8ljTM{width:100%;--hc-p-margin: 0rem}.ZpuEvUwDTEUjrCac2_XR .Fua30XzJCiHuhZIRRQvn{margin:var(--spacer-1) auto;margin:var(--spacer-1);height:1.75rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/ListItemLoading/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,4CACE,UAAA,CACA,mBAAA,CAEF,4CACE,2BAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".container {\n  width: 100%;\n\n  .paragraph {\n    width: 100%;\n    --hc-p-margin: 0rem;\n  }\n  .icon {\n    margin: var(--spacer-1) auto;\n    margin: var(--spacer-1);\n    height: 1.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZpuEvUwDTEUjrCac2_XR`,
	"paragraph": `qL1_GNGXVtyqSCR8ljTM`,
	"icon": `Fua30XzJCiHuhZIRRQvn`
};
export default ___CSS_LOADER_EXPORT___;
