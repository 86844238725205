import AuthService from 'src/infrastructure/services/Auth';
import PlanService from 'src/domain/User/Plan/components/PlanDrawer/services/PlanService';
import { applicationVersion } from 'src/utils/application';
import { getURLPath } from 'src/utils/browser';
import { debounce } from 'src/utils/debounce';
import Tracking from '.';
const SEND_INTERACT_EVENT_DEBOUNCE_TIME = 500;
class EventPrepAndTrackService {
    constructor() {
        Object.defineProperty(this, "_user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "_plan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "_intent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
        Object.defineProperty(this, "sendDebouncedInteractClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(this.handleDebouncedInteractEvent, SEND_INTERACT_EVENT_DEBOUNCE_TIME)
        });
    }
    async getUserData() {
        if (this._user) {
            return this._user;
        }
        this._user = await AuthService.getUser();
    }
    async getPlanData() {
        if (this._plan) {
            return;
        }
        this._plan = {
            planName: '',
        };
        this._plan = await PlanService.getSubscriptionPlan();
    }
    async sendErrorEvent({ message, apiURL, stack, statusCode, }) {
        return Promise.allSettled([this.getUserData(), this.getPlanData()]).then(() => {
            Tracking.sendErrorEvent({
                userId: this._user?.profile.sub || '',
                sessionId: this._user?.profile.sid || '',
                planName: this._plan?.planName || '',
                applicationVersion,
                pagePath: getURLPath(),
                message,
                apiURL,
                stack,
                statusCode,
            });
        });
    }
    async sendInteractEvent({ eventAction, eventDispatchedBy, intent, }) {
        return Promise.allSettled([this.getUserData(), this.getPlanData()]).then(() => {
            Tracking.sendInteractEvent({
                userId: this._user?.profile.sub || '',
                sessionId: this._user?.profile.sid || '',
                planName: this._plan?.planName || '',
                applicationVersion,
                pagePath: getURLPath(),
                eventAction,
                eventDispatchedBy,
                intent,
            });
        });
    }
    handleDebouncedInteractEvent(intent, element = 'button') {
        this.sendInteractEvent({
            eventAction: 'click',
            eventDispatchedBy: element,
            intent,
        });
    }
}
export default new EventPrepAndTrackService();
