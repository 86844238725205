import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from '../../services/SubtitleService';
import { SubtitleStatus } from '../../interfaces/subtitle';
const changeSubtitleStatus = ({ subtitles, subtitleCode, newStatus }) => {
    const newSubtitle = [...subtitles];
    const subtitleIndexToChange = newSubtitle.findIndex((subtitle) => subtitle.id === subtitleCode);
    if (subtitleIndexToChange >= 0) {
        newSubtitle[subtitleIndexToChange].status = newStatus;
    }
    return newSubtitle;
};
export const useToggleSubtitleStatus = () => {
    const queryClient = useQueryClient();
    const mutationToggleSubtitleStatus = useMutation({
        mutationFn: ({ mediaCode, subtitleCode, subtitleStatus }) => SubtitleService.enable({
            mediaCode,
            subtitleCode,
            subtitleStatus: subtitleStatus === SubtitleStatus.ACTIVE ? 'false' : 'true',
        }),
        onMutate: async (variables) => {
            const currentValue = variables;
            await queryClient.cancelQueries({
                queryKey: [QueryKeys.SUBTITLE, variables.mediaCode],
            });
            const newStatus = currentValue.subtitleStatus === SubtitleStatus.ACTIVE
                ? SubtitleStatus.INACTIVE
                : SubtitleStatus.ACTIVE;
            queryClient.setQueryData([QueryKeys.SUBTITLE, variables.mediaCode], (current) => changeSubtitleStatus({
                subtitles: current,
                newStatus,
                subtitleCode: currentValue.subtitleCode,
            }));
            return currentValue;
        },
        onError: (error, variables, context) => {
            const oldStatus = String(context?.subtitleStatus);
            queryClient.setQueryData([QueryKeys.SUBTITLE, variables.mediaCode], (current) => changeSubtitleStatus({
                subtitles: current,
                newStatus: oldStatus,
                subtitleCode: variables.subtitleCode,
            }));
        },
    });
    return {
        mutationToggleSubtitleStatus,
    };
};
