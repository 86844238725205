import Api from 'src/infrastructure/services/Api';
const url = `${process.env.API_GATEWAY}/preferences/creator`;
class PlaybackControlCustomizerApi {
    async get({ mediaCode }) {
        const response = await Api.call({
            method: 'GET',
            url,
            params: {
                mediaCode,
            },
        });
        return response.data;
    }
    async put({ preferences, mediaCode }) {
        const response = await Api.call({
            method: 'PUT',
            url,
            data: preferences,
            params: { mediaCode },
        });
        return response;
    }
}
export default new PlaybackControlCustomizerApi();
