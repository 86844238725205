import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { onPageHasUnsavedChanges, onAllChangesSaved, } from 'src/utils/pageLifeCycleUtils';
export const useWarnIfUnsavedChanges = ({ mustConfirmExitFromPage, confirmation, }) => {
    const blocker = useBlocker(mustConfirmExitFromPage);
    const isBlockedState = blocker.state === 'blocked';
    useEffect(() => {
        if (mustConfirmExitFromPage) {
            onPageHasUnsavedChanges();
        }
        else {
            onAllChangesSaved();
        }
        if (isBlockedState && !mustConfirmExitFromPage) {
            blocker.reset();
        }
        if (isBlockedState && mustConfirmExitFromPage) {
            confirmation().then((isConfirmed) => {
                if (isConfirmed)
                    blocker.proceed();
                else
                    blocker.reset();
            });
        }
        return () => {
            onAllChangesSaved();
        };
    }, [blocker, confirmation, isBlockedState, mustConfirmExitFromPage]);
};
