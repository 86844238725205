import { useCallback, useRef } from 'react';
import UploadManagerService from 'src/infrastructure/services/Upload/UploadManagerService';
import useAuth from 'src/application/hooks/useAuth';
export const useUploadFile = ({ apiUploadUrl }) => {
    const { user } = useAuth();
    const upload = useRef({});
    const removeUploadInstanceByFileHash = (fileHash) => Reflect.deleteProperty(upload.current, fileHash);
    const uploadFile = useCallback(async ({ file, internalFileHash, callbacks, options, }) => {
        const fileHash = internalFileHash || file.name;
        try {
            const uploadManagerService = new UploadManagerService({
                apiConfig: {
                    url: apiUploadUrl,
                    accessToken: user.access_token,
                    authParams: {},
                },
            });
            upload.current[fileHash] = await uploadManagerService.send({
                file,
                internalFileHash: fileHash,
                callbacks: {
                    ...callbacks,
                    onFail: (result) => {
                        callbacks.onFail(result);
                        removeUploadInstanceByFileHash(fileHash);
                    },
                    onSuccess: (result) => {
                        callbacks.onSuccess(result);
                        removeUploadInstanceByFileHash(fileHash);
                    },
                },
                options,
            });
        }
        catch (err) {
            callbacks?.onFail?.({
                internalFileHash: fileHash,
                canCancel: false,
                progress: 0,
                uploadInfo: {
                    id: '',
                    internalFileKey: '',
                    mediaCode: '',
                },
            });
            removeUploadInstanceByFileHash(fileHash);
        }
    }, [apiUploadUrl, user]);
    const cancelFileUpload = (internalFileHash) => {
        upload.current[internalFileHash].cancel();
        removeUploadInstanceByFileHash(internalFileHash);
    };
    const pauseFileUpload = (internalFileHash) => upload.current[internalFileHash].pause();
    const resumeFileUpload = (internalFileHash) => upload.current[internalFileHash]?.resume?.();
    return {
        uploadFile,
        cancelFileUpload,
        pauseFileUpload,
        resumeFileUpload,
    };
};
