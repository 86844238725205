// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thFP8C391BZC0RBAAay9{--hc-dropdown-item-container-padding-block: 0.75rem;--hc-dropdown-item-container-padding-inline: 1rem;--hc-dropdown-menu-min-width: 14.875rem;--hc-dropdown-menu-width: 100%;--hc-dropdown-item-border-bottom: 0;--hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);--hc-dropdown-menu-max-height: 50vh;--hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3)}.thFP8C391BZC0RBAAay9 .vtrjvS8Www8_0ivLYN5a{align-items:center;display:flex;height:1.5rem}.thFP8C391BZC0RBAAay9 .vtrjvS8Www8_0ivLYN5a svg{width:1rem;height:1rem;margin-top:-0.25rem}.thFP8C391BZC0RBAAay9 .eH7rBgBpOktYi0mJtwl_{display:flex;align-items:center}.thFP8C391BZC0RBAAay9 .RXS2lkcUWpL7YDvsuLAr svg{border-bottom:1px solid}`, "",{"version":3,"sources":["webpack://./src/domain/Folder/components/FolderBreadcrumb/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mDAAA,CACA,iDAAA,CACA,uCAAA,CACA,8BAAA,CACA,mCAAA,CACA,sEAAA,CACA,mCAAA,CACA,iEAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,aAAA,CAEA,gDACE,UAAA,CACA,WAAA,CACA,mBAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CAIA,gDACE,uBAAA","sourcesContent":[".dropdown {\n  --hc-dropdown-item-container-padding-block: 0.75rem;\n  --hc-dropdown-item-container-padding-inline: 1rem;\n  --hc-dropdown-menu-min-width: 14.875rem;\n  --hc-dropdown-menu-width: 100%;\n  --hc-dropdown-item-border-bottom: 0;\n  --hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);\n  --hc-dropdown-menu-max-height: 50vh;\n  --hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3);\n\n  .icon {\n    align-items: center;\n    display: flex;\n    height: 1.5rem;\n\n    svg {\n      width: 1rem;\n      height: 1rem;\n      margin-top: -0.25rem;\n    }\n  }\n\n  .label {\n    display: flex;\n    align-items: center;\n  }\n\n  .dropdownButton {\n    svg {\n      border-bottom: 1px solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `thFP8C391BZC0RBAAay9`,
	"icon": `vtrjvS8Www8_0ivLYN5a`,
	"label": `eH7rBgBpOktYi0mJtwl_`,
	"dropdownButton": `RXS2lkcUWpL7YDvsuLAr`
};
export default ___CSS_LOADER_EXPORT___;
