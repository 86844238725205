import { FileStatus } from '../../interfaces/FileUploadProps';
import { MediaProcessStatus } from '../../interfaces/MediaProcessProps';
export const MEDIA_WEB_SOCKET_PARSE_STATUS = {
    [MediaProcessStatus.THUMBNAIL_READY]: FileStatus.SUCCESS,
    [MediaProcessStatus.READY]: FileStatus.SUCCESS,
    [MediaProcessStatus.FAILED]: FileStatus.FATAL_ERROR,
};
export const MAX_DAYS_INTERVAL = 2;
export const TIME_TO_CLOSE_FRAME_UPLOAD_AFTER_UPLOADS_COMPLETED = MAX_DAYS_INTERVAL * 24 * 60 * 60 * 1000; // X days
export const MAX_AMOUNT_OF_FILE_TO_UPLOAD_AT_A_TIME = 10;
export const CONFIG_UPLOAD_URL = `${process.env.API_GATEWAY}/v2/media/upload/config`;
export const INITIAL_FILE_CONFIG_ALLOWED = {
    byteSizeLimit: 21474836480,
    supportedFiles: [
        'application/mp4',
        'application/x-troff-msvideo',
        'audio/aiff',
        'audio/aiff',
        'audio/avi',
        'audio/mp3',
        'audio/mp4',
        'audio/mpeg',
        'audio/mpeg3',
        'audio/mpg',
        'audio/wav',
        'audio/wave',
        'audio/x-midi',
        'audio/x-mp3',
        'audio/x-mpeg',
        'audio/x-mpeg3',
        'audio/x-mpegaudio',
        'audio/x-mpg',
        'audio/x-pn-wav',
        'audio/x-wav',
        'image/avi',
        'image/mov',
        'video/avi',
        'video/mp4',
        'video/msvideo',
        'video/quicktime',
        'video/x-matroska',
        'video/x-ms-wmv',
        'video/x-msvideo',
        'video/x-quicktime',
        'video/xmpg2',
    ],
};
