import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import DomainService from '../../services/DomainService';
export const useRemoveDomain = ({ medias }) => {
    const isMoreThanOneMedia = medias.length > 1;
    const queryClient = useQueryClient();
    const removeDomainByCodes = (mediaDomainCodes) => {
        queryClient.setQueryData([QueryKeys.DOMAINS], (currentData) => {
            const parseCurrentData = [...(currentData || [])];
            return parseCurrentData.filter((item) => !mediaDomainCodes.includes(item.id));
        });
    };
    return useMutation({
        mutationFn: ({ mediaDomainCodes }) => DomainService.deleteMediaDomains({
            mediaDomainCodes,
        }),
        onSuccess: (data, variables) => {
            if (!isMoreThanOneMedia) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.DOMAINS, medias[0].code],
                });
                return;
            }
            const { mediaDomainCodes } = variables;
            removeDomainByCodes(mediaDomainCodes);
        },
        onError: (error) => {
            console.error(error);
        },
    });
};
