// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nYsOXLnCUGa10RgUqjXT .YC0f65pvytfBzd7HR1C_{display:flex;flex-direction:column;gap:.75rem}.nYsOXLnCUGa10RgUqjXT .YC0f65pvytfBzd7HR1C_ .gniaGcGhDQbv411ggwoG{--hc-p-neutral-color: var(--hc-color-neutral-600)}.nYsOXLnCUGa10RgUqjXT .YC0f65pvytfBzd7HR1C_ label{color:var(--hc-color-neutral-600) !important}.nYsOXLnCUGa10RgUqjXT .MYXAT4pMvQ91H8K0pA1D{--hc-button-danger-background-color: var(--hc-color-danger-400);--hc-button-danger-border-color: var(--hc-color-danger-400);--hc-button-danger-color: var(--hc-color-danger-100);--hc-button-danger-disabled-background-color: var(--hc-color-neutral-400);--hc-button-danger-disabled-border-colo: var(--hc-color-neutral-400);--hc-button-danger-disabled-colo: var(--hc-color-primary-100)}`, "",{"version":3,"sources":["webpack://./src/ui/Modal/components/BasicConfirmationModal/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,kEACE,iDAAA,CAGF,kDACE,4CAAA,CAIJ,4CACE,+DAAA,CACA,2DAAA,CACA,oDAAA,CAEA,yEAAA,CACA,oEAAA,CACA,6DAAA","sourcesContent":[".modal {\n  .description {\n    display: flex;\n    flex-direction: column;\n    gap: 0.75rem;\n\n    .paragraph {\n      --hc-p-neutral-color: var(--hc-color-neutral-600);\n    }\n\n    label {\n      color: var(--hc-color-neutral-600) !important;\n    }\n  }\n\n  .button {\n    --hc-button-danger-background-color: var(--hc-color-danger-400);\n    --hc-button-danger-border-color: var(--hc-color-danger-400);\n    --hc-button-danger-color: var(--hc-color-danger-100);\n\n    --hc-button-danger-disabled-background-color: var(--hc-color-neutral-400);\n    --hc-button-danger-disabled-border-colo: var(--hc-color-neutral-400);\n    --hc-button-danger-disabled-colo: var(--hc-color-primary-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `nYsOXLnCUGa10RgUqjXT`,
	"description": `YC0f65pvytfBzd7HR1C_`,
	"paragraph": `gniaGcGhDQbv411ggwoG`,
	"button": `MYXAT4pMvQ91H8K0pA1D`
};
export default ___CSS_LOADER_EXPORT___;
