// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vojFLvuvSUr7wy6a0510{display:flex;gap:.375rem}.vojFLvuvSUr7wy6a0510 .ALMATAoyFwKPx4BFaAt6{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/Radio/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,4CACE,eAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 0.375rem;\n\n  .radio {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vojFLvuvSUr7wy6a0510`,
	"radio": `ALMATAoyFwKPx4BFaAt6`
};
export default ___CSS_LOADER_EXPORT___;
