// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bZ71sURbb2rJuXbsGEUC{display:flex;align-items:center;justify-content:center}.bZ71sURbb2rJuXbsGEUC.ldoVwfbXDTdYhafO1vWS{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Loading/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,2CACE,UAAA,CACA,WAAA","sourcesContent":[".loading {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.full {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `bZ71sURbb2rJuXbsGEUC`,
	"full": `ldoVwfbXDTdYhafO1vWS`
};
export default ___CSS_LOADER_EXPORT___;
