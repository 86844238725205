import { useCallback } from 'react';
import { useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
export const useAddMediaToLibrary = () => {
    const queryClient = useQueryClient();
    const updateLibrary = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.LIBRARY],
        });
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.SEARCH],
        });
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.FOLDER_ITEMS],
        });
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.MEDIA_LATEST],
        });
    }, [queryClient]);
    return {
        updateLibrary,
    };
};
