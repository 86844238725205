import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { HcFormField } from '@hotmart-org-ca/cosmos-web/dist/react/hc-form-field';
import { Select, SelectItem } from 'src/ui/cosmosWeb/Select';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const SubtitleSelect = ({ onSelected, availableSubtitles, disabled = false, invalidMessage, value = '', }) => {
    const { t } = useTranslation();
    const selectRef = useRef(null);
    useEffect(() => {
        if (!selectRef.current?.['elementInput']) {
            return;
        }
        selectRef.current['elementInput'].value = value
            ? t(`subtitle.supported_languages.${value}`)
            : '';
    }, [availableSubtitles, value, t]);
    return (_jsxs(HcFormField, { children: [_jsx(Select, { ref: selectRef, size: 'medium', placeholder: t(`subtitle.placeholder`), autocomplete: 'on', "data-testid": 'subtitle-selection', className: styles.select, docked: true, "aria-disabled": disabled, invalid: !!invalidMessage, children: !disabled &&
                    availableSubtitles?.map((lang) => (_jsx(SelectItem, { value: lang, "data-testid": `subtitle-selection-item-${lang}`, onClick: (e) => onSelected(e.target.value), children: t(`subtitle.supported_languages.${lang}`) }, lang))) }), invalidMessage && _jsx("div", { slot: 'invalid-message', children: invalidMessage })] }));
};
export default SubtitleSelect;
