import { subDays, format } from 'date-fns';
import MediaService from 'src/domain/Media/services/MediaService';
import { parseFilesToContinueProcessing } from 'src/domain/Media/modules/MediaUpload/utils/fileUploadUtils';
import { MAX_DAYS_INTERVAL } from '../constants';
export const useMediaProcessing = () => {
    const getProcessingMedias = async () => {
        const dateNow = new Date();
        try {
            const filesToReprocess = await MediaService.getMediasStatusByDateInterval({
                startDate: format(subDays(dateNow, MAX_DAYS_INTERVAL), 'yyyy-MM-dd'),
                endDate: format(dateNow, 'yyyy-MM-dd'),
            });
            if (filesToReprocess.length > 0) {
                return parseFilesToContinueProcessing(filesToReprocess);
            }
        }
        catch (err) {
            console.error(err);
        }
        return {};
    };
    return {
        getProcessingMedias,
    };
};
