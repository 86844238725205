import { useState } from 'react';
export const useFolderNavigation = () => {
    const [folderHistoryNavigation, setFolderHistoryNavigation] = useState([]);
    const currentFolder = folderHistoryNavigation[folderHistoryNavigation.length - 1];
    const isThereAnyFolderOpen = folderHistoryNavigation.length > 0;
    const openFolder = (folder) => {
        setFolderHistoryNavigation((currentFolder) => [...currentFolder, folder]);
    };
    const goBackToParentFolder = () => {
        setFolderHistoryNavigation((currentFolder) => currentFolder.slice(0, -1));
    };
    return {
        currentFolder,
        isThereAnyFolderOpen,
        openFolder,
        goBackToParentFolder,
    };
};
