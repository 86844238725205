import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaCount, useMediaDurationCount } from 'src/domain/Media/hooks';
import { useCreateFolder, useFolderCount } from 'src/domain/Folder/hooks';
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { convertSecondsToMinutes } from 'src/domain/Media/utils/time';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui/cosmosWeb/Button';
import NiceModal from '@ebay/nice-modal-react';
import { useCallback } from 'react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Totalizer } from '../../components/Totalizer';
import styles from './styles.module.scss';
export const Totalizers = () => {
    const { t } = useTranslation();
    const { data: mediaCount, isLoading: isMediaCountLoading } = useMediaCount();
    const { data: mediaDurationCount, isLoading: isMediaDurationLoading } = useMediaDurationCount();
    const { data: folderCount, isLoading: isFolderCountLoading } = useFolderCount();
    const createFolder = useCreateFolder();
    const handleCreateFolder = useCallback(async () => {
        const name = await NiceModal.show(BasicPromptModal, {
            loading: createFolder.isLoading,
            title: t('modal.folder.create.title'),
            buttons: {
                save: {
                    label: t('general.create'),
                },
                cancel: {
                    label: t('general.cancel'),
                },
            },
        });
        await createFolder.mutateAsync({
            name: name,
            parentFolderCode: '',
        });
        NiceModal.hide(BasicPromptModal);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_NEW_FOLDER);
    }, [createFolder, t]);
    return (_jsxs("div", { className: styles.totalizers, children: [_jsx(Totalizer, { label: t('home.totalizers.total_number_of_folders'), value: t('home.totalizers.folders', { amount: folderCount?.total }), loading: isFolderCountLoading, slotAction: _jsx(Button, { variation: 'secondary', onClick: handleCreateFolder, size: 'small', children: t('home.buttons.add_folder') }) }), _jsx(Totalizer, { label: t('home.totalizers.total_number_of_medias'), value: t('home.totalizers.medias', { amount: mediaCount?.total }), loading: isMediaCountLoading }), _jsx(Totalizer, { label: t('home.totalizers.total_medias_time'), value: t('home.totalizers.minutes', {
                    amount: convertSecondsToMinutes(mediaDurationCount?.total || 0),
                }), loading: isMediaDurationLoading })] }));
};
