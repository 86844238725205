import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import FileUploader from 'src/infrastructure/services/Upload/FileUploader';
import AccountStorage from 'src/infrastructure/persistence/accounts/storage';
const INTERNAL_KEY_PREFIX = 'hot-upload/';
const CHUNK_SIZE = 6291456; // 6MB
export default class UploadManagerService {
    constructor({ apiConfig, maxUploadedFilePartsStillAllowCancelUpload = 10, }) {
        Object.defineProperty(this, "_apiConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_fileUploader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_uploadInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_internalFileHash", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "_maxUploadedFilePartsStillAllowCancelUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._apiConfig = apiConfig;
        this._maxUploadedFilePartsStillAllowCancelUpload =
            maxUploadedFilePartsStillAllowCancelUpload * CHUNK_SIZE;
    }
    makeCallbacks(callbacks) {
        const callbacksItems = {
            onEnqueued: (data) => {
                if (!data?.initialInfo?.id ||
                    !data?.initialInfo?.metaData?.internalFileHash) {
                    return Promise.reject();
                }
                this._uploadInfo = {
                    id: data.initialInfo.id,
                    internalFileKey: data.initialInfo.internalFileKey,
                    mediaCode: data.initialInfo?.mediaCode,
                };
                callbacks?.onProgress?.({
                    internalFileHash: this._internalFileHash,
                    canCancel: false,
                    progress: 0,
                    uploadInfo: this._uploadInfo,
                });
            },
            onProgress: (data) => {
                callbacks?.onProgress?.({
                    internalFileHash: this._internalFileHash,
                    canCancel: (data?.remainingSize || 0) >
                        this._maxUploadedFilePartsStillAllowCancelUpload,
                    progress: Math.max(0.1, Math.min(99, Number(data?.progress) * 100)),
                    uploadInfo: this._uploadInfo,
                });
            },
            onFail: (data) => {
                EventPrepAndTrackService.sendErrorEvent({
                    message: data?.exception || 'Error on Upload',
                    apiURL: this._apiConfig.url,
                });
                return callbacks?.onFail?.({
                    internalFileHash: this._internalFileHash,
                    canCancel: false,
                    progress: 0,
                    uploadInfo: this._uploadInfo,
                });
            },
            onSuccess: () => callbacks?.onSuccess?.({
                internalFileHash: this._internalFileHash,
                canCancel: false,
                progress: 100,
                uploadInfo: this._uploadInfo,
            }),
        };
        return callbacksItems;
    }
    async send({ file, internalFileHash, callbacks, options, }) {
        const callbacksToUpload = this.makeCallbacks(callbacks);
        this._internalFileHash = internalFileHash;
        const { metaData, ...restOptions } = options || {};
        const metaDataParsed = {
            ...metaData,
            internalFileHash: this._internalFileHash,
        };
        const accountId = AccountStorage.currentAccountId;
        this._fileUploader =
            await FileUploader.prepareUploadWithOptionsAndCallbacks({
                config: {
                    config: {
                        url: this._apiConfig.url,
                    },
                    accessToken: this._apiConfig.accessToken,
                    authParams: {
                        ...this._apiConfig.authParams,
                        'hotmart-target-account-id': accountId,
                    },
                },
            }, {
                onInit: (data) => {
                    this?._fileUploader?.send(file, {
                        metaData: metaDataParsed,
                        ...restOptions,
                    });
                    callbacksToUpload.onInit?.bind(this, data);
                },
                onStart: callbacksToUpload.onStart?.bind(this),
                onEnqueued: callbacksToUpload.onEnqueued?.bind(this),
                onProgress: callbacksToUpload.onProgress?.bind(this),
                onSuccess: callbacksToUpload.onSuccess.bind(this),
                onInitFailed: callbacksToUpload.onInitFailed?.bind(this),
                onFail: callbacksToUpload.onFail.bind(this),
            });
        return {
            cancel: this.cancel.bind(this),
            pause: this.pause.bind(this),
            resume: this.resume.bind(this),
        };
    }
    cancel() {
        if (this._uploadInfo?.internalFileKey) {
            this._fileUploader?.cancel(this._uploadInfo.internalFileKey);
        }
    }
    pause() {
        if (this._uploadInfo?.internalFileKey) {
            this._fileUploader?.pause(`${INTERNAL_KEY_PREFIX}${this._uploadInfo.internalFileKey}`);
        }
    }
    resume() {
        if (this._uploadInfo?.internalFileKey) {
            this._fileUploader?.resume(`${INTERNAL_KEY_PREFIX}${this._uploadInfo.internalFileKey}`);
        }
    }
}
