// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TfiylvTz4D4LUV6PS1gl{background:var(--hc-color-neutral-0);display:flex;align-items:center;justify-content:center;height:100%}.TfiylvTz4D4LUV6PS1gl input[type=file]{display:none}.qlka_ZX9fn4a0bYFMnfu{margin-bottom:2rem}.fYrsU6pG1kB2IkkuDeQP{background:var(--hc-color-neutral-0);display:flex;align-items:center;justify-content:center;height:100vh}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/containers/LibraryContent/LibraryContentList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEA,uCACE,YAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".contentFeedbackWrapper {\n  background: var(--hc-color-neutral-0);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n\n  input[type='file'] {\n    display: none;\n  }\n}\n\n.alert {\n  margin-bottom: 2rem;\n}\n\n.loadingContentWrapper {\n  background: var(--hc-color-neutral-0);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentFeedbackWrapper": `TfiylvTz4D4LUV6PS1gl`,
	"alert": `qlka_ZX9fn4a0bYFMnfu`,
	"loadingContentWrapper": `fYrsU6pG1kB2IkkuDeQP`
};
export default ___CSS_LOADER_EXPORT___;
