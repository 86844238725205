import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from '../../services/SubtitleService';
export const useListSubtitlesSupported = () => {
    const allSupportedSubtitles = useQuery({
        queryKey: [QueryKeys.SUBTITLE_SUPPORTED],
        queryFn: () => SubtitleService.supported(),
        staleTime: Infinity,
    });
    return {
        allSupportedSubtitles,
    };
};
