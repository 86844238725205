import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import List from 'react-virtualized/dist/commonjs/List';
const InfiniteScroll = forwardRef(({ rowCount, isRowLoaded, loadMoreRows, rowRenderer, rowHeight }, ref) => {
    const setCustomRef = (list) => {
        if (!ref) {
            return list;
        }
        ref.current = {
            scrollToTop: () => list?.scrollToPosition(0),
        };
        return list;
    };
    return (_jsx(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: (rowCount ?? 0) + 1, threshold: 5, minimumBatchSize: 10, children: ({ onRowsRendered, registerChild }) => {
            return (_jsx(AutoSizer, { children: ({ height, width }) => (_jsx(List, { height: height, width: width, onRowsRendered: onRowsRendered, ref: (r) => registerChild(setCustomRef(r)), rowCount: rowCount ?? 0, rowHeight: rowHeight, rowRenderer: rowRenderer })) }));
        } }));
});
InfiniteScroll.displayName = 'InfiniteScroll';
export default InfiniteScroll;
