// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w3IY5wBohxHJrnbJiVrt{word-break:normal}.L22V58fJjsX1weTqBo6o{word-break:break-all}.gAj9l06mgzzksPYYnXZO{word-break:keep-all}.Hd1dF3CszvgtOR2QT5vl{word-break:break-word}.LctMnbehTemAO0dI5dd3{word-break:initial}.Wtt42iHfK6OsNRcBsfTe{display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/ui/TextTruncate/styles.module.scss"],"names":[],"mappings":"AAIE,sBACE,iBALW,CAIb,sBACE,oBALW,CAIb,sBACE,mBALW,CAIb,sBACE,qBALW,CAIb,sBACE,kBALW,CASf,sBACE,mBAAA,CACA,2BAAA,CACA,eAAA","sourcesContent":["$break-values: 'normal' normal, 'break-all' break-all, 'keep-all' keep-all,\n  'break-word' break-word, 'initial' initial;\n\n@each $className, $value in $break-values {\n  .#{$className} {\n    word-break: $value;\n  }\n}\n\n.truncate {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"normal": `w3IY5wBohxHJrnbJiVrt`,
	"breakAll": `L22V58fJjsX1weTqBo6o`,
	"keepAll": `gAj9l06mgzzksPYYnXZO`,
	"breakWord": `Hd1dF3CszvgtOR2QT5vl`,
	"initial": `LctMnbehTemAO0dI5dd3`,
	"truncate": `Wtt42iHfK6OsNRcBsfTe`
};
export default ___CSS_LOADER_EXPORT___;
