import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
const Header = ({ children, className, hideMenuBurger = false }) => {
    const forceViewTheEntireLogo = (ref) => {
        ref?.shadowRoot
            .querySelector('.hot-application__brand')
            .setAttribute('style', 'overflow:visible');
    };
    return (_jsx("hot-header", { ref: forceViewTheEntireLogo, slot: hideMenuBurger ? null : 'header', class: classNames('hot-application--custom-logo', { 'hot-application--no-product-name': !hideMenuBurger }, className), children: children }));
};
export default Header;
