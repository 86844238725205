function stringToCamel(str) {
    if (!str.includes('_') && !str.includes('-')) {
        return str;
    }
    return str.replace(/[-_]([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    });
}
export function convertObjectKeysToCamelCase(object) {
    const newObject = {};
    Object.keys(object).forEach((key) => {
        const newKey = stringToCamel(key);
        newObject[newKey] = object[key];
    });
    return newObject;
}
