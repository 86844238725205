import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faFolder } from 'src/application/icons/fontAwesome/light';
import { faAngleRight } from 'src/application/icons/fontAwesome/regular';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const FolderItem = ({ name, selected, disabled = false, onClick, onOpenFolder, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { "data-testid": 'folder-item', onClick: () => !disabled && onClick(), className: classNames(styles.folder, {
            [styles.selected]: selected,
            [styles.disabled]: disabled,
        }), children: [_jsxs("div", { className: styles.folderData, children: [_jsx(FontAwesomeIcon, { icon: faFolder, size: 'lg' }), _jsx(Paragraph, { size: 'small', children: _jsx(TextTruncate, { children: name }) })] }), !disabled && (_jsx(Tooltip, { text: t('general.see_content'), children: _jsx(Button, { "data-testid": `open-folder-button-${name}`, variation: 'tertiary', context: 'neutral', size: 'small', onClick: onOpenFolder, children: _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faAngleRight }) }) }) }))] }));
};
