import { useTranslation } from 'react-i18next';
import { convertTime } from 'src/utils/convertTime';
const useConvertDuration = () => {
    const { t } = useTranslation();
    const convertDuration = (duration) => {
        if (!duration)
            return '-';
        const [hours, minutes, seconds] = convertTime(duration);
        let timeString = '';
        if (hours > 0) {
            timeString += `${hours} ${t(hours === 1 ? t('details.hour') : t('details.hours'))} `;
        }
        if (minutes > 0) {
            timeString += `${minutes} ${t(minutes === 1 ? t('details.minute') : t('details.minutes'))} `;
        }
        if (seconds > 0) {
            timeString += `${seconds} ${t(seconds === 1 ? t('details.seconds') : t('details.seconds'))}`;
        }
        return timeString;
    };
    return { convertDuration };
};
export default useConvertDuration;
