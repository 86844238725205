import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useMemo, useEffect, useCallback, useRef, } from 'react';
import { useUploadFile } from 'src/application/hooks/useUploadFile';
import { FileStatus, } from '../../interfaces/FileUploadProps';
import { parseFiles } from '../../utils/fileUploadUtils';
import { useWebsocketMediaUploadStatus } from '../../hooks/useWebsocketMediaUploadStatus';
import { getGeneralStatusFromFileItems } from '../../utils/fileStatus';
import { CONFIG_UPLOAD_URL, INITIAL_FILE_CONFIG_ALLOWED, MEDIA_WEB_SOCKET_PARSE_STATUS, } from './constants';
import { useFilesAmountToUpload } from './hooks/useFilesAmountToUpload';
import { useConfirmClosePageWhenFileUploading } from './hooks/useConfirmClosePageWhenFileUploading';
import { useMediaProcessing } from './hooks/useMediaProcessing';
import { useAddMediaToLibrary } from './hooks/useAddMediaToLibrary';
export const MediaUploadContext = createContext({});
export const MediaUploadProvider = ({ children, }) => {
    const { updateLibrary } = useAddMediaToLibrary();
    const { checkAndReturnCorrectFilesAmountWithAdvise } = useFilesAmountToUpload();
    const { uploadFile, cancelFileUpload, pauseFileUpload, resumeFileUpload } = useUploadFile({
        apiUploadUrl: CONFIG_UPLOAD_URL,
    });
    const { subscribeConnection } = useWebsocketMediaUploadStatus();
    const { getProcessingMedias } = useMediaProcessing();
    const isLoaded = useRef(false);
    const [files, setFiles] = useState({});
    const generalUploadState = useMemo(() => getGeneralStatusFromFileItems(files), [files]);
    useConfirmClosePageWhenFileUploading({
        generalUploadState,
    });
    const updateFileStatusByInternalFileHash = ({ internalFileHash, ...rest }) => {
        setFiles((current) => {
            const newState = { ...current };
            if (newState[internalFileHash]) {
                newState[internalFileHash] = {
                    ...newState[internalFileHash],
                    ...rest,
                };
            }
            return newState;
        });
    };
    const onReceiveMediaStatusFromWebSocket = useCallback((fileItem, data) => {
        if (!MEDIA_WEB_SOCKET_PARSE_STATUS[data.mediaStatus]) {
            return;
        }
        updateFileStatusByInternalFileHash({
            internalFileHash: fileItem.internalFileHash,
            status: MEDIA_WEB_SOCKET_PARSE_STATUS[data.mediaStatus],
        });
        if (MEDIA_WEB_SOCKET_PARSE_STATUS[data.mediaStatus] === FileStatus.SUCCESS) {
            updateLibrary();
        }
    }, [updateLibrary]);
    const onUploadFileSuccess = useCallback((fileItem) => subscribeConnection({
        mediaCode: String(fileItem.mediaCode),
        fileType: fileItem.type,
        onReceiveMessage: (data) => {
            onReceiveMediaStatusFromWebSocket(fileItem, data);
        },
    }), [onReceiveMediaStatusFromWebSocket, subscribeConnection]);
    const runUpload = (fileItem) => uploadFile({
        file: fileItem.file,
        internalFileHash: fileItem.internalFileHash,
        options: {
            metaData: {
                folderCode: fileItem.folderCode,
                isPublic: true,
            },
        },
        callbacks: {
            onProgress: (data) => updateFileStatusByInternalFileHash({
                ...data,
                mediaCode: String(data.uploadInfo.mediaCode),
                status: FileStatus.PROGRESS,
            }),
            onFail: (data) => updateFileStatusByInternalFileHash({
                ...data,
                mediaCode: String(data.uploadInfo?.mediaCode),
                status: FileStatus.ERROR,
            }),
            onSuccess: (data) => {
                updateFileStatusByInternalFileHash({
                    ...data,
                    mediaCode: String(data.uploadInfo.mediaCode),
                    status: FileStatus.OPTIMIZING,
                });
                onUploadFileSuccess({
                    ...fileItem,
                    mediaCode: data.uploadInfo.mediaCode,
                    status: FileStatus.OPTIMIZING,
                });
            },
        },
    });
    const onSelectFiles = (files, folderCode) => {
        const filesToSend = checkAndReturnCorrectFilesAmountWithAdvise(files);
        const parsedFiles = parseFiles({
            files: filesToSend,
            folderCode,
            fileConfigProps: INITIAL_FILE_CONFIG_ALLOWED,
        });
        setFiles((current) => ({ ...parsedFiles, ...current }));
        Object.keys(parsedFiles).forEach((fileItemKey) => runUpload(parsedFiles[fileItemKey]));
    };
    const removeFileFromFileListByInternalFileHash = (internalFileHash) => setFiles((current) => {
        const newFiles = { ...current };
        Reflect.deleteProperty(newFiles, internalFileHash);
        return newFiles;
    });
    const removeAllFileFromList = () => setFiles({});
    useEffect(() => {
        if (isLoaded.current) {
            return;
        }
        isLoaded.current = true;
        const getMediasToContinueProcess = async () => {
            const processingMedias = await getProcessingMedias();
            setFiles((current) => ({ ...current, ...processingMedias }));
            Object.keys(processingMedias).forEach((fileItemKey) => processingMedias[fileItemKey].status === FileStatus.OPTIMIZING &&
                onUploadFileSuccess(processingMedias[fileItemKey]));
        };
        getMediasToContinueProcess();
    }, [getProcessingMedias, onUploadFileSuccess]);
    return (_jsx(MediaUploadContext.Provider, { value: {
            files,
            generalUploadState,
            onSelectFiles,
            removeFileFromFileListByInternalFileHash,
            removeAllFileFromList,
            runUpload,
            cancelFileUpload,
            pauseFileUpload,
            resumeFileUpload,
        }, children: children }));
};
