import { Can } from './can';
import { AbilityProvider } from './provider';
import { ability } from './ability';
const getMediaActionsFromCurrentAccount = (account) => {
    return Object.entries(account ?? {})
        .filter(([key, value]) => key.startsWith('media') && value === true)
        .map(([key]) => key.slice(5).toLowerCase());
};
const MEDIA_FREEMIUM_NOT_ALLOWED = ['embed', 'domain'];
const updateAbility = (account) => {
    let mediaActions = getMediaActionsFromCurrentAccount(account);
    let planActions = [];
    if (account?.freemium) {
        mediaActions = mediaActions.filter((action) => !MEDIA_FREEMIUM_NOT_ALLOWED.includes(action));
    }
    if (!account?.freemium && mediaActions.includes('upload')) {
        planActions = ['read'];
    }
    ability.update([
        { subject: 'Media', action: mediaActions },
        { subject: 'Plan', action: planActions },
    ]);
};
export { AbilityProvider, Can, updateAbility, ability };
