import Api from 'src/infrastructure/services/Api';
const addDomainsToMedias = async ({ mediaCodes, domains, }) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/domain`,
        data: {
            mediaCodes,
            domain: domains,
        },
    });
    return response.data;
};
const getMediaDomains = async ({ code }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/${code}/domain`,
    });
    return response.data;
};
const deleteMediaDomains = async ({ mediaDomainCodes, }) => {
    await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/domain/delete`,
        data: {
            mediaDomainIds: mediaDomainCodes,
        },
    });
};
export { addDomainsToMedias, getMediaDomains, deleteMediaDomains };
