import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import ApiError from 'src/infrastructure/services/Api/ApiError';
import { Toast } from 'src/ui/cosmos/Toast';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useTranslation } from 'react-i18next';
import FoldersService from '../../services/FoldersService';
const useCreateFolder = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: ({ name, parentFolderCode }) => FoldersService.createFolder({ name, parentFolderCode }),
        onSuccess: (_, { parentFolderCode }) => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_COUNT],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.LIBRARY],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_LATEST],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SEARCH],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_ITEMS, parentFolderCode],
            });
            const message = t('modal.folder.create.notify.success');
            Toast.success({ message });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
};
export default useCreateFolder;
