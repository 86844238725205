import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import InfiniteScroll from 'src/ui/InfiniteScroll';
import { FolderItem } from '../FolderItem';
import { MediaItem } from '../MediaItem';
export const MoveModalListItems = forwardRef(({ isRowLoaded, loadMoreRows, allItemsLoaded, itemsToMove, selectedFolder, onOpenFolder, onSelectFolder, }, ref) => {
    return (_jsx(InfiniteScroll, { ref: ref, isRowLoaded: () => isRowLoaded, rowHeight: 32, rowCount: allItemsLoaded.length, loadMoreRows: loadMoreRows, rowRenderer: ({ index, key, style }) => {
            const item = allItemsLoaded[index];
            return (_jsx("div", { style: style, "data-testid": `list-item-modal-${item.name}`, children: item.category === 'folder' ? (_jsx(FolderItem, { onClick: () => onSelectFolder(item), selected: selectedFolder?.code === item?.code, name: item.name, onOpenFolder: () => onOpenFolder(item), disabled: itemsToMove.some((itemToMove) => itemToMove.code === item.code) })) : (_jsx(MediaItem, { name: allItemsLoaded[index].name })) }, key));
        } }));
});
MoveModalListItems.displayName = 'MoveModalListItems';
