// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K60xjXL4DTNzOxEYLgbz{--hc-h-small-md-strong-font-weight: 700}.VgketK5HVnU2YnaMxAOh input{--hc-form-field-transition: none}`, "",{"version":3,"sources":["webpack://./src/ui/Modal/components/BasicPromptModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uCAAA,CAIA,4BACE,gCAAA","sourcesContent":[".heading {\n  --hc-h-small-md-strong-font-weight: 700;\n}\n\n.removeTransitionFromInput {\n  input {\n    --hc-form-field-transition: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `K60xjXL4DTNzOxEYLgbz`,
	"removeTransitionFromInput": `VgketK5HVnU2YnaMxAOh`
};
export default ___CSS_LOADER_EXPORT___;
