import NiceModal from '@ebay/nice-modal-react';
import { DetailsDrawer } from 'src/ui/DetailsDrawer';
import useConvertDuration from 'src/utils/hooks/useConvertDuration';
import { convertDateISOToDateStringFormat } from 'src/utils/convertDateISOToDateStringFormat';
import { convertItemSize } from 'src/utils/convertItemSize';
import { useTranslation } from 'react-i18next';
import FoldersService from '../../services/FoldersService';
export const useDetailsFolder = () => {
    const { t, i18n } = useTranslation();
    const { convertDuration } = useConvertDuration();
    const getFolderData = async (code) => {
        return await FoldersService.getFolderByCode(code);
    };
    const showDetailsFolder = async (code) => {
        const data = await getFolderData(code);
        const details = {
            type: 'folder',
            size: convertItemSize(data.folderSize || 0),
            durationLabel: t('details.folder_medias_total_time'),
            durationValue: convertDuration(data.totalDuration || 0),
            code: data.code,
            itemsAmount: data.itemsAmount
                ? `${`${data.itemsAmount} `} ${t('details.items')}`
                : '-',
            parentFolderName: data.parentFolder?.default_folder
                ? t('details.default_folder')
                : data.parentFolder?.name,
            createDate: data.createDate
                ? convertDateISOToDateStringFormat(data.createDate, i18n.language)
                : '',
            updateDate: data.updateDate
                ? convertDateISOToDateStringFormat(data.updateDate, i18n.language)
                : '',
        };
        NiceModal.show(DetailsDrawer, { detailsItem: details });
    };
    return { showDetailsFolder };
};
