import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { Toast } from 'src/ui/cosmos/Toast';
import ApiError from 'src/infrastructure/services/Api/ApiError';
import { useTranslation } from 'react-i18next';
import PlaybackControlCustomizerService from '../../services/PlaybackControlCustomizerService';
const useUpdatePlaybackControlCustomizer = ({ mediaCode }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ preferences, }) => PlaybackControlCustomizerService.update({
            preferences,
            mediaCode,
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.PLAYBACK_CONTROL, mediaCode],
            });
            Toast.success({ message: t('controls.toast.success') });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
};
export default useUpdatePlaybackControlCustomizer;
