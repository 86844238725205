// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rMhgBBuZSPqDlzeS5JVX{display:flex;justify-content:center;height:100%;max-height:100%}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartContentGauge/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rMhgBBuZSPqDlzeS5JVX`
};
export default ___CSS_LOADER_EXPORT___;
