import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
export const ListRoot = ({ children, data }) => {
    const [rowSelection, setRowSelection] = useState({});
    const table = useReactTable({
        data,
        getRowId: (row) => row.code,
        columns: [],
        getCoreRowModel: getCoreRowModel(),
        state: {
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
    });
    const hasItemSelected = table.getSelectedRowModel().rows.length === 0;
    useEffect(() => {
        if (hasItemSelected) {
            table.resetRowSelection();
        }
    }, [hasItemSelected, table]);
    return _jsx("div", { children: children(table) });
};
