import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FileErrorType, FileStatus, } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import { TextTruncate } from 'src/ui/TextTruncate';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Error = ({ item }) => {
    const { t } = useTranslation();
    const { runUpload } = useFileUploadProcess();
    const errorTypeMessage = {
        [FileErrorType.OVER_SIZE]: t('upload.upload_status.error.oversize'),
        [FileErrorType.UNSUPPORTED_FILE]: t('upload.upload_status.error.unsupported_file'),
    };
    return (_jsxs("div", { "data-testid": 'file-upload-component-error', className: styles.error, children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.fileName, children: item.file.name }) }) }), _jsx("div", { className: styles.message, children: _jsxs("span", { children: [t('upload.upload_status.error.message'), item.errorType && ` - ${errorTypeMessage[item.errorType]}`, item.status === FileStatus.ERROR && item.isValid && (_jsx(Button, { className: styles.button, context: 'danger', variation: 'tertiary', "data-testid": 'button-retry-send-file', onClick: () => {
                                runUpload(item);
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.UPLOAD_MEDIA_RETRY);
                            }, children: t('upload.upload_status.error.try_again') }))] }) })] }));
};
export default Error;
