import { ListRow } from 'src/domain/Library/components/List/ListRow';
import { ListHeader } from './ListHeader';
import { ListBody } from './ListBody';
import { ListPagination } from './ListPagination';
import { ListRoot } from './ListRoot';
export const List = {
    Root: ListRoot,
    Header: ListHeader,
    Body: ListBody,
    Pagination: ListPagination,
    Row: ListRow,
};
