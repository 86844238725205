// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eb58nQurVoz3jsOyigcC{display:flex;justify-content:space-between;color:var(--hc-color-neutral-900);font-size:var(--hc-size-text-medium);font-weight:var(--hc-font-weight-regular);line-height:var(--hc-font-line-height-large)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Media/Settings/containers/PlaybackControlCustomizer/components/ControlPanel/ControlPanelOption/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,iCAAA,CACA,oCAAA,CACA,yCAAA,CACA,4CAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  color: var(--hc-color-neutral-900);\n  font-size: var(--hc-size-text-medium);\n  font-weight: var(--hc-font-weight-regular);\n  line-height: var(--hc-font-line-height-large);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eb58nQurVoz3jsOyigcC`
};
export default ___CSS_LOADER_EXPORT___;
