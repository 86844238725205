import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import useAuth from 'src/application/hooks/useAuth';
import { PlanInfo } from 'src/domain/User/Plan/containers/PlanInfo';
import { Can } from 'src/infrastructure/libs/CASL';
import { Card } from 'src/ui/cosmosWeb/Card';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { FoldersPreview } from './containers/FoldersPreview';
import { MediasPreview } from './containers/MediasPreview';
import { Totalizers } from './containers/Totalizers';
import styles from './styles.module.scss';
export const Home = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.container, children: [_jsxs(Card, { className: styles.welcomeCard, children: [_jsx(Heading, { type: 'h2', children: _jsx(Trans, { i18nKey: 'general.hello_highlighted_name', values: { name: user.profile.name }, components: { strong: _jsx("strong", {}) } }) }), _jsx("p", { children: t('general.hello_info') })] }), _jsxs("div", { children: [_jsx(Can, { I: 'read', a: 'Plan', children: _jsx(PlanInfo, {}) }), _jsx(Totalizers, {})] }), _jsx(MediasPreview, {}), _jsx(FoldersPreview, {})] }));
};
