import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import '@hotmart-org-ca/cosmos/dist/pagination/pagination.css';
import '@hotmart-org-ca/cosmos/dist/pagination/variations/small.css';
import '@hotmart-org-ca/cosmos/dist/pagination/variations/large.css';
import { generatePagesArray } from './utils/index';
import { Ellipsis } from './Ellipsis';
import { PaginationItem } from './PaginationItem';
const siblingsCount = 2;
export const Pagination = ({ totalCountOfItems, onPageChange, currentPage, registersPerPage, size = 'md', }) => {
    const lastPage = Math.ceil(totalCountOfItems / registersPerPage);
    const previousPages = currentPage > 1
        ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
        : [];
    const nextPages = currentPage < lastPage
        ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
        : [];
    return (_jsxs("hot-pagination", { class: `hot-pagination--${size}`, children: [_jsx("hot-pagination-item", { "data-testid": 'pagination-item-prev', class: `${currentPage === 1 && 'hot-pagination__item--disabled'}`, onClick: () => onPageChange(currentPage - 1), children: _jsx("span", { className: 'hot-pagination__prev' }) }), currentPage > 1 + siblingsCount && (_jsxs(_Fragment, { children: [_jsx(PaginationItem, { number: 1, onPageChange: onPageChange }), currentPage > 2 + siblingsCount && _jsx(Ellipsis, {})] })), previousPages.length > 0 &&
                previousPages.map((page) => (_jsx(PaginationItem, { onPageChange: onPageChange, number: page }, page))), _jsx(PaginationItem, { isActive: true, onPageChange: onPageChange, number: currentPage }), nextPages.length > 0 &&
                nextPages.map((page) => (_jsx(PaginationItem, { number: page, onPageChange: onPageChange }, page))), currentPage + siblingsCount < lastPage && (_jsxs(_Fragment, { children: [currentPage + 1 + siblingsCount < lastPage && _jsx(Ellipsis, {}), _jsx(PaginationItem, { number: lastPage, onPageChange: onPageChange })] })), _jsx("hot-pagination-item", { "data-testid": 'pagination-item-next', class: `${currentPage === lastPage && 'hot-pagination__item--disabled'}`, onClick: () => onPageChange(currentPage + 1), children: _jsx("span", { className: 'hot-pagination__next' }) })] }));
};
