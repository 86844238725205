import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import usePlan from 'src/domain/User/Plan/components/PlanDrawer/hooks/usePlan';
// eslint-disable-next-line max-len
import { ConsumptionMetricsWithPercentage } from 'src/domain/User/Plan/components/PlanDrawer/components/ConsumptionMetricsWithPercentage';
import { Loading } from 'src/ui/cosmos/Loading';
import { useTranslation } from 'react-i18next';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import styles from './styles.module.scss';
export const PlanInfo = () => {
    const { t } = useTranslation();
    const { data: plan, isSuccess, isLoading } = usePlan();
    return (_jsxs("div", { className: styles.container, "data-testid": 'plan-info-container', children: [isLoading && _jsx(Loading, { full: true }), _jsx(Heading, { type: 'h3', size: 'small', className: styles.title, children: t `plan.info.summary` }), isSuccess && (_jsxs("div", { className: styles.content, children: [_jsx(ConsumptionMetricsWithPercentage, { label: t `plan.info.storage`, total: plan?.storage, used: plan?.storageConsumption }), _jsx("span", { className: styles.separator }), _jsx(ConsumptionMetricsWithPercentage, { label: t `plan.info.bandwidth`, total: plan?.bandwidth, used: plan?.bandwidthConsumption })] }))] }));
};
