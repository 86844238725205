import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Checkbox } from 'src/ui/Checkbox';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import NiceModal from '@ebay/nice-modal-react';
import { FolderThumb } from 'src/ui/FolderThumb';
import { MediaThumb } from 'src/ui/MediaThumb';
import { MediaPreviewModal } from 'src/domain/Media/components/Modal/MediaPreview';
import { convertItemSize } from 'src/utils/convertItemSize';
import { useTranslation } from 'react-i18next';
import { MediaControls } from './Controls/MediaControls';
import { FolderControls } from './Controls/FolderControls';
import styles from './styles.module.scss';
export const ListItem = ({ item, type, selected, onSelect, onClick, onMove, }) => {
    const { t } = useTranslation();
    function handleSelect() {
        onSelect(item);
        EventPrepAndTrackService.sendDebouncedInteractClick(selected
            ? IntentEvent.LIBRARY_UNSELECT_ONE_ITEM
            : IntentEvent.LIBRARY_SELECT_ONE_ITEM, 'checkbox');
    }
    const hasRestrictions = type === 'media' && Number(item.domainsSize) > 0;
    return (_jsxs("div", { className: classNames(styles.listItem, { [styles.selected]: selected }), "data-testid": `list-item-${type}`, children: [_jsx(Checkbox, { className: styles.addMarginBottomToLabel, id: item?.code.toString(), checked: selected, onChange: handleSelect }), _jsxs("div", { className: styles.info, onClick: onClick, children: [_jsxs("div", { className: styles.requiredInfo, children: [type === 'folder' ? (_jsx(FolderThumb, { className: classNames({
                                    [styles.addBoxShadowToMediaFolder]: selected,
                                }), size: 'xsmall' })) : (_jsx(MediaThumb, { size: 'xsmall', mediaCode: item.code, mediaType: item.type, onClick: (e) => {
                                    e.stopPropagation();
                                    NiceModal.show(MediaPreviewModal, { media: item });
                                } })), _jsx(Paragraph, { className: styles.paragraph, children: _jsx(TextTruncate, { children: item?.name }) })] }), _jsx("div", { className: styles.extraInfo, children: type === 'media' ? (_jsxs(_Fragment, { children: [hasRestrictions ? (_jsx(Tag, { context: 'warning', children: t('library.list.list_item.restricted') })) : (_jsx(Tag, { shadow: selected ? 'small' : '', children: t('library.list.list_item.unrestricted') })), _jsx("span", { children: convertItemSize(item.size || 0) })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { role: 'separator', "aria-hidden": 'true' }), _jsx("span", { children: convertItemSize(item.folderSize || 0) })] })) })] }), type === 'folder' ? (_jsx(FolderControls, { onMove: () => onMove?.(), folder: item })) : (_jsx(MediaControls, { media: item, onMove: () => onMove?.() }))] }));
};
