import { useCallback } from 'react';
export const usePlayerEvents = (playerRef) => {
    const sendMessage = useCallback((data) => {
        playerRef.current?.contentWindow?.postMessage(JSON.stringify(data), '*');
    }, [playerRef]);
    const setComponentsVisibility = useCallback((preferences) => {
        const data = {
            event: 'SET_COMPONENTS_VISIBILITY',
            payload: preferences,
        };
        sendMessage(data);
    }, [sendMessage]);
    return {
        setComponentsVisibility,
    };
};
