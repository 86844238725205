import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from '../../services/SubtitleService';
export const useDeleteSubtitle = () => {
    const queryClient = useQueryClient();
    const deleteSubtitle = useMutation({
        mutationFn: ({ mediaCode, subtitleCode, }) => SubtitleService.delete({ mediaCode, subtitleCode }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SUBTITLE, variables.mediaCode],
            });
        },
    });
    return {
        deleteSubtitle,
    };
};
