// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oBOAZoer7TtnG0wswaAt{margin-bottom:1rem}.rLHAWcK04Gu2KjCoXKjn{display:none}.npWl7URHsIkzuG3F_iaQ{width:100%;--hc-button-border-radius: var(--hc-size-spacing-2)}.f47OfnaUPamV6MMgQbsC{--hc-grid-col-padding: 0 var(--hc-size-spacing-1) 0 var(--hc-size-spacing-3)}.cdj6lMVQF4rmETZRCDKZ{--hc-p-margin: 0px 0px 0.5rem 0px}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/Form/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,YAAA,CAGF,sBACE,UAAA,CACA,mDAAA,CAGF,sBACE,4EAAA,CAGF,sBACE,iCAAA","sourcesContent":[".alert {\n  margin-bottom: 1rem;\n}\n\n.inputFileHidden {\n  display: none;\n}\n\n.sendFileButton {\n  width: 100%;\n  --hc-button-border-radius: var(--hc-size-spacing-2);\n}\n\n.formFieldsContent {\n  --hc-grid-col-padding: 0 var(--hc-size-spacing-1) 0 var(--hc-size-spacing-3);\n}\n\n.paragraph {\n  --hc-p-margin: 0px 0px 0.5rem 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `oBOAZoer7TtnG0wswaAt`,
	"inputFileHidden": `rLHAWcK04Gu2KjCoXKjn`,
	"sendFileButton": `npWl7URHsIkzuG3F_iaQ`,
	"formFieldsContent": `f47OfnaUPamV6MMgQbsC`,
	"paragraph": `cdj6lMVQF4rmETZRCDKZ`
};
export default ___CSS_LOADER_EXPORT___;
