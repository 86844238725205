import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { DeleteItemsModal } from 'src/application/pages/Library/components/Modal/DeleteItemsModal';
class Modal {
    static createIfNotExistPortalContainer() {
        Modal._portalContainer = document.getElementById('modals-area');
        if (Modal._root) {
            return Modal._root;
        }
        Modal._portalContainer = document.createElement('div');
        Modal._portalContainer.id = 'modals-area';
        Modal._portalContainer.setAttribute('data-testid', 'modals-area');
        document.body.appendChild(Modal._portalContainer);
        Modal._root = ReactDOM.createRoot(Modal._portalContainer);
        return Modal._root;
    }
    static clearModalAreaContent() {
        if (Modal._portalContainer) {
            Modal._root.render(null);
        }
    }
}
Object.defineProperty(Modal, "_portalContainer", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: null
});
Object.defineProperty(Modal, "renderModal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (component) => {
        const container = Modal.createIfNotExistPortalContainer();
        container.render(_jsx(_Fragment, { children: component }));
    }
});
export const modals = {
    confirmationDeleteItems: ({ title, description, buttons, checkConfirmDescription, onDismiss, }) => {
        Modal.renderModal(_jsx(DeleteItemsModal, { title: title, description: description, buttons: buttons, checkConfirmDescription: checkConfirmDescription, onDismiss: () => {
                onDismiss?.();
                Modal.clearModalAreaContent();
            } }));
    },
};
