// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lTNr1pjzEoMve40Ffvqb{--hc-modal-dialog-max-width: 47.5rem;--hc-modal-dialog-max-height: 28.75rem;--hc-modal-dialog-border-radius: 1rem;--hc-modal-content-padding: 0 var(--hc-size-spacing-5) 0 var(--hc-size-spacing-5);--hc-modal-header-padding: var(--hc-size-spacing-5) var(--hc-size-spacing-5) 0.5625rem;--hc-modal-content-overflow-y: hidden}.x3bv7AODKwX4LwZmpScg{height:17.5rem}`, "",{"version":3,"sources":["webpack://./src/domain/Folder/containers/Modal/MoveModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,sCAAA,CACA,qCAAA,CACA,iFAAA,CACA,sFAAA,CACA,qCAAA,CAGF,sBACE,cAAA","sourcesContent":[".modal {\n  --hc-modal-dialog-max-width: 47.5rem;\n  --hc-modal-dialog-max-height: 28.75rem;\n  --hc-modal-dialog-border-radius: 1rem;\n  --hc-modal-content-padding: 0 var(--hc-size-spacing-5) 0 var(--hc-size-spacing-5);\n  --hc-modal-header-padding: var(--hc-size-spacing-5) var(--hc-size-spacing-5) 0.5625rem;\n  --hc-modal-content-overflow-y: hidden;\n}\n\n.content {\n  height: 17.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `lTNr1pjzEoMve40Ffvqb`,
	"content": `x3bv7AODKwX4LwZmpScg`
};
export default ___CSS_LOADER_EXPORT___;
