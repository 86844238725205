import { providers } from './providers';
class TrackingFacadeService {
    sendInteractEvent(data) {
        providers.interaction.forEach((provider) => provider.sendInteractEvent(data));
    }
    sendErrorEvent(data) {
        providers.error.forEach((provider) => provider.sendErrorEvent(data));
    }
}
export default new TrackingFacadeService();
