import { useCallback, useContext, useMemo } from 'react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { isUploading } from '../../../utils/fileStatus';
import { MediaUploadContext } from '../../MediaUploadContext';
export const useRemoveFileOnUpload = () => {
    const { files, cancelFileUpload, pauseFileUpload, resumeFileUpload, removeFileFromFileListByInternalFileHash, removeAllFileFromList, } = useContext(MediaUploadContext);
    const canCancelAllFilesUpload = useMemo(() => Object.values(files).some((file) => file.canCancel), [files]);
    const handleOnRemoveFile = useCallback((file) => {
        if (file.internalFileHash && isUploading(file.status)) {
            cancelFileUpload(file.internalFileHash);
        }
        removeFileFromFileListByInternalFileHash(file.internalFileHash);
    }, [cancelFileUpload, removeFileFromFileListByInternalFileHash]);
    const getUploadingFiles = useCallback(() => Object.values(files).filter((file) => isUploading(file.status)), [files]);
    const handleOnRemoveAllFileUploading = useCallback(() => {
        getUploadingFiles().forEach((file) => {
            handleOnRemoveFile(file);
        });
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.UPLOAD_MEDIAS_CANCEL);
    }, [getUploadingFiles, handleOnRemoveFile]);
    const handlePauseAllFileUpload = useCallback(() => {
        getUploadingFiles().forEach((file) => {
            pauseFileUpload(file.internalFileHash);
        });
    }, [getUploadingFiles, pauseFileUpload]);
    const handleResumeAllFileUpload = useCallback(() => {
        getUploadingFiles().forEach((file) => {
            resumeFileUpload(file.internalFileHash);
        });
    }, [getUploadingFiles, resumeFileUpload]);
    const allFileUploadingDelete = useCallback(async (confirmation) => {
        handlePauseAllFileUpload();
        const isConfirmed = await confirmation();
        if (isConfirmed) {
            handleOnRemoveAllFileUploading();
        }
        handleResumeAllFileUpload();
    }, [
        handleOnRemoveAllFileUploading,
        handlePauseAllFileUpload,
        handleResumeAllFileUpload,
    ]);
    const fileUploadingDelete = useCallback(async (file, confirmation) => {
        pauseFileUpload(file.internalFileHash);
        const isConfirmed = await confirmation();
        if (isConfirmed) {
            handleOnRemoveFile(file);
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.UPLOAD_MEDIA_CANCEL);
        }
        setTimeout(() => resumeFileUpload(file.internalFileHash), 100);
    }, [handleOnRemoveFile, pauseFileUpload, resumeFileUpload]);
    return {
        canCancelAllFilesUpload,
        fileUploadingDelete,
        allFileUploadingDelete,
        removeAllFileFromList,
    };
};
