// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zpSv8Fw8UL0vMn9LCT5t{flex:1}.zpSv8Fw8UL0vMn9LCT5t ._h6fxZJSZNIjVbLPmgFp{--hc-form-field-background-color: var(--white);--hc-form-field-focus-background-color:	var(--white);--hc-form-field-border-width: .0625rem;--hc-form-field-border-style: solid;--hc-form-field-border-color: var(--hc-color-neutral-400)}.zpSv8Fw8UL0vMn9LCT5t input{height:2.5rem}.zpSv8Fw8UL0vMn9LCT5t .m5GXjFyiRCD9fjksOUGK{height:100%;border-left:solid 1px var(--gray-400)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/containers/LibraryContent/LibrarySearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CAEA,4CACE,8CAAA,CACA,oDAAA,CACA,sCAAA,CACA,mCAAA,CACA,yDAAA,CAGF,4BACE,aAAA,CAGF,4CACE,WAAA,CACA,qCAAA","sourcesContent":[".searchContainer {\n  flex: 1;\n\n  .formField {\n    --hc-form-field-background-color: var(--white);\n    --hc-form-field-focus-background-color:\tvar(--white);\n    --hc-form-field-border-width: .0625rem;\n    --hc-form-field-border-style: solid;\n    --hc-form-field-border-color: var(--hc-color-neutral-400);\n  }\n\n  input {\n    height: 2.5rem;\n  }\n\n  .searchButton {\n    height: 100%;\n    border-left: solid 1px var(--gray-400);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `zpSv8Fw8UL0vMn9LCT5t`,
	"formField": `_h6fxZJSZNIjVbLPmgFp`,
	"searchButton": `m5GXjFyiRCD9fjksOUGK`
};
export default ___CSS_LOADER_EXPORT___;
