import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { useTranslation } from 'react-i18next';
import { Heading } from '../cosmosWeb/Heading';
import { DrawerItem } from './components/DrawerItem';
import DrawerImage from './components/DrawerImage';
import styles from './styles.module.scss';
export const DetailsDrawer = NiceModal.create(({ detailsItem }) => {
    const { t } = useTranslation();
    const drawerRef = useRef(null);
    const modal = useModal();
    return (_jsx("div", { className: styles.container, children: _jsxs(Drawer, { opened: true, ref: drawerRef, onCloseDrawer: modal.remove, className: styles.drawer, position: 'end', children: [_jsx(Heading, { slot: 'heading', size: 'small', children: _jsx("span", { className: styles.heading, children: t('general.details') }) }), _jsxs("div", { slot: 'content', children: [_jsx(DrawerImage, { type: detailsItem.type, code: detailsItem.code }), _jsxs("div", { className: styles.itemsContainer, children: [_jsx(DrawerItem, { label: t('details.type'), children: _jsx(Paragraph, { children: t(`details.${detailsItem.type}`) }) }), _jsx(DrawerItem, { label: t('details.size'), children: _jsx(Paragraph, { "data-testid": 'details-size', children: detailsItem.size }) }), _jsx(DrawerItem, { label: t('details.create_date'), children: _jsx(Paragraph, { "data-testid": 'details-create-date', children: detailsItem.createDate }) }), _jsx(DrawerItem, { label: t('details.update_date'), children: _jsx(Paragraph, { "data-testid": 'details-update-date', children: detailsItem.updateDate }) }), detailsItem.itemsAmount && (_jsx(DrawerItem, { label: t('details.items_quantity'), children: _jsx(Paragraph, { "data-testid": 'details-items-quantity', children: detailsItem.itemsAmount }) })), _jsx(DrawerItem, { label: detailsItem.durationLabel, children: _jsx(Paragraph, { "data-testid": 'details-total-time', children: detailsItem.durationValue }) })] }), _jsx(DrawerItem, { label: t('details.where'), children: _jsx(Tag, { className: styles.tag, children: detailsItem.parentFolderName }) })] })] }) }));
});
