import { FileErrorType } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
const isValid = (file, fileConfigProps) => {
    const { size, type } = file;
    const { byteSizeLimit, supportedFiles } = fileConfigProps;
    if (size > byteSizeLimit)
        return { valid: false, errorType: FileErrorType.OVER_SIZE };
    if (!supportedFiles.includes(type))
        return { valid: false, errorType: FileErrorType.UNSUPPORTED_FILE };
    return { valid: true, errorType: undefined };
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isValid,
};
