export const MIX_CATEGORY_FOLDER_AND_MEDIA = 'folder_and_media';
export const translationLabels = {
    media: {
        one: {
            title: 'modal.media.delete.one.title',
            description: 'modal.media.delete.one.description',
        },
        many: {
            title: 'modal.media.delete.many.title',
            description: 'modal.media.delete.many.description',
        },
    },
    folder: {
        one: {
            title: 'modal.folder.delete.one.title',
            description: 'modal.folder.delete.one.description',
        },
        many: {
            title: 'modal.folder.delete.many.title',
            description: 'modal.folder.delete.many.description',
        },
    },
    folder_and_media: {
        one: {
            title: 'modal.library.delete_medias_and_folders.title',
            description: 'modal.library.delete_medias_and_folders.description',
        },
        many: {
            title: 'modal.library.delete_medias_and_folders.title',
            description: 'modal.library.delete_medias_and_folders.description',
        },
    },
};
