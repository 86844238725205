export var MediaStatus;
(function (MediaStatus) {
    MediaStatus["PROCESSING"] = "PROCESSING";
    MediaStatus["READY"] = "READY";
    MediaStatus["FAILED"] = "FAILED";
    MediaStatus["QUEUED"] = "QUEUED";
    MediaStatus["UPLOAD_STARTED"] = "UPLOAD_STARTED";
})(MediaStatus || (MediaStatus = {}));
export var MediaProcessStatus;
(function (MediaProcessStatus) {
    MediaProcessStatus["PROCESSING"] = "PROCESSING";
    MediaProcessStatus["READY"] = "READY";
    MediaProcessStatus["FAILED"] = "FAILED";
    MediaProcessStatus["QUEUED"] = "QUEUED";
    MediaProcessStatus["THUMBNAIL_READY"] = "THUMBNAIL_READY";
})(MediaProcessStatus || (MediaProcessStatus = {}));
