export const appendSurvicateScript = ({ visitorTraitsData = {}, }) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.dataset.testid = 'survicate-script';
    scriptTag.text = `                                
      (function(w) {
        var s = document.createElement('script');                                
        s.src = 'https://survey.survicate.com/workspaces/9cd0d9cb3823f32f6cb3492eb6e7d1e4/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      })(window);

      (function(opts) {
        opts.traits = ${JSON.stringify(visitorTraitsData)}
      })(window._sva = window._sva || {});
    `;
    document.body.appendChild(scriptTag);
};
