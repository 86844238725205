import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentError } from 'src/ui/ContentError';
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import { useTranslation } from 'react-i18next';
import searchWithNoResultImg from 'public/assets/svg/search_with_no_result.svg';
import Input from 'src/ui/Input';
import { FormField } from 'src/ui/cosmosWeb/FormField';
import { Button } from 'src/ui/cosmosWeb/Button';
import { faSearch, FontAwesomeIcon, } from 'src/application/icons/fontAwesome/light';
import { faArrowLeft } from 'src/application/icons/fontAwesome/regular';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import AccountOption from 'src/domain/User/Accounts/components/AccountOption';
import styles from './styles.module.scss';
const Accounts = () => {
    const { t } = useTranslation();
    const { data, isLoading, isError, searchAccounts, isEmptyResult, selectAccount, } = useAccounts();
    if (isLoading)
        return (_jsx("div", { className: styles.container, "data-testid": 'account-loading', children: _jsx("div", { className: styles.content, children: _jsx(Loading, { full: true }) }) }));
    if (isError)
        return (_jsx("div", { className: styles.container, "data-testid": 'content-error', children: _jsx("div", { className: styles.content, children: _jsx(ContentError, { description: t('accounts.error.description'), title: t('accounts.error.title') }) }) }));
    return (_jsx("div", { className: styles.container, children: _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.contentHeader, children: [_jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', href: '/', children: _jsx(FontAwesomeIcon, { icon: faArrowLeft }) }), _jsx("h2", { children: t('accounts.title_page') }), _jsxs(FormField, { iconPosition: 'end', children: [_jsx(Input, { placeholder: t('accounts.search.placeholder'), onChange: (e) => searchAccounts(e.target?.value), type: 'search' }), _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faSearch }) })] })] }), isEmptyResult ? (_jsx(ContentFeedback, { title: t('accounts.empty_result.title'), description: t('accounts.empty_result.description'), svg: searchWithNoResultImg })) : (_jsx("div", { className: styles.accountsList, children: data?.map((account) => (_jsx(AccountOption, { image: account.accountOwnerAvatar, name: account.accountOwnerName, onClick: () => {
                            selectAccount(account.accountOwnerMarketplaceId);
                        } }, account.accountOwnerMarketplaceId))) }))] }) }));
};
export default Accounts;
