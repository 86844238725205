// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FRQ7N1sAdSsEbNqRKvaH div{display:flex;flex:1;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/domain/Folder/containers/Modal/MoveModal/EmptyAlert/styles.module.scss"],"names":[],"mappings":"AACE,0BACE,YAAA,CACA,MAAA,CACA,sBAAA","sourcesContent":[".alert {\n  div {\n    display: flex;\n    flex: 1;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `FRQ7N1sAdSsEbNqRKvaH`
};
export default ___CSS_LOADER_EXPORT___;
