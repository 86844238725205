export var ControlPlayback;
(function (ControlPlayback) {
    ControlPlayback["MEDIA_TITLE"] = "mediaTitle";
    ControlPlayback["FULL_SCREEN"] = "fullScreen";
    ControlPlayback["PICTURE_IN_PICTURE"] = "pictureInPicture";
    ControlPlayback["PERSONAL_NOTES"] = "personalNotes";
    ControlPlayback["SEEK_BAR"] = "seekBar";
    ControlPlayback["REWIND"] = "rewind";
    ControlPlayback["FORWARD"] = "forward";
    ControlPlayback["SPEED_CONTROL"] = "speedControl";
})(ControlPlayback || (ControlPlayback = {}));
