import validateSizeAndTypeByConfig from 'src/infrastructure/services/Upload/validation/validateSizeAndTypeByConfig';
import { FileStatus, } from '../interfaces/FileUploadProps';
import { MediaStatus } from '../interfaces/MediaProcessProps';
import { getFileTypeFromFile } from './getFileTypeFromFile';
export const parseProcessedMediaStatusToFileStatus = {
    [MediaStatus.FAILED]: FileStatus.ERROR,
    [MediaStatus.QUEUED]: FileStatus.OPTIMIZING,
    [MediaStatus.PROCESSING]: FileStatus.OPTIMIZING,
    [MediaStatus.UPLOAD_STARTED]: FileStatus.PROGRESS,
    [MediaStatus.READY]: FileStatus.SUCCESS,
};
export const isAmountFilesLessOrEqualMaxFileAmount = (files, maxAmount = 1) => files.length <= maxAmount;
export const sliceFilesToMaxAmount = (files, maxAmount = 1) => Array.from(files).slice(0, maxAmount);
export const parseFiles = ({ files, folderCode, fileConfigProps, }) => {
    const parsedFile = {};
    if (files.length === 0) {
        return parsedFile;
    }
    for (const file of files) {
        const internalFileHash = `${file.name}-${Date.now()}`;
        const { valid, errorType } = validateSizeAndTypeByConfig.isValid(file, fileConfigProps);
        parsedFile[internalFileHash] = {
            internalFileHash,
            file,
            folderCode,
            type: getFileTypeFromFile(file),
            status: FileStatus.QUEUE,
            isValid: valid,
            errorType: errorType,
        };
    }
    return parsedFile;
};
const removeStatusToRetryUpload = (fileStatus) => fileStatus === FileStatus.ERROR ? FileStatus.FATAL_ERROR : fileStatus;
const convertStatus = (currentStatus) => {
    const newStatus = removeStatusToRetryUpload(parseProcessedMediaStatusToFileStatus[currentStatus] ||
        parseProcessedMediaStatusToFileStatus.PROCESSING);
    return newStatus;
};
export const parseFilesToContinueProcessing = (files) => {
    const parsedFile = {};
    if (files.length === 0) {
        return parsedFile;
    }
    for (const file of files) {
        const internalFileHash = `${file.name}-${file.code}`;
        parsedFile[internalFileHash] = {
            file: file,
            type: file.type,
            mediaCode: file.code,
            folderCode: file.folderCode,
            internalFileHash,
            status: convertStatus(file.status),
            isValid: true,
        };
    }
    return parsedFile;
};
