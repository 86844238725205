// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TykaDbMCZaI2P5DonJLk{display:grid;grid-auto-rows:auto;gap:1rem;width:100%;--hc-h-margin: 0;--hc-h-small-lg-font-size: var(--hc-size-text-2extralarge)}.TykaDbMCZaI2P5DonJLk .r3R36fUoS8egOOmEGKkU{width:100%;max-width:100%;height:100%;min-height:31.25rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/containers/Analytics/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,mBAAA,CACA,QAAA,CACA,UAAA,CAEA,gBAAA,CACA,0DAAA,CAEA,4CACE,UAAA,CACA,cAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".container {\n  display: grid;\n  grid-auto-rows: auto;\n  gap: 1rem;\n  width: 100%;\n\n  --hc-h-margin: 0;\n  --hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);\n\n  .retentionChart {\n    width: 100%;\n    max-width: 100%;\n    height: 100%;\n    min-height: 31.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TykaDbMCZaI2P5DonJLk`,
	"retentionChart": `r3R36fUoS8egOOmEGKkU`
};
export default ___CSS_LOADER_EXPORT___;
