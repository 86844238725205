// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._mVaAGXT7ZPVXgXTqQPm{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);padding:.5rem 1.125rem;background-color:#fafafa;display:flex;justify-content:center;align-items:center;gap:.5rem;border-radius:.5rem;border:1px solid var(--hc-color-neutral-200)}._mVaAGXT7ZPVXgXTqQPm button{background:none;border:none;margin:0;padding:0;font-size:var(--hc-text-medium);font-weight:var(--hc-font-weight-extrabold);color:var(--hc-color-info-500);cursor:pointer}._mVaAGXT7ZPVXgXTqQPm button:focus:not(:focus-visible){outline:none}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/components/List/ListHeader/SelectionCount/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,sBAAA,CACA,wBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,mBAAA,CACA,4CAAA,CAEA,6BACE,eAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,+BAAA,CACA,2CAAA,CACA,8BAAA,CACA,cAAA,CAEA,uDACE,YAAA","sourcesContent":[".container {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 0.5rem 1.125rem;\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n  border-radius: 0.5rem;\n  border: 1px solid var(--hc-color-neutral-200);\n\n  button {\n    background: none;\n    border: none;\n    margin: 0;\n    padding: 0;\n    font-size: var(--hc-text-medium);\n    font-weight: var(--hc-font-weight-extrabold);\n    color: var(--hc-color-info-500);\n    cursor: pointer;\n\n    &:focus:not(:focus-visible) {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_mVaAGXT7ZPVXgXTqQPm`
};
export default ___CSS_LOADER_EXPORT___;
