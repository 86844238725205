import { Toast } from 'src/ui/cosmos/Toast';
class ApiError extends Error {
    constructor({ statusCode, message }) {
        super();
        Object.defineProperty(this, "_statusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._statusCode = statusCode;
        this._message = message;
    }
    get statusCode() {
        return this._statusCode;
    }
    get message() {
        return this._message;
    }
    notify(message) {
        Toast.error({ message });
    }
}
export default ApiError;
