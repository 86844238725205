/* eslint-disable max-lines */
import Api from 'src/infrastructure/services/Api';
const url = `${process.env.API_GATEWAY}/v2/folder`;
const DEFAULT_ELEMENT_PER_PAGE = 10;
const DEFAULT_PAGE = 0;
const createFolder = async ({ name, parentFolderCode }) => {
    const response = await Api.call({
        method: 'POST',
        url,
        data: {
            name,
            parentFolderCode,
        },
    });
    return response.data;
};
const getFolderParents = async ({ code }) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/${code}/breadcrumb`,
    });
    return data;
};
const getFolderByCode = async (code) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/${code}`,
    });
    return data;
};
const updateFolders = async ({ name, code }) => {
    const response = await Api.call({
        method: 'PUT',
        url: `${url}/${code}`,
        data: {
            name,
        },
    });
    return response.data;
};
const deleteItems = async ({ itemCodes: folderCodes }) => {
    await Api.call({
        method: 'POST',
        url: `${url}/delete`,
        data: {
            codes: folderCodes,
        },
    });
};
const getFolderItems = async ({ code, page = DEFAULT_PAGE, elementPerPage = DEFAULT_ELEMENT_PER_PAGE, category, }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${url}/${code}/list`,
        params: {
            page,
            elementPerPage,
            category,
        },
    });
    return response.data;
};
const getMainFolderItems = async ({ page = DEFAULT_PAGE, elementPerPage = DEFAULT_ELEMENT_PER_PAGE, category, }) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/home`,
        params: {
            page,
            elementPerPage,
            category,
        },
    });
    return data;
};
const moveItemsToFolder = async ({ sourceCodes, targetFolderCode, }) => {
    return Api.call({
        method: 'POST',
        url: `${url}/move`,
        data: {
            codes: sourceCodes,
            targetFolderCode,
        },
    });
};
const searchItems = async (name) => {
    const { data } = await Api.call({
        method: 'POST',
        url: `${url}/search`,
        data: {
            name,
        },
    });
    return data;
};
const getFolderCount = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/count`,
    });
    return data;
};
const getLatestFolders = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/recents`,
        params: {
            elementPerPage: 4,
        },
    });
    return data;
};
export { createFolder, updateFolders, getFolderByCode, deleteItems, getFolderParents, getMainFolderItems, getFolderItems, moveItemsToFolder, searchItems, getFolderCount, getLatestFolders, };
