import axios, { HttpStatusCode, } from 'axios';
import AuthService from 'src/infrastructure/services/Auth';
import AccountStorage from 'src/infrastructure/persistence/accounts/storage';
import EventPrepAndTrackService from '../Tracking/EventPrepAndTrackService';
import ApiError from './ApiError';
class Api {
    constructor() {
        Object.defineProperty(this, "_api", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "responseWithError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    AuthService.logout();
                }
                if (error.response) {
                    const responseError = new ApiError({
                        statusCode: error.response.status,
                        message: error.response.data.message,
                    });
                    EventPrepAndTrackService.sendErrorEvent({
                        message: error?.response?.data?.message,
                        stack: error?.stack,
                        apiURL: error?.config?.url,
                        statusCode: error?.status,
                    });
                    throw responseError;
                }
                /**
                 * @todo send error to Sentry?
                 */
                const genericError = new ApiError({
                    statusCode: 500,
                    message: error.message,
                });
                EventPrepAndTrackService.sendErrorEvent({
                    message: error?.message,
                    stack: error?.stack,
                    apiURL: error?.config?.url,
                    statusCode: error?.status || 500,
                });
                throw genericError;
            }
        });
        this._api = axios.create();
        this._api.interceptors.request.use(async (request) => {
            const user = await AuthService.getUser();
            request.headers.Authorization = `Bearer ${user.id_token}`;
            return request;
        });
        this._api.interceptors.response.use((response) => response, this.responseWithError);
    }
    cancelRequestSource() {
        const cancelToken = axios.CancelToken;
        return cancelToken.source();
    }
    call({ url, method, data, headers, ...rest }) {
        const accountId = AccountStorage.currentAccountId;
        const customHeaders = {
            ...headers,
            'hotmart-target-account-id': accountId,
        };
        return this._api.request({
            url,
            method,
            data,
            headers: customHeaders,
            ...rest,
        });
    }
}
export default new Api();
