import { useContext } from 'react';
import { MediaUploadContext } from '..';
export const useFileUploadProcess = () => {
    const { files, onSelectFiles, runUpload, generalUploadState } = useContext(MediaUploadContext);
    return {
        files,
        onSelectFiles,
        runUpload,
        generalUploadState,
    };
};
