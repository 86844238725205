// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aWcvcuwFbi9t84FbRELJ{display:flex;flex-direction:column;height:100%}.aWcvcuwFbi9t84FbRELJ .SlZu9LK14LTkwweRDNnI{margin-bottom:var(--hc-size-spacing-3)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Domain/container/Domain/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,4CACE,sCAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  .alert {\n    margin-bottom: var(--hc-size-spacing-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aWcvcuwFbi9t84FbRELJ`,
	"alert": `SlZu9LK14LTkwweRDNnI`
};
export default ___CSS_LOADER_EXPORT___;
