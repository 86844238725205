// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WAuH8qiLdYI4na3TQZO8{display:flex;flex-direction:column;gap:.25rem;--hc-p-margin: 0 0 0;margin-bottom:var(--hc-size-spacing-4)}`, "",{"version":3,"sources":["webpack://./src/ui/DetailsDrawer/components/DrawerItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,oBAAA,CACA,sCAAA","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    gap: .25rem;\n    \n    --hc-p-margin: 0 0 0;\n    margin-bottom: var(--hc-size-spacing-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WAuH8qiLdYI4na3TQZO8`
};
export default ___CSS_LOADER_EXPORT___;
