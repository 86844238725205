import { useQueryClient } from 'src/application/hooks/useRequest';
import { Toast } from 'src/ui/cosmos/Toast';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useTranslation } from 'react-i18next';
import { useMediaUpdate } from '../useMediaUpdate';
export const useMediaRename = () => {
    const queryClient = useQueryClient();
    const { updateMedia } = useMediaUpdate();
    const { t } = useTranslation();
    const renameMedia = async ({ media, name }) => {
        return updateMedia.mutateAsync({ code: media.code, name }, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.LIBRARY],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.SEARCH],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.FOLDER_ITEMS],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.MEDIA_DATA],
                });
                const message = t('library.modal.rename_item.notify.media', {
                    old_name: media.name,
                    new_name: name,
                });
                Toast.info({ message });
            },
        });
    };
    return {
        renameMedia,
        isLoading: updateMedia.isLoading,
    };
};
