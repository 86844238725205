// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qT4RmLNc_XuAxMDQRlYD{display:flex;align-items:flex-start;justify-content:space-between}.slXOYDO0bfWRgUTKmnHD{display:flex;align-items:center;color:var(--hc-color-info-500);background:none;border:none;padding:0;font-size:var(--hc-size-text-medium);cursor:pointer}.slXOYDO0bfWRgUTKmnHD:focus:not(:focus-visible){outline:none}.slXOYDO0bfWRgUTKmnHD>svg{margin-left:1rem;width:var(--hc-size-spacing-4);height:var(--hc-size-spacing-4)}.JXOoqBJstavNeKvDzCgu{--hc-h-nano-lg-font-size: var(--hc-size-text-large);--hc-h-margin: 0 0 var(--hc-size-spacing-2);--hc-h-nano-strong-font-weight: var(--hc-font-weight-bold) --hc-h-nano-md-strong-font-weight: var(--hc-font-weight-bold) --hc-h-nano-lg-strong-font-weight: var(--hc-font-weight-bold) }`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/components/CardList/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,8BAAA,CACA,eAAA,CACA,WAAA,CACA,SAAA,CACA,oCAAA,CACA,cAAA,CAEA,gDACE,YAAA,CAGF,0BACE,gBAAA,CACA,8BAAA,CACA,+BAAA,CAIJ,sBACE,mDAAA,CACA,2CAAA,CACA,uLAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n}\n\n.rightButton {\n  display: flex;\n  align-items: center;\n  color: var(--hc-color-info-500);\n  background: none;\n  border: none;\n  padding: 0;\n  font-size: var(--hc-size-text-medium);\n  cursor: pointer;\n\n  &:focus:not(:focus-visible) {\n    outline: none;\n  }\n\n  > svg {\n    margin-left: 1rem;\n    width: var(--hc-size-spacing-4);\n    height: var(--hc-size-spacing-4);\n  }\n}\n\n.title {\n  --hc-h-nano-lg-font-size: var(--hc-size-text-large);\n  --hc-h-margin: 0 0 var(--hc-size-spacing-2);\n  --hc-h-nano-strong-font-weight: var(--hc-font-weight-bold)\n  --hc-h-nano-md-strong-font-weight: var(--hc-font-weight-bold)\n  --hc-h-nano-lg-strong-font-weight: var(--hc-font-weight-bold)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `qT4RmLNc_XuAxMDQRlYD`,
	"rightButton": `slXOYDO0bfWRgUTKmnHD`,
	"title": `JXOoqBJstavNeKvDzCgu`
};
export default ___CSS_LOADER_EXPORT___;
