import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Alert } from 'src/ui/cosmosWeb/Alert';
import { Trans, useTranslation } from 'react-i18next';
import { Radio } from '../Radio';
import { SubtitleType } from '../../interfaces/subtitle';
import styles from './styles.module.scss';
const messages = {
    UPLOAD: 'library.settings.subtitle.types.upload.info',
    TRANSCRIPTION: 'library.settings.subtitle.types.transcription.info',
    TRANSLATION: 'library.settings.subtitle.types.translation.info',
};
export const SubtitleTypeSelector = ({ subtitleType, onChooseSubtitleType, thereAreSubtitle, }) => {
    const { t } = useTranslation();
    const alertMessage = subtitleType ? messages[subtitleType] : '';
    return (_jsxs("div", { className: styles.container, children: [_jsx(Paragraph, { size: 'micro', strong: true, children: t('library.settings.subtitle.types.title') }), _jsxs("div", { className: styles.radios, children: [_jsx(Radio, { id: 'upload', "data-testid": 'subtitle-upload-radio-button', label: t('library.settings.subtitle.types.upload.title'), onChange: () => onChooseSubtitleType(SubtitleType.UPLOAD), checked: subtitleType === SubtitleType.UPLOAD }), _jsx(Radio, { id: 'transcription', "data-testid": 'subtitle-transcription-radio-button', label: t('library.settings.subtitle.types.transcription.title'), onChange: () => onChooseSubtitleType(SubtitleType.TRANSCRIPTION), checked: subtitleType === SubtitleType.TRANSCRIPTION, disabled: thereAreSubtitle, tooltipText: t('library.settings.subtitle.types.transcription.tooltip_text') }), _jsx(Radio, { id: 'translate', "data-testid": 'subtitle-translate-radio-button', label: t('library.settings.subtitle.types.translation.title'), onChange: () => onChooseSubtitleType(SubtitleType.TRANSLATION), checked: subtitleType === SubtitleType.TRANSLATION, disabled: !thereAreSubtitle, tooltipText: !thereAreSubtitle
                            ? t('library.settings.subtitle.types.translation.tooltip_text')
                            : '' })] }), alertMessage && (_jsx(Alert, { "data-testid": 'subtitle-type-alert', children: _jsx(Trans, { i18nKey: alertMessage, components: { strong: _jsx("strong", {}) } }) }))] }));
};
