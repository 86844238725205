import { useInfiniteQuery } from 'src/application/hooks/useRequest';
import FoldersService from 'src/domain/Folder/services/FoldersService';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
export const useGetFolders = ({ folder, isThereAnyFolderOpen = true, }) => {
    return useInfiniteQuery({
        queryKey: [QueryKeys.MOVE_MODAL_FOLDER, folder?.code],
        queryFn: ({ pageParam }) => {
            return isThereAnyFolderOpen
                ? FoldersService.getFolderItems({
                    code: String(folder?.code),
                    category: !folder?.belongsToFolder ? 'media' : undefined,
                })
                : FoldersService.getMainFolderItems({
                    page: pageParam,
                    category: 'folder',
                });
        },
        getNextPageParam: (lastPage, pages) => {
            const maxPage = lastPage.totalPages;
            const nextPage = pages.length;
            return nextPage < maxPage ? nextPage : undefined;
        },
    });
};
