import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Player } from 'src/ui/Player';
import { Loading } from 'src/ui/cosmos/Loading';
import { useMediaData } from 'src/domain/Media/hooks';
import styles from './styles.module.scss';
const PlayerPreview = forwardRef(function PlayerPreviewComponent({ mediaCode }, ref) {
    const { isLoading, data: mediaData, isSuccess, } = useMediaData({ mediaCode });
    return (_jsxs("div", { className: styles.playerContainer, children: [isSuccess && (_jsx(Player, { media: mediaData, ref: ref, editMode: true })), isLoading && _jsx(Loading, { full: true })] }));
});
export default PlayerPreview;
