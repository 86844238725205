// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MGc_a_dQt2uLFlnZIAeY{--hc-p-margin: 1.25rem 0 var(--hc-size-spacing-4)}.J_SvV2uVjnUYgKDupZ0e{display:flex;flex-direction:column;flex:1;width:100%;padding-bottom:.0625rem;gap:var(--hc-size-spacing-2)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Domain/components/DomainList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,UAAA,CACA,uBAAA,CACA,4BAAA","sourcesContent":[".paragraph {\n  --hc-p-margin: 1.25rem 0 var(--hc-size-spacing-4);\n}\n\n.domainList {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n  padding-bottom: 0.0625rem;\n  gap: var(--hc-size-spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `MGc_a_dQt2uLFlnZIAeY`,
	"domainList": `J_SvV2uVjnUYgKDupZ0e`
};
export default ___CSS_LOADER_EXPORT___;
