import { jsx as _jsx } from "react/jsx-runtime";
//import Script from 'next/script';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import useAuth from 'src/application/hooks/useAuth';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import useExternalScripts from 'src/utils/hooks/useExternalScripts';
import styles from './styles.module.scss';
const CardChartContentRetention = ({ mediaCode, }) => {
    const { user } = useAuth();
    const { currentAccount } = useAccounts();
    useExternalScripts({ url: process.env.NEXT_PUBLIC_RETENTION_CHART });
    return (_jsx(CardBody, { children: _jsx("div", { className: styles.container, children: _jsx("hotmart-player-retention-chart", { mediaId: mediaCode, token: user.id_token, accountId: currentAccount?.accountOwnerMarketplaceId, fullWidth: true, hideTitle: true, hideStatistics: true }) }) }));
};
export default CardChartContentRetention;
