// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ek1HQZfjN6YIWqBBdS8A{display:flex;flex-direction:column;height:100%}.O51Fe_afai2qRwsBa4Kl{--hc-p-micro-lg-font-size: var(--hc-size-text-medium);--hc-p-margin: 0 0 1.125rem}.Vd9RHWedB18PG6M50Us9{margin-top:1rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/containers/SubtitleContainer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CAGF,sBACE,qDAAA,CACA,2BAAA,CAGF,sBACE,eAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.paragraph {\n  --hc-p-micro-lg-font-size: var(--hc-size-text-medium);\n  --hc-p-margin: 0 0 1.125rem;\n}\n\n.listItemLoading {\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ek1HQZfjN6YIWqBBdS8A`,
	"paragraph": `O51Fe_afai2qRwsBa4Kl`,
	"listItemLoading": `Vd9RHWedB18PG6M50Us9`
};
export default ___CSS_LOADER_EXPORT___;
