// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tKeX4WP5NBLWnIXQW4bS{padding-top:2.5rem;display:grid;gap:1.5rem;grid-template-rows:auto}.tKeX4WP5NBLWnIXQW4bS .RcwdLtBkQR6O7Limd0Is{border:solid 1px var(--hc-color-neutral-200);--hc-h-base-lg-font-size:	var(--hc-size-text-2extralarge);--hc-h-base-md-font-size:	var(--hc-size-text-2extralarge);--hc-h-margin: 0}.tKeX4WP5NBLWnIXQW4bS .RcwdLtBkQR6O7Limd0Is p{color:var(--hc-color-neutral-500);margin:0}.tKeX4WP5NBLWnIXQW4bS>div{display:flex;flex-direction:column;gap:1rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,UAAA,CACA,uBAAA,CAEA,4CACE,4CAAA,CACA,yDAAA,CACA,yDAAA,CACA,gBAAA,CAEA,8CACE,iCAAA,CACA,QAAA,CAKJ,0BACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n  padding-top: 2.5rem;\n  display: grid;\n  gap: 1.5rem;\n  grid-template-rows: auto;\n\n  .welcomeCard {\n    border: solid 1px var(--hc-color-neutral-200);\n    --hc-h-base-lg-font-size: \tvar(--hc-size-text-2extralarge);\n    --hc-h-base-md-font-size: \tvar(--hc-size-text-2extralarge);\n    --hc-h-margin: 0;\n    \n    p {\n      color: var(--hc-color-neutral-500);\n      margin: 0;\n    }\n\n  }\n\n  & > div {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tKeX4WP5NBLWnIXQW4bS`,
	"welcomeCard": `RcwdLtBkQR6O7Limd0Is`
};
export default ___CSS_LOADER_EXPORT___;
