import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome/light';
import styles from './styles.module.scss';
const MenuItem = ({ active = false, className, icon, text, ...rest }) => {
    return (_jsxs("hot-menu-item", { class: classNames({
            [styles.active]: active,
        }, className), ...rest, children: [_jsx("div", { className: classNames('hot-application-menu__icon', styles.icon), children: _jsx(FontAwesomeIcon, { icon: icon }) }), _jsx("span", { children: text })] }));
};
export default MenuItem;
