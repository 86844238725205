import PlaybackControlCustomizerApi from '../PlaybackControlCustomizerApi';
class PlaybackControlCustomizerService {
    async get({ mediaCode }) {
        return PlaybackControlCustomizerApi.get({ mediaCode });
    }
    async update({ preferences, mediaCode, }) {
        return PlaybackControlCustomizerApi.put({ preferences, mediaCode });
    }
}
export default new PlaybackControlCustomizerService();
