import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { LibraryContentFilter } from '../../containers/LibraryContent/LibraryContentFilter';
import { UploadAction } from '../UploadAction';
import styles from './styles.module.scss';
export const Header = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.contentTitle, children: [_jsx(Heading, { type: 'h2', children: t('library.title') }), _jsx(UploadAction, { dropdownButton: {
                            className: styles.dropdownButton,
                            label: t('general.new'),
                        } })] }), _jsx(LibraryContentFilter, {})] }));
};
