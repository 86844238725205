// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vL13O5ysYptBnTbUGmqV{flex:1;width:100%;padding-bottom:.0625rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/List/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,UAAA,CACA,uBAAA","sourcesContent":[".scrollListContainer {\n  flex: 1;\n  width: 100%;\n  padding-bottom: 0.0625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollListContainer": `vL13O5ysYptBnTbUGmqV`
};
export default ___CSS_LOADER_EXPORT___;
