import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { extractDomainAndSubdomainFrom, isValidDomain } from '../../utils/url';
import { DomainList } from '../../components/DomainList';
import { Form } from '../../components/Form';
import SelectedMediaList from '../../components/SelectedMediaList';
import { useDomain } from '../../hooks/useDomain';
import styles from './styles.module.scss';
export const Domain = ({ medias: selectedMedias }) => {
    /**
     * @todo refactor to deal with multiple media or with just one media code. Maybe separate into two components
     */
    const { mediaCode } = useParams();
    const media = { code: mediaCode };
    const modal = useModal('modal-delete-domain');
    const [medias, setMedias] = useState(selectedMedias || [media]);
    const [url, setUrl] = useState('');
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation();
    const { domains, addDomain, removeDomain } = useDomain({
        medias,
    });
    const isMoreThanOneMedia = medias.length > 1;
    const handleAddDomain = async () => {
        setShowError(false);
        const extractedDomain = extractDomainAndSubdomainFrom(url);
        if (!isValidDomain(extractedDomain)) {
            setShowError(true);
            return;
        }
        addDomain.mutate({
            extractedDomain,
        });
        setUrl('');
        EventPrepAndTrackService.sendDebouncedInteractClick(isMoreThanOneMedia
            ? IntentEvent.SETTINGS_MEDIA_RESTRICTIONS_MULTIPLE
            : IntentEvent.SETTINGS_MEDIA_RESTRICTION);
    };
    const deleteDomains = useCallback(async (domain) => {
        const mediaDomainCodes = isMoreThanOneMedia
            ? domains.data
                ?.filter((d) => d.domain === domain.domain)
                .map((d) => d.id)
            : [domain.id];
        await removeDomain.mutateAsync({
            mediaDomainCodes: mediaDomainCodes,
        });
    }, [domains.data, isMoreThanOneMedia, removeDomain]);
    const removeMediaFromSelectedList = useCallback((selectedMedia) => {
        setMedias((oldMedias) => [
            ...oldMedias.filter((media) => media.code !== selectedMedia.code),
        ]);
    }, []);
    const handleDeleteDomain = useCallback(async (domain) => {
        await modal.show();
        await deleteDomains(domain);
        modal.hide();
    }, [deleteDomains, modal]);
    const shouldShowDomainList = (isMoreThanOneMedia && domains.data && domains.data.length > 0) ||
        medias.length == 1;
    const getUniqueDomains = useCallback(() => {
        const result = domains.data?.reduce((accumulator, current) => {
            if (!accumulator.find((domain) => domain.domain === current.domain)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        return result;
    }, [domains]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(BasicConfirmationModal, { id: 'modal-delete-domain', title: t('modal.domain.delete.one.title'), loading: removeDomain.isLoading, description: t('modal.domain.delete.one.title'), buttons: {
                    no: {
                        color: 'tertiary',
                        context: 'neutral',
                        title: t('modal.domain.delete.buttons.no'),
                    },
                    yes: {
                        color: 'primary',
                        context: 'danger',
                        title: t('modal.domain.delete.buttons.yes'),
                    },
                } }), _jsx(Paragraph, { size: 'micro', children: t('library.settings.restriction.description') }), isMoreThanOneMedia && (_jsx(SelectedMediaList, { canDeselect: domains.data?.length === 0, medias: medias, onDeselect: removeMediaFromSelectedList })), _jsx(Form, { value: url, loading: addDomain.isLoading, handleChangeValue: setUrl, handleAddDomain: handleAddDomain, displayInvalidMessage: showError }), shouldShowDomainList && (_jsx(DomainList, { domains: (isMoreThanOneMedia
                    ? getUniqueDomains()
                    : domains.data), loading: domains.isLoading, handleDeleteDomain: handleDeleteDomain }))] }));
};
