import { jsx as _jsx } from "react/jsx-runtime";
//import dynamic from 'next/dynamic';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import FallbackError from './FallbackError';
const ErrorBoundary = ({ children }) => (_jsx(ReactErrorBoundary, { FallbackComponent: FallbackError, onError: (error, info) => {
        /**
         * @todo Send error to app monitoring platform (Sentry for instance)
         */
        console.error({ error, info });
    }, children: children }));
export default ErrorBoundary;
