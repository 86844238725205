import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { faArrowUpFromBracket } from 'src/application/icons/fontAwesome/light';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const UploadFileButton = ({ disabled, onSelectFile, selectedFile, supportedFiles, ...rest }) => {
    const { t } = useTranslation();
    const inputFileRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("input", { ref: inputFileRef, "data-testid": 'input-file', className: styles.inputFileHidden, type: 'file', value: selectedFile, accept: supportedFiles, onChange: onSelectFile }), _jsx(IconButton, { icon: faArrowUpFromBracket, className: styles.sendFileButton, onClick: () => inputFileRef.current?.click(), disabled: disabled, ...rest, children: t('general.send_file') })] }));
};
