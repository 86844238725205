// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vMiKtWeXyu2iyISrPbrx{background:var(--hc-color-primary-300);border-radius:var(--hc-size-border-radius-large);height:5.25rem;width:9.6875rem;overflow:hidden;position:relative}.vMiKtWeXyu2iyISrPbrx img{width:100%;height:100%}.vMiKtWeXyu2iyISrPbrx .DqbSIB2zyyfX1SFTkDMV{left:40%;position:relative;top:25%}.AnwhJPFOANInarB6prHO{height:5.25rem;width:9.6875rem;overflow:hidden;position:relative;justify-content:center;align-items:center;display:flex;border:solid var(--gray-200) .0187rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Thumbnail/components/ThumbnailImage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,gDAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CAGF,4CACE,QAAA,CACA,iBAAA,CACA,OAAA,CAIJ,sBACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qCAAA","sourcesContent":[".mediaThumb {\n  background: var(--hc-color-primary-300);\n  border-radius: var(--hc-size-border-radius-large);\n  height: 5.25rem;\n  width: 9.6875rem;\n  overflow: hidden;\n  position: relative;\n\n  img {\n    width: 100%;\n    height: 100%;\n  }\n\n  .loading {\n    left: 40%;\n    position: relative;\n    top: 25%;\n  }\n}\n\n.audioThumb {\n  height: 5.25rem;\n  width: 9.6875rem;\n  overflow: hidden;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  border: solid var(--gray-200) 0.0187rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaThumb": `vMiKtWeXyu2iyISrPbrx`,
	"loading": `DqbSIB2zyyfX1SFTkDMV`,
	"audioThumb": `AnwhJPFOANInarB6prHO`
};
export default ___CSS_LOADER_EXPORT___;
