import { isProduction } from 'src/utils/env';
import packageJson from 'src/../package.json';
class CASProvider {
    async subscribe(onSubscribe) {
        const { default: CAS, Bootstrap } = await import('@hotmart/cas-js');
        console.log('cas:', process.env.CAS_TOKEN);
        const cas = new CAS({
            env: isProduction() ? 'production' : 'development',
            client_id: process.env.CAS_TOKEN,
            scope: 'openid profile email',
        });
        CASProvider.bootstrap = new Bootstrap(cas, {
            appName: packageJson.name,
            signinRedirect: true,
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
        });
        CASProvider.bootstrap.subscribe({
            next: onSubscribe,
            error: (error) => {
                console.error(error);
            },
        });
        return CASProvider.bootstrap;
    }
    async logout(isSlo = false) {
        if (isSlo === false) {
            await CASProvider.bootstrap.logout();
        }
        localStorage.clear();
    }
    async getUser() {
        return CASProvider.bootstrap.getUser();
    }
}
export default CASProvider;
