import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from 'src/domain/Folder/services/FoldersService';
export const useSearchItems = (value) => {
    return useQuery({
        queryKey: [QueryKeys.SEARCH, value],
        queryFn: () => FoldersService.searchItems(value),
        //keepPreviousData: true,
    });
};
