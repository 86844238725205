import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useModal } from '@ebay/nice-modal-react';
import { HotProgress } from 'src/ui/cosmos/Progress';
import { faClose } from 'src/application/icons/fontAwesome/light';
// eslint-disable-next-line max-len
import { useRemoveFileOnUpload } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useRemoveFileOnUpload';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { TextTruncate } from 'src/ui/TextTruncate';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Progress = ({ item }) => {
    const { t } = useTranslation();
    const confirmationModal = useModal(BasicConfirmationModal);
    const { fileUploadingDelete } = useRemoveFileOnUpload();
    const confirmFileUploadingDelete = async () => {
        const confirmed = await confirmationModal.show({
            title: t('modal.interrupt_upload.one.title'),
            description: t('modal.interrupt_upload.one.description'),
            buttons: {
                yes: {
                    title: t('modal.interrupt_upload.one.buttons.yes'),
                },
                no: {
                    title: t('modal.interrupt_upload.one.buttons.no'),
                    color: 'tertiary',
                },
            },
        });
        confirmationModal.hide();
        return !!confirmed;
    };
    return (_jsxs("div", { "data-testid": 'file-upload-component-progress', className: styles.progress, children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.fileName, children: item.file.name }) }) }), item.canCancel && (_jsx(IconButton, { icon: faClose, className: styles.icon, onClick: () => fileUploadingDelete(item, confirmFileUploadingDelete), "data-testid": 'button-remove-file' })), _jsx("div", { className: styles.progressBar, children: _jsx(HotProgress, { value: item.progress || 0, color: 'success', thickness: 'small' }) })] }));
};
export default Progress;
