// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fn1jKaCWbbM3dmd77ICa{text-align:center;margin:.25rem}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Pagination/Ellipsis/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA","sourcesContent":[".ellipsis {\n  text-align: center;\n  margin: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsis": `fn1jKaCWbbM3dmd77ICa`
};
export default ___CSS_LOADER_EXPORT___;
