// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrSpkCfjTy4diiuPp8PQ{position:relative}.PrSpkCfjTy4diiuPp8PQ .b0H9Sl1kq6T5vCcvRFNk{height:fit-content;min-height:calc(100vh - var(--structure-header-height))}.PrSpkCfjTy4diiuPp8PQ .b0H9Sl1kq6T5vCcvRFNk main{width:100%;min-height:100%;padding:0 1.5rem 1.5rem 1.5rem}.PrSpkCfjTy4diiuPp8PQ .b8HPSRBOScWt03Z6tcjb{margin-left:auto;margin-right:auto}@media(min-width: 1200px){.PrSpkCfjTy4diiuPp8PQ .b8HPSRBOScWt03Z6tcjb{max-width:87.5rem}}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Structure/Content/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,kBAAA,CACA,uDAAA,CAEA,iDACE,UAAA,CACA,eAAA,CACA,8BAAA,CAIJ,4CACE,gBAAA,CACA,iBAAA,CACA,0BAHF,4CAII,iBAAA,CAAA","sourcesContent":[".content {\n  position: relative;\n\n  .mainWrapper {\n    height: fit-content;\n    min-height: calc(100vh - var(--structure-header-height));\n\n    main {\n      width: 100%;\n      min-height: 100%;\n      padding: 0 1.5rem 1.5rem 1.5rem;\n    }\n  }\n\n  .whiteSpace {\n    margin-left: auto;\n    margin-right: auto;\n    @media (min-width: 1200px) {\n      max-width: 87.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `PrSpkCfjTy4diiuPp8PQ`,
	"mainWrapper": `b0H9Sl1kq6T5vCcvRFNk`,
	"whiteSpace": `b8HPSRBOScWt03Z6tcjb`
};
export default ___CSS_LOADER_EXPORT___;
