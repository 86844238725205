import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FolderThumb } from 'src/ui/FolderThumb';
import { MediaThumb } from 'src/ui/MediaThumb';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const DrawerImage = ({ type, code }) => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styles.container, children: _jsxs("div", { children: [_jsx(Tag, { context: 'neutral', className: styles.tag, children: t('media.thumbnail') }), type === 'folder' ? (_jsx(FolderThumb, { size: 'xlarge' })) : (_jsx(MediaThumb, { size: 'xlarge', showPlayIcon: false, mediaCode: code }))] }) }));
};
export default DrawerImage;
