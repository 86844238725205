import en from 'src/application/transifex/en/common.json';
import fr from 'src/application/transifex/fr/common.json';
import de from 'src/application/transifex/de/common.json';
import es from 'src/application/transifex/es/common.json';
import it from 'src/application/transifex/it/common.json';
import pt from 'src/application/transifex/pt-BR/common.json';
export const resources = {
    en: { translation: en },
    fr: { translation: fr },
    de: { translation: de },
    es: { translation: es },
    it: { translation: it },
    'pt-BR': { translation: pt },
};
