import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import AnalyticsService from '../services/AnalyticsService';
export const normalizeData = (data) => {
    return data?.reduce((obj, item) => Object.assign(obj, { [item.name]: parseFloat(item.value) ?? 0 }), {});
};
const getAnalyticsData = async (mediaCode) => {
    const data = await AnalyticsService.getAnalyticsData(mediaCode);
    return normalizeData(data);
};
const useAnalytics = (mediaCode) => {
    return useQuery({
        queryKey: [QueryKeys.REPORT_METRICS, mediaCode],
        queryFn: () => getAnalyticsData(mediaCode),
    });
};
export default useAnalytics;
