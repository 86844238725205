// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O5TNeg3ARy1L_qVyb3Ro{--hc-dropdown-item-border-bottom: none}.O5TNeg3ARy1L_qVyb3Ro .zORsdsgjtZaCYmKaLuMX{--hc-dropdown-header-box-shadow: none;--hc-dropdown-header-border-bottom: none;--hc-dropdown-header-label-font-size: var(--hc-size-text-small);color:var(--gray-500)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/components/UploadAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,4CACE,qCAAA,CACA,wCAAA,CACA,+DAAA,CACA,qBAAA","sourcesContent":[".dropdown {\n  --hc-dropdown-item-border-bottom: none;\n  .dropdownHeaderItem {\n    --hc-dropdown-header-box-shadow: none;\n    --hc-dropdown-header-border-bottom: none;\n    --hc-dropdown-header-label-font-size: var(--hc-size-text-small);\n    color: var(--gray-500);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `O5TNeg3ARy1L_qVyb3Ro`,
	"dropdownHeaderItem": `zORsdsgjtZaCYmKaLuMX`
};
export default ___CSS_LOADER_EXPORT___;
