import Api from 'src/infrastructure/services/Api';
const url = `${process.env.API_GATEWAY}/v2/subscription`;
async function getAuthorization() {
    const { data } = await Api.call({
        method: 'GET',
        url: `${url}/authorization`,
    });
    return data;
}
export { getAuthorization };
