import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from 'src/domain/Media/modules/Subtitle/services/SubtitleService';
export const useTranscribeSubtitle = () => {
    const queryClient = useQueryClient();
    const onSuccess = (data, variables) => {
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.SUBTITLE, variables.mediaCode],
        });
    };
    const transcribeSubtitle = useMutation({
        mutationFn: ({ mediaCode }) => SubtitleService.transcribe({ mediaCode }),
        onSuccess,
    });
    return {
        transcribeSubtitle,
    };
};
