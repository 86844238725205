// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Am8gEeQOGoKn7_2lhWsg{height:1.5rem;width:100%}.Am8gEeQOGoKn7_2lhWsg .xkYGbrFZznnEKUWyTaKP{font-family:var(--hc-font-family-primary);font-weight:var(--hc-font-weight-extrabold);font-size:var(--hc-size-text-small);line-height:var(--hc-font-line-large)}.Am8gEeQOGoKn7_2lhWsg .MnBUspz03hp4lx2_6rAP{color:var(--hc-color-neutral-700);font-weight:var(--hc-font-weight-light);font-size:var(--hc-size-text-extralarge);line-height:var(--hc-font-line-large);text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/domain/User/Plan/components/PlanDrawer/components/Plan/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,UAAA,CAEA,4CACE,yCAAA,CACA,2CAAA,CACA,mCAAA,CACA,qCAAA,CAGF,4CACE,iCAAA,CACA,uCAAA,CACA,wCAAA,CACA,qCAAA,CACA,yBAAA","sourcesContent":[".container {\n  height: 1.5rem;\n  width: 100%;\n\n  .name {\n    font-family: var(--hc-font-family-primary);\n    font-weight: var(--hc-font-weight-extrabold);\n    font-size: var(--hc-size-text-small);\n    line-height: var(--hc-font-line-large);\n  }\n\n  .planType {\n    color: var(--hc-color-neutral-700);\n    font-weight: var(--hc-font-weight-light);\n    font-size: var(--hc-size-text-extralarge);\n    line-height: var(--hc-font-line-large);\n    text-transform: capitalize;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Am8gEeQOGoKn7_2lhWsg`,
	"name": `xkYGbrFZznnEKUWyTaKP`,
	"planType": `MnBUspz03hp4lx2_6rAP`
};
export default ___CSS_LOADER_EXPORT___;
