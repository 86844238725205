import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faXmarkLarge } from 'src/application/icons/fontAwesome/light';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const Domain = ({ domain, handleDelete }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { "data-testid": 'domain', className: styles.domain, children: [_jsx("span", { children: domain.domain }), _jsx(Tooltip, { text: t('general.remove'), children: _jsx("button", { "data-testid": 'delete-domain-button', onClick: () => handleDelete(domain), children: _jsx(FontAwesomeIcon, { icon: faXmarkLarge }) }) })] }));
};
