// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oyIZcdl8E6h0TDfwvaxY{display:flex;width:100%;gap:1.5rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/Totalizers/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".totalizers {\n  display: flex;\n  width: 100%;\n  gap: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalizers": `oyIZcdl8E6h0TDfwvaxY`
};
export default ___CSS_LOADER_EXPORT___;
