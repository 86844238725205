// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aoGqvis9udYQtI30Ruz1{background:var(--hc-color-neutral-0);display:flex;align-items:center;justify-content:center;height:100%}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/containers/FolderContentList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".contentFeedbackWrapper {\n  background: var(--hc-color-neutral-0);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentFeedbackWrapper": `aoGqvis9udYQtI30Ruz1`
};
export default ___CSS_LOADER_EXPORT___;
