import { jsx as _jsx } from "react/jsx-runtime";
import { useFileUploadProcess } from '../../contexts/MediaUploadContext/hooks/useFileUploadProcess';
import Progress from '../TaskUpload/progress';
import Error from '../TaskUpload/error';
import Optimizing from '../TaskUpload/optimizing';
import Queue from '../TaskUpload/queue';
import Success from '../TaskUpload/success';
import { FileStatus } from '../../interfaces/FileUploadProps';
import styles from './styles.module.scss';
const UploadItemStates = {
    [FileStatus.FATAL_ERROR]: Error,
    [FileStatus.ERROR]: Error,
    [FileStatus.OPTIMIZING]: Optimizing,
    [FileStatus.PROGRESS]: Progress,
    [FileStatus.QUEUE]: Queue,
    [FileStatus.SUCCESS]: Success,
};
export const FileUpload = () => {
    const { files } = useFileUploadProcess();
    return (_jsx("div", { "data-testid": 'file-upload-component', className: styles.container, children: Object.values(files).map((item) => {
            const UploadItemStateComponent = UploadItemStates[item.status];
            return (_jsx("div", { className: styles.info, children: _jsx("div", { className: styles.box, children: UploadItemStateComponent && (_jsx(UploadItemStateComponent, { item: item })) }) }, item.internalFileHash));
        }) }));
};
