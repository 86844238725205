import Api from 'src/infrastructure/services/Api';
class PlanApi {
    async getSubscriptionPlan() {
        const response = await Api.call({
            method: 'GET',
            url: `${process.env.API_GATEWAY}/v2/subscription/plan`,
        });
        return response.data;
    }
}
export default new PlanApi();
