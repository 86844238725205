import { getClusterInfo, getProductCountsInfo, getTypeInfo, getUserProfile, } from 'src/infrastructure/endpoints/userInfo';
class UserInfoService {
    async getUserInfo() {
        const [userProfile, typeInfo, clusterInfo, productCounts] = await Promise.all([
            getUserProfile(),
            getTypeInfo(),
            getClusterInfo(),
            getProductCountsInfo(),
        ]);
        return { userProfile, typeInfo, clusterInfo, productCounts };
    }
}
export default new UserInfoService();
