// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q6O7O4HMKxO1r1UjVJgt{width:100%;max-width:100%;display:flex;justify-content:space-between;align-items:flex-start}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA","sourcesContent":[".container {\n  width: 100%;\n  max-width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Q6O7O4HMKxO1r1UjVJgt`
};
export default ___CSS_LOADER_EXPORT___;
