import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Container } from 'src/ui/cosmosWeb/Container';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faCloud } from 'src/application/icons/fontAwesome/light';
import { Loading } from 'src/ui/cosmos/Loading';
import { useTranslation } from 'react-i18next';
import ConsumptionMetrics from './components/ConsumptionMetrics';
import PlanInfo from './components/Plan';
import usePlan from './hooks/usePlan';
import styles from './styles.module.scss';
const PlanDrawer = () => {
    const { t } = useTranslation();
    const { data: plan, isSuccess, isLoading, isStale, refetch } = usePlan();
    const drawerRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', size: 'small', onClick: () => {
                    drawerRef.current?.open();
                    if (isStale) {
                        refetch();
                    }
                }, className: styles.button, "data-testid": 'plan-drawer-button', children: _jsx(FontAwesomeIcon, { icon: faCloud }) }), _jsx(Drawer, { ref: drawerRef, dismissible: false, className: styles.drawer, position: 'top', children: _jsxs("div", { slot: 'content', children: [isLoading && _jsx(Loading, { full: true }), isSuccess && (_jsxs(Container, { full: true, children: [_jsx(PlanInfo, { "data-testid": 'plan-info' }), _jsx(ConsumptionMetrics, { label: t('plan.info.bandwidth'), total: plan.bandwidth, used: plan.bandwidthConsumption, "data-testid": 'plan-bandwidth' }), _jsx(ConsumptionMetrics, { label: t('plan.info.storage'), total: plan.storage, used: plan.storageConsumption, "data-testid": 'plan-storage' })] }))] }) })] }));
};
export default PlanDrawer;
