import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Loading } from 'src/ui/cosmos/Loading';
import { useTranslation } from 'react-i18next';
import { Domain } from '../Domain';
import styles from './styles.module.scss';
export const DomainList = ({ domains, loading, handleDeleteDomain, }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, { className: styles.paragraph, strong: true, children: t('library.settings.restriction.registered') }), _jsx("div", { "data-testid": 'domain-list', className: styles.domainList, children: loading ? (_jsx(Loading, { full: true })) : (domains.map((domain) => (_jsx(Domain, { domain: domain, handleDelete: handleDeleteDomain }, domain.id)))) })] }));
};
