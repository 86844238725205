import { useListDomains } from './queries/useListDomains';
import { useAddDomain } from './mutations/useAddDomain';
import { useRemoveDomain } from './mutations/useRemoveDomain';
export const useDomain = ({ medias }) => {
    const domains = useListDomains({ medias });
    const addDomain = useAddDomain({ medias });
    const removeDomain = useRemoveDomain({ medias });
    return {
        domains,
        addDomain,
        removeDomain,
    };
};
