// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MqE9ig8_Nih4NLZNDIhJ{display:flex;flex-direction:column;gap:.875rem;padding:1.5rem;border-radius:.5rem;border:1px solid var(--hc-color-neutral-200);background-color:var(--hc-color-neutral-0)}.MqE9ig8_Nih4NLZNDIhJ strong{color:var(--hc-color-neutral-1000);font-size:var(--hc-size-text-large);font-weight:var(--hc-font-weight-extrabold);line-height:var(--hc-font-line-height-large)}.MqE9ig8_Nih4NLZNDIhJ .SfTGp6hmMnStz9VYWcUf{display:flex;flex-direction:column;gap:.625rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Media/Settings/containers/PlaybackControlCustomizer/components/ControlPanel/ControlPanelRoot/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,cAAA,CACA,mBAAA,CACA,4CAAA,CACA,0CAAA,CAEA,6BACE,kCAAA,CACA,mCAAA,CACA,2CAAA,CACA,4CAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 0.875rem;\n  padding: 1.5rem;\n  border-radius: 0.5rem;\n  border: 1px solid var(--hc-color-neutral-200);\n  background-color: var(--hc-color-neutral-0);\n\n  strong {\n    color: var(--hc-color-neutral-1000);\n    font-size: var(--hc-size-text-large);\n    font-weight: var(--hc-font-weight-extrabold);\n    line-height: var(--hc-font-line-height-large);\n  }\n\n  .listOption {\n    display: flex;\n    flex-direction: column;\n    gap: 0.625rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MqE9ig8_Nih4NLZNDIhJ`,
	"listOption": `SfTGp6hmMnStz9VYWcUf`
};
export default ___CSS_LOADER_EXPORT___;
