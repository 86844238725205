import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { useMediaUrl } from 'src/domain/Media/hooks';
import { Loading } from '../cosmos/Loading';
import styles from './styles.module.scss';
export const Player = forwardRef(function PlayerComponent({ media, editMode }, ref) {
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    const { isLoading: isLoadingSrc, data: src } = useMediaUrl(media, editMode);
    const isLoading = isLoadingIframe || isLoadingSrc;
    return (_jsxs("div", { className: styles.player, children: [isLoading && (_jsx("div", { className: styles.loading, children: _jsx(Loading, { full: true }) })), _jsx("iframe", { ref: ref, allowFullScreen: true, "data-testid": 'player', className: classNames({ [styles.isLoading]: isLoading }), onLoad: () => setIsLoadingIframe(false), src: src })] }));
});
