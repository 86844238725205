import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'src/ui/cosmosWeb/Alert';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { faCheckCircle, faCircleQuestion, } from 'src/application/icons/fontAwesome/regular';
import useCopyToClipboard from 'src/application/hooks/useCopyToClipboard';
import Input from 'src/ui/Input';
import TextArea from 'src/ui/TextArea';
import { getMediaEmbed, getMediaLink } from 'src/domain/Media/utils/shareLink';
import DomainService from 'src/domain/Media/modules/Domain/services/DomainService';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { Loading } from 'src/ui/cosmos/Loading';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
const ShareMedia = () => {
    const { mediaCode } = useParams();
    const { t } = useTranslation();
    const { copyToClipboard, isCopied } = useCopyToClipboard();
    const [copyType, setCopyType] = useState('');
    const [loading, setLoading] = useState(true);
    const [domains, setDomains] = useState([]);
    const link = getMediaLink(mediaCode);
    const embedCode = getMediaEmbed(mediaCode);
    const handleCopyEmbedCode = () => {
        setCopyType(t('share.embed_code'));
        copyToClipboard(embedCode);
    };
    const handleCopyLink = () => {
        setCopyType(t('share.link'));
        copyToClipboard(link);
    };
    const getMediaDomains = useCallback(async () => {
        try {
            setLoading(true);
            const result = await DomainService.getMediaDomains({
                code: mediaCode,
            });
            setDomains(result);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [mediaCode]);
    useEffect(() => {
        getMediaDomains();
    }, [getMediaDomains]);
    const copyMessage = useMemo(() => {
        return `${copyType} ${t('share.copy_message')}`;
    }, [copyType, t]);
    const hasRestrictions = domains?.length > 0;
    if (loading) {
        return (_jsx("div", { className: styles.container, children: _jsx(Loading, { "data-testid": 'share-loading', full: true }) }));
    }
    return (_jsxs("div", { className: styles.container, children: [isCopied && (_jsxs(Tag, { context: 'success', className: styles.copyTag, shadow: 'small', size: 'large', "data-testid": 'copy-success-alert', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faCheckCircle, width: 40, height: 40 }) }), copyMessage] })), hasRestrictions && (_jsx(Alert, { "data-testid": 'alert-restriction-information', context: 'info', children: _jsx(Trans, { i18nKey: 'share.restriction_information', components: {
                        button: (_jsx(Link, { to: `/library/media/${mediaCode}/restriction`, "data-testid": 'button-restriction-information' })),
                    } }) })), _jsxs("div", { className: styles.contentField, children: [_jsxs("div", { children: [_jsx("label", { children: t('share.link') }), _jsx(Input, { defaultValue: link, disabled: true, "data-testid": 'input-media-link' })] }), _jsx(Button, { variation: 'secondary', "data-testid": 'copy-link-btn', onClick: () => {
                            handleCopyLink();
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_COPY_LINK);
                        }, className: styles.copyButton, children: t('share.copy_link') })] }), _jsxs("div", { className: styles.contentField, children: [_jsxs("div", { children: [_jsx("label", { children: _jsxs(Tooltip, { text: t `share.copy_code_more_info`, children: [t('share.embed_code'), _jsx("sup", { children: _jsx(FontAwesomeIcon, { icon: faCircleQuestion }) })] }) }), _jsx(TextArea, { defaultValue: embedCode, rows: 3, disabled: true, "data-testid": 'testarea-media-embed' })] }), _jsx(Button, { variation: 'secondary', onClick: () => {
                            handleCopyEmbedCode();
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_COPY_EMBED);
                        }, "data-testid": 'copy-embed-btn', className: styles.copyButton, children: t('share.copy_code') })] })] }));
};
export default ShareMedia;
