// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x3qxF9oUH6au8DrKQ3GG{display:flex;width:100%;height:3.75rem;align-items:center;justify-content:center;background-color:var(--hc-color-info-100)}.x3qxF9oUH6au8DrKQ3GG>svg{width:var(--hc-size-spacing-5);height:var(--hc-size-spacing-5);color:var(--hc-color-info-500)}.x3qxF9oUH6au8DrKQ3GG.SRNkcsPIQFLbLMC0FtpH{width:5.625rem;height:3.125rem;border-radius:var(--hc-size-spacing-1)}.x3qxF9oUH6au8DrKQ3GG.Ps2w2C6nqFNAD0S0Rob0{width:6.875rem;height:3.75rem;border-radius:var(--hc-size-spacing-2);min-width:6.875rem}.x3qxF9oUH6au8DrKQ3GG.bi5uSXti8M6Nygb9CCOL{width:23.875rem;height:11.5rem;border-radius:var(--hc-size-spacing-1)}`, "",{"version":3,"sources":["webpack://./src/ui/FolderThumb/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACA,yCAAA,CAEA,0BACE,8BAAA,CACA,+BAAA,CACA,8BAAA,CAGF,2CACE,cAAA,CACA,eAAA,CACA,sCAAA,CAGF,2CACE,cAAA,CACA,cAAA,CACA,sCAAA,CACA,kBAAA,CAGF,2CACE,eAAA,CACA,cAAA,CACA,sCAAA","sourcesContent":[".folderCover {\n  display: flex;\n  width: 100%;\n  height: 3.75rem;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--hc-color-info-100);\n\n  > svg {\n    width: var(--hc-size-spacing-5);\n    height: var(--hc-size-spacing-5);\n    color: var(--hc-color-info-500);\n  }\n\n  &.xsmall {\n    width: 5.625rem;\n    height: 3.125rem;\n    border-radius: var(--hc-size-spacing-1);\n  }\n\n  &.small {\n    width: 6.875rem;\n    height: 3.75rem;\n    border-radius: var(--hc-size-spacing-2);\n    min-width: 6.875rem;\n  }\n\n  &.xlarge {\n    width: 23.875rem;\n    height: 11.5rem;\n    border-radius: var(--hc-size-spacing-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folderCover": `x3qxF9oUH6au8DrKQ3GG`,
	"xsmall": `SRNkcsPIQFLbLMC0FtpH`,
	"small": `Ps2w2C6nqFNAD0S0Rob0`,
	"xlarge": `bi5uSXti8M6Nygb9CCOL`
};
export default ___CSS_LOADER_EXPORT___;
