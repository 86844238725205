import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useTranslation } from 'react-i18next';
import FoldersService from '../../services/FoldersService';
const STALE_TIME = 1000 * 5; // 5 seconds
const useFolderParents = ({ code }) => {
    const { t } = useTranslation();
    const getFolderParents = useCallback(async () => {
        const folderParents = await FoldersService.getFolderParents({
            code,
        });
        const defaultFolderIndex = folderParents.findIndex((folder) => folder.defaultFolder);
        folderParents[defaultFolderIndex].name = t('library.title');
        return folderParents;
    }, [code, t]);
    return useQuery({
        queryKey: [QueryKeys.FOLDER_PARENTS, code],
        queryFn: () => getFolderParents(),
        keepPreviousData: true,
        staleTime: STALE_TIME,
        enabled: !!code,
    });
};
export default useFolderParents;
