import convertSize from 'convert-size';
export const storageStatus = (used, total) => {
    if (used > total)
        return 'danger';
    if (used < total / 2)
        return 'success';
    return 'warning';
};
export const convertSizeUnity = (gigabytes = 0) => {
    if (gigabytes < 0.99) {
        return convertSize(`${gigabytes} GB`, 'MB', {
            stringify: true,
            accuracy: 2,
        });
    }
    if (gigabytes > 999) {
        return convertSize(`${gigabytes} GB`, 'TB', {
            stringify: true,
            accuracy: 2,
        });
    }
    return `${gigabytes} GB`;
};
