// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b123O18G4MvKzs5rveyQ{--hc-select-menu-max-height: 9.375rem;--hc-select-menu-overflow-y: scroll}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/Select/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CACA,mCAAA","sourcesContent":[".select {\n  --hc-select-menu-max-height: 9.375rem;\n  --hc-select-menu-overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `b123O18G4MvKzs5rveyQ`
};
export default ___CSS_LOADER_EXPORT___;
