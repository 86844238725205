import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import UserInfo from 'src/domain/User/Info/services/UserInfo';
import { ProductFruits } from 'react-product-fruits';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/application/hooks/useAuth';
const LOCALE_LENGTH = 2;
export const FruitsOnboarding = () => {
    const [userInfo, setUserInfo] = useState(null);
    const { user } = useAuth();
    const { i18n } = useTranslation();
    const shortLang = i18n.language.slice(0, LOCALE_LENGTH).toLowerCase();
    useEffect(() => {
        const initializeFruits = async () => {
            try {
                const { typeInfo, clusterInfo, productCounts } = await UserInfo.getUserInfo();
                const transformedUserInfo = {
                    username: user.profile.email,
                    firstname: user.profile.name || '',
                    lastname: user.profile.middle_name || '',
                    email: user.profile.email,
                    role: typeInfo.userType,
                    props: {
                        customProp1: clusterInfo.clusterName,
                        productsCounts: String(productCounts.productsQuantity),
                        revenueSegmentation: typeInfo.userSegmentationInfo,
                    },
                };
                setUserInfo(transformedUserInfo);
            }
            catch {
                console.error('Failed to retrieve user info');
            }
        };
        initializeFruits();
    }, [user]);
    if (!userInfo) {
        return null;
    }
    return (_jsx(ProductFruits, { workspaceCode: process.env.FRUITS_WORKSPACE, language: shortLang, user: userInfo }));
};
export default FruitsOnboarding;
