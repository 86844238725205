import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from '../../services/FoldersService';
const useFolderLatest = () => {
    const getLatestFolders = useCallback(async () => {
        const data = await FoldersService.getLatestFolders();
        return data;
    }, []);
    return useQuery({
        queryKey: [QueryKeys.FOLDER_LATEST],
        queryFn: () => getLatestFolders(),
    });
};
export default useFolderLatest;
