// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tPjAMRKDzpti1OnyNV65{--hc-modal-footer-background-color: transparent;--hc-modal-dialog-max-width: 32.875rem;--hc-modal-dialog-border-radius: 0.5rem;--hc-modal-dialog-width: 100%}`, "",{"version":3,"sources":["webpack://./src/ui/cosmosWeb/Modal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,+CAAA,CACA,sCAAA,CACA,uCAAA,CACA,6BAAA","sourcesContent":[".modal {\n  --hc-modal-footer-background-color: transparent;\n  --hc-modal-dialog-max-width: 32.875rem;\n  --hc-modal-dialog-border-radius: 0.5rem;\n  --hc-modal-dialog-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `tPjAMRKDzpti1OnyNV65`
};
export default ___CSS_LOADER_EXPORT___;
