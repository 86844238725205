export var FileErrorType;
(function (FileErrorType) {
    FileErrorType["OVER_SIZE"] = "OVER_SIZE";
    FileErrorType["UNSUPPORTED_FILE"] = "UNSUPPORTED_FILE";
})(FileErrorType || (FileErrorType = {}));
export var FileStatus;
(function (FileStatus) {
    FileStatus["FATAL_ERROR"] = "FATAL_ERROR";
    FileStatus["ERROR"] = "ERROR";
    FileStatus["OPTIMIZING"] = "OPTIMIZING";
    FileStatus["PROGRESS"] = "PROGRESS";
    FileStatus["SUCCESS"] = "SUCCESS";
    FileStatus["QUEUE"] = "QUEUE";
})(FileStatus || (FileStatus = {}));
export var GeneralStatus;
(function (GeneralStatus) {
    GeneralStatus["COMPLETED"] = "COMPLETED";
    GeneralStatus["UPLOADING"] = "UPLOADING";
    GeneralStatus["OPTIMIZING"] = "OPTIMIZING";
    GeneralStatus["ERROR"] = "ERROR";
})(GeneralStatus || (GeneralStatus = {}));
