import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FormField } from 'src/ui/cosmosWeb/FormField';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import styles from './styles.module.scss';
export const BasicPromptModal = NiceModal.create(({ title, value = '', buttons, onDismiss, loading, }) => {
    const modal = useModal();
    const inputRef = useRef(null);
    const [isModalReady, setIsModalReady] = useState(false);
    const [text, setText] = useState(value);
    useEffect(() => {
        if (value)
            setText(value);
        inputRef?.current?.focus();
    }, [isModalReady, value]);
    function handleSubmit(event) {
        event.preventDefault();
        modal.resolve(text);
    }
    return (_jsxs(Modal, { className: styles.modal, onCloseModal: () => {
            modal.remove();
            onDismiss?.();
        }, opened: modal.visible, role: 'dialog', onOpenModal: () => {
            setIsModalReady(true);
        }, children: [_jsx(Heading, { className: styles.heading, strong: true, slot: 'heading', size: 'small', children: title }), _jsx("div", { slot: 'description', children: _jsx("form", { onSubmit: handleSubmit, children: _jsx(FormField, { className: styles.removeTransitionFromInput, children: _jsx("input", { type: 'text', "data-testid": 'input-basic-modal-prompt', value: text, onChange: (e) => setText(e.target.value), ref: inputRef }) }) }) }), _jsx(Button, { onClick: modal.hide, slot: 'controls', variation: 'tertiary', context: 'neutral', children: buttons.cancel.label }), _jsx(Button, { role: 'button', loading: loading, disabled: text.length === 0, "data-testid": 'button-save-basic-modal-prompt', onClick: handleSubmit, slot: 'controls', children: buttons.save.label })] }));
});
