export function convertTime(seconds) {
    const SECONDS_ON_HOURS = 3600;
    const SECONDS_ON_MINUTES = 60;
    if (seconds <= 0) {
        return [0, 0, 0];
    }
    const hours = Math.floor(seconds / SECONDS_ON_HOURS);
    const remainingSeconds = seconds % SECONDS_ON_HOURS;
    const minutes = Math.floor(remainingSeconds / SECONDS_ON_MINUTES);
    const secondsLeft = remainingSeconds % SECONDS_ON_MINUTES;
    return [hours, minutes, secondsLeft];
}
