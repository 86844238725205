import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentError } from 'src/ui/ContentError';
import { FolderBreadcrumb } from 'src/domain/Folder/components/FolderBreadcrumb';
import useFolderParents from 'src/domain/Folder/hooks/useFolderParents';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/ui/cosmos/Breadcrumb';
import { FolderContentList } from '../Library/containers/FolderContentList';
const FolderPage = () => {
    const { folderCode } = useParams();
    const { t } = useTranslation('common');
    const { data, isError } = useFolderParents({ code: folderCode });
    if (isError)
        return (_jsx(ContentError, { description: t('general.error.folder.description'), title: t('general.error.folder.title') }));
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb.Root, { children: _jsx(FolderBreadcrumb, { folders: data || [] }) }), _jsx("div", { children: _jsx(FolderContentList, { folderCode: folderCode }) })] }));
};
export default FolderPage;
