import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Container } from 'src/ui/cosmosWeb/Container';
import { FontAwesomeIcon, faArrowUpToLine, } from 'src/application/icons/fontAwesome/light';
import { FileUploadListContainer } from 'src/domain/Media/modules/MediaUpload/containers/FileUploadListContainer';
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import styles from './styles.module.scss';
const UploadDrawer = () => {
    const drawerRef = useRef(null);
    const uploadListContainerRef = useRef(null);
    const { generalUploadState } = useFileUploadProcess();
    useEffect(() => {
        if (!drawerRef.current) {
            return;
        }
        generalUploadState === GeneralStatus.UPLOADING && drawerRef.current.open();
    }, [generalUploadState]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', className: styles.button, "data-testid": 'button-open-upload-drawer', onClick: () => drawerRef.current?.open(), children: _jsx(FontAwesomeIcon, { icon: faArrowUpToLine }) }), _jsx(Drawer, { ref: drawerRef, dismissible: false, className: classNames(styles.drawer), onOpenDrawer: () => uploadListContainerRef.current?.scrollTo({
                    top: 0,
                }), position: 'top', children: _jsxs("div", { slot: 'content', children: [_jsx(Container, { full: true, children: _jsx(FileUploadListContainer, { ref: uploadListContainerRef }) }), _jsx("footer", { className: styles.contentFooter })] }) })] }));
};
export default UploadDrawer;
