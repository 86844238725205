// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CKMdAQh6fvhaYfB550Z7{--hc-alert-padding: var(--hc-size-spacing-2);--hc-alert-info-color: var(--hc-color-neutral-900)}.CKMdAQh6fvhaYfB550Z7.dWspnpVxVPZovUWcz4YQ{--hc-alert-icon-display: none}.CKMdAQh6fvhaYfB550Z7.eaJUx7vtAqhCa_r4GUTt{--hc-alert-info-border-color: var(--hc-color-info-200)}`, "",{"version":3,"sources":["webpack://./src/ui/cosmosWeb/Alert/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kDAAA,CAEA,2CACE,6BAAA,CAGF,2CACE,sDAAA","sourcesContent":[".alert {\n  --hc-alert-padding: var(--hc-size-spacing-2);\n  --hc-alert-info-color: var(--hc-color-neutral-900);\n\n  &.hideIcon {\n    --hc-alert-icon-display: none;\n  }\n\n  &.info {\n    --hc-alert-info-border-color: var(--hc-color-info-200);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `CKMdAQh6fvhaYfB550Z7`,
	"hideIcon": `dWspnpVxVPZovUWcz4YQ`,
	"info": `eaJUx7vtAqhCa_r4GUTt`
};
export default ___CSS_LOADER_EXPORT___;
