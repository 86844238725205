import { createMongoAbility } from '@casl/ability';
export var MediaActionEnum;
(function (MediaActionEnum) {
    MediaActionEnum["EDIT"] = "edit";
    MediaActionEnum["LIST"] = "list";
    MediaActionEnum["DELETE"] = "delete";
    MediaActionEnum["UPLOAD"] = "upload";
    MediaActionEnum["EMBED"] = "embed";
    MediaActionEnum["DOWNLOAD"] = "download";
    MediaActionEnum["DOMAIN"] = "domain";
})(MediaActionEnum || (MediaActionEnum = {}));
export var PlanActionEnum;
(function (PlanActionEnum) {
    PlanActionEnum["READ"] = "read";
})(PlanActionEnum || (PlanActionEnum = {}));
export const ability = createMongoAbility();
