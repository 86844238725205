import { useEffect } from 'react';
import UserInfo from 'src/domain/User/Info/services/UserInfo';
import { appendSurvicateScript } from './survicate';
export const Csat = () => {
    useEffect(() => {
        const initializeCsat = async () => {
            try {
                const { userProfile, typeInfo, clusterInfo, productCounts } = await UserInfo.getUserInfo();
                appendSurvicateScript({
                    visitorTraitsData: {
                        country: userProfile.country,
                        user_id: String(userProfile.id),
                        email: userProfile.email,
                        language: userProfile.locale,
                        userType: typeInfo.userType,
                        revenueSegmentation: typeInfo.userSegmentationInfo,
                        clusterName: clusterInfo.clusterName,
                        id: String(userProfile.id),
                        locale: userProfile.locale,
                        productsCounts: String(productCounts.productsQuantity),
                    },
                });
            }
            catch {
                console.error('Failed to recovery creator info');
            }
        };
        initializeCsat();
    }, []);
    return null;
};
