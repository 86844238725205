import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import audioPlayImg from 'public/assets/svg/audio_play.svg';
import mediaPlayImg from 'public/assets/svg/media_play.svg';
import styles from './styles.module.scss';
export const MediaThumb = ({ mediaCode, size, width = 100, height = 100, showPlayIcon = true, mediaType, onClick, }) => {
    const [hasNoImage, setHasNoImage] = useState(false);
    const shouldShowAudioThumbPlaceholder = mediaType === 'AUDIO';
    const src = `${process.env.NEXT_PUBLIC_API_THUMBNAIL}/${mediaCode}/dimension?w=${width}&h=${height}`;
    return (_jsx("div", { className: classNames(styles.mediaThumb, styles[size]), "data-testid": 'media-thumb', onClick: onClick, onKeyDown: onClick, children: _jsxs(_Fragment, { children: [_jsx("img", { alt: 'thumbnail', onError: () => setHasNoImage(true), src: src, hidden: hasNoImage, "data-testid": 'img-thumb' }), showPlayIcon && (_jsx("div", { children: _jsx("div", { className: classNames({
                            [styles.audioThumb]: shouldShowAudioThumbPlaceholder,
                        }), children: shouldShowAudioThumbPlaceholder ? (_jsx("div", { "data-testid": 'audio-media-thumb', children: _jsx("img", { src: audioPlayImg, alt: '', width: 16, height: 16 }) })) : (_jsx("div", { "data-testid": 'video-media-thumb', children: _jsx("img", { src: mediaPlayImg, alt: '', width: 33, height: 32 }) })) }) }))] }) }));
};
