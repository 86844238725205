import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Checkbox } from 'src/ui/Checkbox';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import ActionsContainer from '../../ActionsContainer';
import styles from './styles.module.scss';
import { SelectionCount } from './SelectionCount';
export const ListHeader = ({ areAllSelected, handleToggleSelectAll, clearSelection, selectedItems, }) => {
    const { t } = useTranslation();
    const handleClearSelection = useCallback(() => {
        clearSelection();
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_CLEAR_SELECTION);
    }, [clearSelection]);
    const selectedCount = selectedItems.length;
    const hasItemsSelected = selectedItems.length > 0;
    return (_jsxs("div", { "data-testid": 'list-header', className: styles.listHeader, children: [_jsxs("div", { className: styles.leftInfo, children: [_jsx(Checkbox, { id: 'list-header', className: styles.addMarginBottomToLabel, checked: areAllSelected, onChange: () => {
                            EventPrepAndTrackService.sendDebouncedInteractClick(areAllSelected
                                ? IntentEvent.LIBRARY_UNSELECT_MULTIPLE_ITEMS
                                : IntentEvent.LIBRARY_SELECT_MULTIPLE_ITEMS, 'checkbox');
                            handleToggleSelectAll();
                        } }), hasItemsSelected ? (_jsx(ActionsContainer, { selectedItems: selectedItems })) : (_jsx(Paragraph, { strong: true, children: t('library.header.list_info.name') }))] }), hasItemsSelected && (_jsx(SelectionCount, { count: selectedCount, onClearSelection: handleClearSelection })), _jsxs("div", { className: styles.rightInfo, children: [_jsx("div", { children: _jsx(Paragraph, { strong: true, children: t('library.header.list_info.restriction') }) }), _jsx("div", { children: _jsx(Paragraph, { strong: true, children: t('library.header.list_info.size') }) })] })] }));
};
