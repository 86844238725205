// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yX16FYvGH8Qz6UZjM2m1{--hc-modal-dialog-width: 50rem;--hc-modal-dialog-max-width: 50rem}.yX16FYvGH8Qz6UZjM2m1 .tB33swxHrKr7hDQyC38x{display:flex;align-items:center;justify-content:space-between;flex:1;--hc-button-large-padding: var(--hc-size-spacing-1) var(--hc-size-spacing-2)}.yX16FYvGH8Qz6UZjM2m1 .tB33swxHrKr7hDQyC38x>div{display:flex;align-items:center;gap:var(--hc-size-spacing-3)}.yX16FYvGH8Qz6UZjM2m1 .uKQtmkMZxUw4V68nTpLc{display:flex;height:25rem}.yX16FYvGH8Qz6UZjM2m1 .uKQtmkMZxUw4V68nTpLc iframe{border-radius:var(--hc-size-spacing-2)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/Modal/MediaPreview/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CACA,kCAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,MAAA,CACA,4EAAA,CAEA,gDACE,YAAA,CACA,kBAAA,CACA,4BAAA,CAIJ,4CACE,YAAA,CACA,YAAA,CAEA,mDACE,sCAAA","sourcesContent":[".modal {\n  --hc-modal-dialog-width: 50rem;\n  --hc-modal-dialog-max-width: 50rem;\n\n  .header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex: 1;\n    --hc-button-large-padding: var(--hc-size-spacing-1) var(--hc-size-spacing-2);\n\n    > div {\n      display: flex;\n      align-items: center;\n      gap: var(--hc-size-spacing-3);\n    }\n  }\n\n  .player {\n    display: flex;\n    height: 25rem;\n\n    iframe {\n      border-radius: var(--hc-size-spacing-2);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `yX16FYvGH8Qz6UZjM2m1`,
	"header": `tB33swxHrKr7hDQyC38x`,
	"player": `uKQtmkMZxUw4V68nTpLc`
};
export default ___CSS_LOADER_EXPORT___;
