// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tOszJ9TLGPHP3gq1Nm3K{display:flex;height:100vh;width:100%;align-items:center;justify-content:center}.tOszJ9TLGPHP3gq1Nm3K>div{display:flex;flex-direction:column;align-items:center}.tOszJ9TLGPHP3gq1Nm3K>div .O5mR73cxLl_nqEMBkmwv{width:100%;max-width:30rem;margin-bottom:1.25rem;text-align:center}.tOszJ9TLGPHP3gq1Nm3K>div .O5mR73cxLl_nqEMBkmwv .iVBl2Ybp85ARp8U9Qwl2{--hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);--hc-h-margin: var(--hc-size-spacing-5) 0 var(--hc-size-spacing-2)}.tOszJ9TLGPHP3gq1Nm3K>div .O5mR73cxLl_nqEMBkmwv .XEMOnT8NcSmbtG5Qre6K{font-size:var(--hc-size-text-medium);color:var(--hc-color-neutral-500)}`, "",{"version":3,"sources":["webpack://./src/ui/ContentFeedback/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,gDACE,UAAA,CACA,eAAA,CACA,qBAAA,CACA,iBAAA,CAEA,sEACE,0DAAA,CACA,kEAAA,CAGF,sEACE,oCAAA,CACA,iCAAA","sourcesContent":[".container {\n  display: flex;\n  height: 100vh;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n\n  > div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .text {\n      width: 100%;\n      max-width: 30rem;\n      margin-bottom: 1.25rem;\n      text-align: center;\n\n      .heading {\n        --hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);\n        --hc-h-margin: var(--hc-size-spacing-5) 0 var(--hc-size-spacing-2);\n      }\n\n      .description {\n        font-size: var(--hc-size-text-medium);\n        color: var(--hc-color-neutral-500);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tOszJ9TLGPHP3gq1Nm3K`,
	"text": `O5mR73cxLl_nqEMBkmwv`,
	"heading": `iVBl2Ybp85ARp8U9Qwl2`,
	"description": `XEMOnT8NcSmbtG5Qre6K`
};
export default ___CSS_LOADER_EXPORT___;
