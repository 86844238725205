import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import AccountStorage from 'src/infrastructure/persistence/accounts/storage';
import { updateAbility } from 'src/infrastructure/libs/CASL';
import { useNavigate } from 'react-router-dom';
import AccountService from '../../services/AccountService';
const fuseOptions = {
    threshold: 0.3,
    ignoreLocation: true,
    // List of keys that will be searched
    keys: ['accountOwnerName'],
};
const useAccounts = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: accounts, ...rest } = useQuery({
        queryKey: [QueryKeys.SUBSCRIPTION_AUTHORIZATION],
        queryFn: () => AccountService.getAuthorization(),
        staleTime: Infinity,
    });
    const [searchResult, setSearchResult] = useState();
    const fuse = new Fuse(accounts || [], fuseOptions);
    const searchAccounts = (name) => {
        const search = name
            ? fuse.search(name).map((item) => item.item)
            : undefined;
        setSearchResult(search);
    };
    const data = searchResult ? searchResult : accounts;
    const isEmptyResult = !data?.length ?? 0;
    const currentAccount = useMemo(() => data?.find((account) => account.accountOwnerMarketplaceId ===
        Number(AccountStorage.currentAccountId)), [data]);
    const updateAccountAbilities = useCallback(() => {
        updateAbility(currentAccount);
    }, [currentAccount]);
    useEffect(updateAccountAbilities, [updateAccountAbilities]);
    const selectAccount = (accountId) => {
        AccountStorage.selectAccount(accountId);
        navigate('/', { replace: true });
        //clear all cache of older accounts requests
        queryClient.clear();
    };
    return {
        ...rest,
        data,
        searchAccounts,
        isEmptyResult,
        currentAccount,
        selectAccount,
    };
};
export default useAccounts;
