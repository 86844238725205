// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g0CuWJFbpuxNJwTPVAMj{display:flex;flex:1;position:relative}.g0CuWJFbpuxNJwTPVAMj>iframe{height:100%;width:100%;visibility:visible;border:0;transition:visibility var(--hc-timing, 500ms) var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1))}.g0CuWJFbpuxNJwTPVAMj>iframe.PfqxITa3zm1YZZvtHWg1{visibility:hidden}.g0CuWJFbpuxNJwTPVAMj .yrH2bU87zVKFJRBRi49l{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./src/ui/Player/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,MAAA,CACA,iBAAA,CAEA,6BACE,WAAA,CACA,UAAA,CACA,kBAAA,CACA,QAAA,CACA,4FAAA,CAGA,kDACE,iBAAA,CAIJ,4CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".player {\n  display: flex;\n  flex: 1;\n  position: relative;\n\n  > iframe {\n    height: 100%;\n    width: 100%;\n    visibility: visible;\n    border: 0;\n    transition: visibility var(--hc-timing, 500ms)\n      var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1));\n\n    &.isLoading {\n      visibility: hidden;\n    }\n  }\n\n  .loading {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player": `g0CuWJFbpuxNJwTPVAMj`,
	"isLoading": `PfqxITa3zm1YZZvtHWg1`,
	"loading": `yrH2bU87zVKFJRBRi49l`
};
export default ___CSS_LOADER_EXPORT___;
