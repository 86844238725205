import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentError } from 'src/ui/ContentError';
import { Loading } from 'src/ui/cosmos/Loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import { CardChart } from '../../components/CardChart';
import styles from './styles.module.scss';
export const Analytics = () => {
    const { mediaCode } = useParams();
    const { t } = useTranslation();
    const { isLoading, isError, data: items } = useAnalytics(mediaCode);
    if (isLoading) {
        return _jsx(Loading, { full: true });
    }
    if (isError)
        return (_jsx("div", { "data-testid": 'content-error', children: _jsx(ContentError, { description: t('analytics.error.description'), title: t('analytics.error.title') }) }));
    return (_jsxs("div", { className: styles.container, "data-testid": 'container-analytics', children: [_jsxs(CardChart.Container, { children: [_jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.loads.title'), description: t('analytics.loads.description') }), _jsx(CardChart.ContentText, { value: String(items.LOADS), "data-testid": 'analytics-loads' })] }), _jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.views.title'), description: t('analytics.views.description') }), _jsx(CardChart.ContentText, { value: String(items.VIEWS), "data-testid": 'analytics-views' })] }), _jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.hours_watched.title'), description: t('analytics.hours_watched.description') }), _jsx(CardChart.ContentText, { value: String(items.HOURS_WATCHED), "data-testid": 'analytics-hours-watched' })] })] }), _jsxs(CardChart.Container, { children: [_jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.play_rate.title'), description: t('analytics.play_rate.description') }), _jsx(CardChart.ContentGauge, { value: String((items.PLAY_RATE * 100).toFixed(0)), "data-testid": 'analytics-play-rate' })] }), _jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.engagement.title'), description: t('analytics.engagement.description') }), _jsx(CardChart.ContentGauge, { value: String((items.ENGAGEMENT * 100).toFixed(0)), "data-testid": 'analytics-engagement' })] })] }), _jsx("div", { className: styles.retentionChart, children: _jsxs(CardChart.Item, { children: [_jsx(CardChart.Header, { title: t('analytics.retention_chart.title'), description: t('analytics.retention_chart.description') }), _jsx(CardChart.ContentRetention, { mediaCode: mediaCode })] }) })] }));
};
