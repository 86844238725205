import { isTest } from 'src/utils/env';
import CASProvider from './SSO/implementation/CAS';
import FakeSSO from './SSO/fake/FakeSSO';
class AuthService {
    constructor(sso) {
        Object.defineProperty(this, "sso", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sso
        });
        this.sso = sso;
    }
    async subscribe(onSubscribe) {
        return this.sso.subscribe(onSubscribe);
    }
    async logout(isSlo = false) {
        this.sso.logout(isSlo);
    }
    async getUser() {
        return this.sso.getUser();
    }
}
const sso = isTest() ? new FakeSSO() : new CASProvider();
export default new AuthService(sso);
