const MAKE_UPLOAD_WITH_WEB_WORKERS = true;
class FileUploader {
    constructor() {
        Object.defineProperty(this, "_fileUploaderDependency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_fileUploader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    async loadFileUpload() {
        const { default: FileUploader } = await import('@hotmart/file-uploader');
        this._fileUploaderDependency = FileUploader;
    }
    async prepareUploadWithOptionsAndCallbacks(options, { onInit, onStart, onEnqueued, onProgress, onInitFailed, onSuccess, onFail, }) {
        if (!this._fileUploaderDependency) {
            await this.loadFileUpload();
        }
        this._fileUploader = await this._fileUploaderDependency
            .setCallbackInit(onInit)
            .setCallbackStart(onStart)
            .setCallbackEnqueued(onEnqueued)
            .setCallbackProgress(onProgress)
            .setCallbackInitFailed(onInitFailed)
            .setCallbackSuccess(onSuccess)
            .setCallbackFailure(onFail)
            .build(options.config, MAKE_UPLOAD_WITH_WEB_WORKERS);
        return this._fileUploader;
    }
}
export default new FileUploader();
