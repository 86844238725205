import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import useFolderItems from 'src/domain/Folder/hooks/useFolderItems';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import contentErrorImg from 'public/assets/svg/content_error.svg';
import emptyFolderPlusImg from 'public/assets/svg/empty_folder_plus.svg';
import { List } from '../../components/List';
import { REGISTERS_PER_PAGE } from '../constants';
import styles from './styles.module.scss';
export const FolderContentList = ({ folderCode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page'));
    const isAValidPage = page && !isNaN(page);
    const currentPage = isAValidPage ? page - 1 : 0;
    const { isLoading, isError, data, isPreviousData } = useFolderItems({
        code: folderCode,
        page: currentPage,
        elementPerPage: REGISTERS_PER_PAGE,
    });
    const handleChangePage = (page) => {
        navigate(`/library/folder/${folderCode}?page=${page}`);
    };
    if (isLoading || isPreviousData)
        return (_jsx("div", { className: styles.contentFeedbackWrapper, children: _jsx(Loading, { full: true }) }));
    if (isError) {
        return (_jsx("div", { className: styles.contentFeedbackWrapper, children: _jsx(ContentFeedback, { title: t('general.load_content_error.title'), description: t('general.load_content_error.description'), svg: contentErrorImg }) }));
    }
    if (data?.items.length === 0)
        return (_jsx("div", { className: styles.contentFeedbackWrapper, children: _jsx(ContentFeedback, { title: t('folder.list.empty.title'), description: t('folder.list.empty.description'), svg: emptyFolderPlusImg }) }));
    return (_jsx("div", { children: _jsx(List.Root, { data: data.items, children: (table) => {
                return (_jsxs(_Fragment, { children: [_jsx(List.Header, { selectedItems: table
                                .getSelectedRowModel()
                                .flatRows.map((item) => item.original), areAllSelected: table.getIsAllPageRowsSelected(), handleToggleSelectAll: table.toggleAllPageRowsSelected, clearSelection: table.resetRowSelection }), _jsx(List.Body, { children: table.getRowModel().rows.map((row) => (_jsx(List.Row, { item: row.original, isSelected: row.getIsSelected(), toggleSelect: row.toggleSelected }, row.original.code))) }), _jsx(List.Pagination, { totalPages: data.totalPages, currentPage: currentPage + 1, onPageChange: handleChangePage, registersPerPage: REGISTERS_PER_PAGE, totalCountOfItems: data.totalElements })] }));
            } }) }));
};
