export var SubtitleStatus;
(function (SubtitleStatus) {
    SubtitleStatus["ACTIVE"] = "ACTIVE";
    SubtitleStatus["FAILED"] = "FAILED";
    SubtitleStatus["INACTIVE"] = "INACTIVE";
    SubtitleStatus["PROCESSING"] = "PROCESSING";
})(SubtitleStatus || (SubtitleStatus = {}));
export var SubtitleTagContext;
(function (SubtitleTagContext) {
    SubtitleTagContext["TRANSCRIPTION"] = "warning";
    SubtitleTagContext["TRANSLATION"] = "success";
    SubtitleTagContext["UPLOAD"] = "brand";
    SubtitleTagContext["MANUAL"] = "neutral";
})(SubtitleTagContext || (SubtitleTagContext = {}));
export var SubtitleType;
(function (SubtitleType) {
    SubtitleType["UPLOAD"] = "UPLOAD";
    SubtitleType["TRANSCRIPTION"] = "TRANSCRIPTION";
    SubtitleType["TRANSLATION"] = "TRANSLATION";
})(SubtitleType || (SubtitleType = {}));
