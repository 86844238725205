import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { GridCol, GridRow } from 'src/ui/cosmosWeb/Grid';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useTranslation } from 'react-i18next';
import SubtitleSelect from '../Select';
import { INITIAL_FILE_CONFIG_ALLOWED } from '../../constants';
import { SubtitleType } from '../../interfaces/subtitle';
import { UploadFileButton } from '../UploadFileButton';
import styles from './styles.module.scss';
const supportedFiles = INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.join(',');
export const Form = ({ availableSubtitles, errorDescription = '', disabled = false, subtitleType, onSubmit, }) => {
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const clearSelectedStates = () => {
        setSelectedFile('');
        setSelectedLanguage('');
    };
    const handleSelectFile = (event) => {
        onSubmit({ event, language: selectedLanguage });
        clearSelectedStates();
    };
    const handleSubmit = () => {
        onSubmit({ language: selectedLanguage });
        clearSelectedStates();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, { className: styles.paragraph, size: 'micro', strong: true, children: subtitleType === SubtitleType.TRANSCRIPTION
                    ? t('library.settings.subtitle.form.transcription_label')
                    : t('library.settings.subtitle.title') }), _jsxs(GridRow, { className: styles.formFieldsContent, children: [_jsx(GridCol, { col: '5', "col-md": '5', "col-sm": '6', children: _jsx(SubtitleSelect, { disabled: disabled, onSelected: (language) => setSelectedLanguage(language), availableSubtitles: availableSubtitles, value: selectedLanguage, invalidMessage: errorDescription }) }), _jsx(GridCol, { col: '3', "col-md": '3', "col-sm": '5', children: subtitleType === SubtitleType.UPLOAD ? (_jsx(UploadFileButton, { disabled: !selectedLanguage || disabled, onSelectFile: handleSelectFile, selectedFile: selectedFile, supportedFiles: supportedFiles, "data-testid": 'subtitle-upload-file-button' })) : (_jsx(Button, { className: styles.sendFileButton, onClick: handleSubmit, disabled: !selectedLanguage || disabled, "data-testid": `subtitle-${subtitleType?.toLowerCase()}-button`, children: t(`${subtitleType === SubtitleType.TRANSCRIPTION
                                ? 'library.settings.subtitle.buttons.transcribe'
                                : 'library.settings.subtitle.buttons.translate'}`) })) })] })] }));
};
