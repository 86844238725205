// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A_vze1zPrgY7mATPdIgr{border:.0625rem solid var(--hc-color-neutral-200);border-radius:.25rem;padding:1rem;background:var(--white);flex:1;min-width:16rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,oBAAA,CACA,YAAA,CACA,uBAAA,CACA,MAAA,CACA,eAAA","sourcesContent":[".container {\n  border: 0.0625rem solid var(--hc-color-neutral-200);\n  border-radius: 0.25rem;\n  padding: 1rem;\n  background: var(--white);\n  flex: 1;\n  min-width: 16rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `A_vze1zPrgY7mATPdIgr`
};
export default ___CSS_LOADER_EXPORT___;
