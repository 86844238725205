import * as SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
const RECONNECT_DELAY = 5000;
export default class Socket {
    constructor() {
        Object.defineProperty(this, "_Client", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    createClient() {
        const sock = new SockJS(process.env.API_SOCKET);
        const client = Stomp.over(sock);
        client.debug = () => null;
        return client;
    }
    destroyClient() {
        this._Client = null;
    }
    reconnect({ props, callbackSuccess, callbackError }) {
        if (this._Client?.connected) {
            return;
        }
        this._Client?.deactivate({
            force: true,
        });
        this.destroyClient();
        setTimeout(() => {
            this.connect({ props, callbackSuccess, callbackError });
        }, RECONNECT_DELAY);
    }
    connect({ props, callbackSuccess, callbackError }) {
        if (!this._Client) {
            this._Client = this.createClient();
        }
        const onErrorCallback = () => {
            this.reconnect({ props, callbackSuccess, callbackError });
            callbackError?.();
        };
        const onCloseCallback = (event) => {
            if (!event.wasClean) {
                onErrorCallback();
            }
        };
        this._Client?.connect(props, callbackSuccess, onErrorCallback, onCloseCallback);
    }
    disconnect(callback) {
        this._Client?.disconnect(() => {
            callback?.();
            this.destroyClient();
        });
    }
    send({ destination, headers, body }) {
        this._Client?.send(destination, headers, body);
    }
    subscribe({ destination, callback, headers }) {
        this._Client?.subscribe(destination, callback, headers);
    }
    get isConnected() {
        return !!this._Client?.connected;
    }
}
