import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfileDrawer } from 'src/ui/ProfileDrawer';
import PlanDrawer from 'src/domain/User/Plan/components/PlanDrawer';
import { UploadAction } from 'src/application/pages/Library/components/UploadAction';
// eslint-disable-next-line max-len
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import UploadDrawer from 'src/domain/Media/modules/MediaUpload/components/Drawer/UploadDrawer';
import { Can } from 'src/infrastructure/libs/CASL/can';
import { HeaderAction } from 'src/ui/cosmos/Structure';
import styles from './styles.module.scss';
const HeaderActions = () => {
    const { generalUploadState: isUploading } = useFileUploadProcess();
    return (_jsxs(_Fragment, { children: [isUploading && (_jsx(HeaderAction, { children: _jsx(UploadDrawer, {}) })), _jsx(HeaderAction, { children: _jsx(Can, { I: 'read', a: 'Plan', children: _jsx(PlanDrawer, {}) }) }), _jsx(HeaderAction, { children: _jsx(UploadAction, { dropdownButton: {
                        className: styles.button,
                    } }) }), _jsx(HeaderAction, { children: _jsx(ProfileDrawer, {}) })] }));
};
export default HeaderActions;
