// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pr_4FZSIZZB5UQYGFI2b{width:100%;min-height:2.8125rem;padding:var(--hc-size-spacing-2) var(--hc-size-spacing-2) var(--hc-size-spacing-2) var(--hc-size-spacing-4);border:1px solid var(--hc-color-neutral-300);border-radius:.25rem;background-color:#fff;color:var(--hc-color-neutral-800)}`, "",{"version":3,"sources":["webpack://./src/ui/TextArea/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,oBAAA,CACA,2GAAA,CAEA,4CAAA,CACA,oBAAA,CACA,qBAAA,CACA,iCAAA","sourcesContent":[".textarea {\n  width: 100%;\n  min-height: 2.8125rem;\n  padding: var(--hc-size-spacing-2) var(--hc-size-spacing-2)\n    var(--hc-size-spacing-2) var(--hc-size-spacing-4);\n  border: 1px solid var(--hc-color-neutral-300);\n  border-radius: 0.25rem;\n  background-color: white;\n  color: var(--hc-color-neutral-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `pr_4FZSIZZB5UQYGFI2b`
};
export default ___CSS_LOADER_EXPORT___;
