'use client';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'src/application/context/Auth/AuthContext';
import AuthService from 'src/infrastructure/services/Auth';
const useAuth = () => {
    const user = useContext(AuthContext);
    const logout = useCallback((isSlo = false) => {
        AuthService.logout(isSlo);
    }, []);
    return { user, logout };
};
export default useAuth;
