// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jJ9l_N5ADM1B4XnFCSoH{display:flex;gap:1rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/components/CardList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA","sourcesContent":[".cardsWrapper {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsWrapper": `jJ9l_N5ADM1B4XnFCSoH`
};
export default ___CSS_LOADER_EXPORT___;
