import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { GaugeChart } from 'src/ui/Charts/Gauge/components/GaugeChart';
export var Color;
(function (Color) {
    Color["green"] = "#009D43";
    Color["red"] = "#D6342C";
    Color["yellow"] = "#EFBA0F";
})(Color || (Color = {}));
const getColor = (value) => {
    if (value <= 30)
        return Color.red;
    if (value > 30 && value <= 69)
        return Color.yellow;
    return Color.green;
};
export const Gauge = ({ value }) => {
    const gaugeElementRef = useRef(null);
    useEffect(() => {
        if (!gaugeElementRef.current?.gaugeRef?.current) {
            return;
        }
        const circumference = 225;
        const offset = String(circumference * (1 - Number(value) / 100));
        gaugeElementRef.current.gaugeRef.current.style.strokeDasharray = `${circumference}, ${circumference}`;
        gaugeElementRef.current.gaugeRef.current.style.strokeDashoffset = offset;
    }, [value]);
    return (_jsx(GaugeChart, { color: getColor(value), ref: gaugeElementRef, value: value }));
};
export default Gauge;
