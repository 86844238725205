import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { faClose } from 'src/application/icons/fontAwesome/light';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const SelectedMediaList = ({ canDeselect, onDeselect, medias, }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, { size: 'small', strong: true, children: t('library.settings.restriction.selected_medias') }), _jsx("div", { "data-testid": 'selected-media-list', className: styles.container, children: medias.map((media) => (_jsxs(Tag, { class: styles.tag, "data-testid": 'media-tag', children: [media.name, canDeselect && (_jsx(IconButton, { icon: faClose, className: styles.closeButton, size: 'small', onClick: () => {
                                onDeselect(media);
                            }, "data-testid": 'deselect-button' }))] }, media.code))) })] }));
};
export default SelectedMediaList;
