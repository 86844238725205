import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ListItem } from 'src/ui/cosmosWeb/List';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { faSpinnerThird } from 'src/application/icons/fontAwesome/regular';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const ListItemLoading = ({ className }) => {
    const { t } = useTranslation();
    return (_jsxs(ListItem, { "data-testid": 'loading-component', className: classNames(styles.container, className), children: [_jsx(Paragraph, { className: styles.paragraph, size: 'micro', children: t('subtitle.loading') }), _jsx(FontAwesomeIcon, { className: styles.icon, icon: faSpinnerThird, spin: true })] }));
};
