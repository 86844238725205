import { jsx as _jsx } from "react/jsx-runtime";
import NiceModal from '@ebay/nice-modal-react';
import { MoveModal } from 'src/domain/Folder/containers/Modal/MoveModal';
import { useNavigate } from 'react-router-dom';
import { ListItem } from '../../components/ListItem';
export const ListFolderItem = ({ folder, isSelected, toggleSelect, }) => {
    const navigate = useNavigate();
    return (_jsx(ListItem, { item: folder, type: 'folder', selected: isSelected, onSelect: () => toggleSelect(), onClick: () => {
            navigate(`/library/folder/${folder.code}`);
        }, onMove: () => {
            NiceModal.show(MoveModal, {
                itemsToMove: [folder],
            });
        } }));
};
