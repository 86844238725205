// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bcGpnuJqHkTx3PfZkMEs{--hc-dropdown-item-container-padding-block: 0.75rem;--hc-dropdown-item-container-padding-inline: 1rem;--hc-dropdown-menu-min-width: 14.875rem;--hc-dropdown-menu-width: 100%;--hc-dropdown-item-border-bottom: 0;--hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);--hc-dropdown-menu-max-height: 50vh;--hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3)}.bcGpnuJqHkTx3PfZkMEs .UoE4xIhnv2mVzgSKI_du{align-items:center;display:flex;height:1.5rem}.bcGpnuJqHkTx3PfZkMEs .UoE4xIhnv2mVzgSKI_du svg{width:1rem;height:1rem;margin-top:-0.25rem}.bcGpnuJqHkTx3PfZkMEs .rMRHJIerVxbcta7uZEBk{display:flex;align-items:center}.bcGpnuJqHkTx3PfZkMEs .TpkyvpNVeh3CuBzsftoG svg{border-bottom:1px solid}`, "",{"version":3,"sources":["webpack://./src/application/Layout/MediaSettingsLayout/components/MediaBreadcrumb/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mDAAA,CACA,iDAAA,CACA,uCAAA,CACA,8BAAA,CACA,mCAAA,CACA,sEAAA,CACA,mCAAA,CACA,iEAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,aAAA,CAEA,gDACE,UAAA,CACA,WAAA,CACA,mBAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CAIA,gDACE,uBAAA","sourcesContent":[".dropdown {\n  --hc-dropdown-item-container-padding-block: 0.75rem;\n  --hc-dropdown-item-container-padding-inline: 1rem;\n  --hc-dropdown-menu-min-width: 14.875rem;\n  --hc-dropdown-menu-width: 100%;\n  --hc-dropdown-item-border-bottom: 0;\n  --hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);\n  --hc-dropdown-menu-max-height: 50vh;\n  --hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3);\n\n  .icon {\n    align-items: center;\n    display: flex;\n    height: 1.5rem;\n\n    svg {\n      width: 1rem;\n      height: 1rem;\n      margin-top: -0.25rem;\n    }\n  }\n\n  .label {\n    display: flex;\n    align-items: center;\n  }\n\n  .dropdownButton {\n    svg {\n      border-bottom: 1px solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `bcGpnuJqHkTx3PfZkMEs`,
	"icon": `UoE4xIhnv2mVzgSKI_du`,
	"label": `rMRHJIerVxbcta7uZEBk`,
	"dropdownButton": `TpkyvpNVeh3CuBzsftoG`
};
export default ___CSS_LOADER_EXPORT___;
