import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Loading } from 'src/ui/cosmos/Loading';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useMediaLatest } from 'src/domain/Media/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { CardList } from '../../components/CardList';
export const MediasPreview = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isLoading, data: medias, isSuccess } = useMediaLatest();
    const isEmpty = !isLoading && medias?.length === 0;
    return (_jsxs(CardList.Root, { children: [_jsxs(CardList.Header, { children: [_jsx(CardList.Header.Title, { children: t('home.buttons.recent_media') }), _jsx(CardList.Header.RightButton, { onClick: () => {
                            navigate('library?filter=medias');
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SHOW_ALL);
                        }, children: t(isEmpty ? 'home.buttons.go_to_library' : 'home.buttons.see_all') })] }), isLoading && _jsx(Loading, { full: true }), isEmpty && _jsx(Paragraph, { children: t('home.library_preview.empty') }), isSuccess && !isEmpty && (_jsx(CardList.CardsWrapper, { children: medias.map((media) => (_jsx(Card, { item: media, type: 'media' }, media.code))) }))] }));
};
