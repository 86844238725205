import { useCallback } from 'react';
import UploadSocketService from '../services/UploadSocketService';
export const useWebsocketMediaUploadStatus = () => {
    const subscribeConnection = useCallback(({ mediaCode, onReceiveMessage, fileType }) => {
        UploadSocketService.subscribeToReceiveStatusFromMediaCode({
            mediaCode,
            fileType,
            onReceiveMediaCodeStatus: onReceiveMessage,
        });
    }, []);
    return { subscribeConnection };
};
