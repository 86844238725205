import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from '../../services/FoldersService';
const STALE_TIME = 1000 * 5; // 5 seconds
const useFolderItems = ({ code, page, elementPerPage, }) => {
    const getFolderItems = useCallback(async () => {
        const { content, totalElements, totalPages } = await FoldersService.getFolderItems({
            code,
            elementPerPage,
            page,
        });
        return { items: content, totalElements, totalPages };
    }, [code, elementPerPage, page]);
    return useQuery({
        queryKey: [QueryKeys.FOLDER_ITEMS, code, page],
        queryFn: () => getFolderItems(),
        keepPreviousData: true,
        staleTime: STALE_TIME,
        enabled: !!code,
    });
};
export default useFolderItems;
