import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { faCircleQuestion } from 'src/application/icons/fontAwesome/light';
import styles from './styles.module.scss';
const CardChartHeader = ({ title, description }) => {
    return (_jsx(CardHeader, { children: _jsxs("div", { className: styles.container, children: [_jsx(Heading, { size: 'micro', type: 'h2', children: title }), _jsx(Tooltip, { position: 'bottom', text: description, children: _jsx(FontAwesomeIcon, { icon: faCircleQuestion }) })] }) }));
};
export default CardChartHeader;
