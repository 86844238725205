// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fMuYYjmbAyqvU_R5CzAr{background-color:var(--hc-color-info-100);border-radius:.25rem;color:var(--hc-color-info-500)}.fMuYYjmbAyqvU_R5CzAr .S513k3KUGofIiDPVY9OL,.fMuYYjmbAyqvU_R5CzAr span,.fMuYYjmbAyqvU_R5CzAr svg{color:var(--hc-color-info-500)}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Structure/MenuItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CACA,oBAAA,CACA,8BAAA,CAEA,iGAGE,8BAAA","sourcesContent":[".active {\n  background-color: var(--hc-color-info-100);\n  border-radius: 0.25rem;\n  color: var(--hc-color-info-500);\n\n  .icon,\n  span,\n  svg {\n    color: var(--hc-color-info-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `fMuYYjmbAyqvU_R5CzAr`,
	"icon": `S513k3KUGofIiDPVY9OL`
};
export default ___CSS_LOADER_EXPORT___;
