export const convertDateISOToDateStringFormat = (date, lang, options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}) => {
    try {
        return new Intl.DateTimeFormat(lang, options).format(new Date(date));
    }
    catch (error) {
        return '-';
    }
};
