import { FileStatus, GeneralStatus, } from '../interfaces/FileUploadProps';
const STATUS = {
    [GeneralStatus.COMPLETED]: [
        FileStatus.ERROR,
        FileStatus.FATAL_ERROR,
        FileStatus.SUCCESS,
    ],
    [GeneralStatus.ERROR]: [FileStatus.ERROR, FileStatus.FATAL_ERROR],
    [GeneralStatus.OPTIMIZING]: [FileStatus.OPTIMIZING],
    [GeneralStatus.UPLOADING]: [FileStatus.QUEUE, FileStatus.PROGRESS],
};
export const isCompletedStatus = (status = FileStatus.QUEUE) => STATUS[GeneralStatus.COMPLETED].includes(status);
export const isUploading = (status = FileStatus.QUEUE) => STATUS[GeneralStatus.UPLOADING].includes(status);
export const isOptimizing = (status = FileStatus.QUEUE) => STATUS[GeneralStatus.OPTIMIZING].includes(status);
export const isFailed = (status = FileStatus.QUEUE) => STATUS[GeneralStatus.ERROR].includes(status);
export const getGeneralStatusFromFileItems = (files) => {
    const filesValues = Object.values(files);
    if (filesValues.length === 0)
        return;
    if (filesValues.some((file) => isUploading(file.status)))
        return GeneralStatus.UPLOADING;
    if (filesValues.some((file) => isOptimizing(file.status)))
        return GeneralStatus.OPTIMIZING;
    if (filesValues.every((file) => isFailed(file.status)))
        return GeneralStatus.ERROR;
    if (filesValues.some((file) => isCompletedStatus(file.status)))
        return GeneralStatus.COMPLETED;
};
