import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import notFoundImg from 'public/assets/svg/not_found.svg';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export default function NotFound() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.container, children: [_jsx("img", { src: notFoundImg, width: 150, height: 150, alt: '' }), _jsxs("div", { className: styles.mainText, children: [_jsx("h1", { children: t('general.error.generic.title') }), _jsx("h2", { children: t('general.error.generic.description') })] }), _jsxs("div", { className: styles.redirectLinks, children: [_jsx(Button, { className: styles.button, onClick: () => navigate('/'), children: t('general.error.generic.button') }), _jsx(Link, { className: styles.link, to: `https://help.hotmart.com/en`, children: _jsx("p", { children: t('general.error.generic.help_center') }) })] })] }));
}
