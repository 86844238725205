// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L0f2kHLzcvInpWcuSPK5{display:flex;width:100%;align-items:center;justify-content:center;height:100vh}`, "",{"version":3,"sources":["webpack://./src/domain/User/Accounts/containers/ManagerAccount/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `L0f2kHLzcvInpWcuSPK5`
};
export default ___CSS_LOADER_EXPORT___;
