import AuthService from 'src/infrastructure/services/Auth';
import Socket from 'src/infrastructure/services/Socket';
import { MediaProcessStatus, } from '../../interfaces/MediaProcessProps';
class UploadSocketService {
    constructor() {
        Object.defineProperty(this, "_subscribes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "_socket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._socket = new Socket();
    }
    removeSubscribeItemByMediaCode(mediaCode) {
        Reflect.deleteProperty(this._subscribes, mediaCode);
    }
    disconnectWhenSubscribeIsEmpty() {
        if (Object.keys(this._subscribes).length > 0) {
            return;
        }
        this._socket.disconnect();
    }
    async sendAndSubscribeMediaCode() {
        if (Object.keys(this._subscribes).length === 0) {
            return;
        }
        const { access_token } = await AuthService.getUser();
        const headers = { Authorization: access_token };
        Object.keys(this._subscribes).forEach((row) => {
            const { mediaCode, onReceiveMediaCodeStatus } = this._subscribes[row];
            try {
                this._socket.send({
                    destination: '/ui/public/ws',
                    headers,
                    body: mediaCode,
                });
                this._socket.subscribe({
                    destination: `/media/status/${mediaCode}`,
                    callback: onReceiveMediaCodeStatus,
                });
            }
            catch (error) {
                console.warn(`Check if mediaCode: ${mediaCode}, exists in network > ws, if yes, you can ignore this warning, 
          maybe the media did a duplicate signature. Details: `, error);
            }
        });
    }
    async subscribeToReceiveStatusFromMediaCode({ mediaCode, fileType, onReceiveMediaCodeStatus, }) {
        const maxSuccessStatRequired = fileType === 'VIDEO' ? 2 : 1;
        this._subscribes[mediaCode] = {
            mediaCode,
            statusSuccess: [],
            onReceiveMediaCodeStatus: (message) => {
                const media = JSON.parse(message.body.toString());
                if (media.mediaStatus === MediaProcessStatus.READY ||
                    media.mediaStatus === MediaProcessStatus.THUMBNAIL_READY) {
                    this._subscribes[mediaCode].statusSuccess?.push(media.mediaStatus);
                }
                if (this._subscribes[mediaCode].statusSuccess?.length ===
                    maxSuccessStatRequired ||
                    media.mediaStatus === MediaProcessStatus.FAILED) {
                    onReceiveMediaCodeStatus(media);
                    this.removeSubscribeItemByMediaCode(mediaCode);
                }
                this.disconnectWhenSubscribeIsEmpty();
            },
        };
        const { access_token } = await AuthService.getUser();
        const headers = { Authorization: access_token };
        this._socket.connect({
            props: headers,
            callbackSuccess: () => {
                this.sendAndSubscribeMediaCode();
            },
        });
        this.sendAndSubscribeMediaCode();
    }
}
export default new UploadSocketService();
