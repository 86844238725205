export function isDevelopment() {
    return process.env.APP_ENV === 'development';
}
export function isStaging() {
    return process.env.APP_ENV === 'staging';
}
export function isProduction() {
    return process.env.APP_ENV === 'production';
}
export function isTest() {
    return process.env.APP_ENV === 'test';
}
export function isServer() {
    return typeof window === 'undefined';
}
