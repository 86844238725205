import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider, } from 'src/application/hooks/useRequest';
const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
function ReactQueryProvider({ children }) {
    return (_jsxs(QueryClientProvider, { client: queryClient, children: [children, _jsx(ReactQueryDevtools, { initialIsOpen: false })] }));
}
export default ReactQueryProvider;
