import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ListItem as CosmosListItem } from 'src/ui/cosmosWeb/List';
import { Switch } from 'src/ui/cosmos/Switch';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { faArrowDownToBracket, faTrashCan, } from 'src/application/icons/fontAwesome/light';
import { faEllipsisVertical } from 'src/application/icons/fontAwesome/regular';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Dropdown, DropdownItem } from 'src/ui/cosmosWeb/DropDown';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { useTranslation } from 'react-i18next';
import { SubtitleStatus, SubtitleTagContext } from '../../interfaces/subtitle';
import styles from './styles.module.scss';
export const ListItem = ({ language, onDelete, onDownload, onToggle, mediaCode, }) => {
    const { t } = useTranslation();
    const isFailed = language.status === SubtitleStatus.FAILED;
    return (_jsxs(CosmosListItem, { "data-testid": 'subtitle-list-item', className: classNames(styles.listItem, {
            [styles.failed]: isFailed,
        }), children: [_jsxs("div", { className: styles.content, children: [_jsx(Switch, { id: language.id, "data-testid": 'input-checkbox-status', label: t(`subtitle.supported_languages.${language.languageCode}`), labelClassName: classNames(styles.switchLabel, {
                            [styles.failed]: isFailed,
                        }), containerClassName: styles.switchContainer, onChange: () => onToggle({
                            mediaCode,
                            subtitleCode: language.id,
                            subtitleStatus: language.status,
                        }), disabled: isFailed, checked: language.status === SubtitleStatus.ACTIVE }), isFailed ? (_jsx(Paragraph, { className: styles.paragraph, size: 'micro', children: t('library.settings.subtitle.errors.failed') })) : (_jsx(Tag, { className: styles.tag, "data-testid": `subtitle-type-${language.type.toLowerCase()}`, context: SubtitleTagContext[language.type], children: t(`subtitle.type.${language.type.toLowerCase()}`) }))] }), isFailed ? (_jsx(IconButton, { icon: faTrashCan, "data-testid": 'subtitle-failed-delete-button', className: styles.deleteButton, context: 'neutral', variation: 'tertiary', onClick: () => {
                    onDelete({
                        mediaCode,
                        subtitleCode: language.id,
                    });
                } })) : (_jsxs(Dropdown, { "data-testid": 'subtitle-list-dropdown', className: styles.dropdown, slot: 'actions', position: 'end', docked: true, children: [_jsx(Button, { "data-testid": `button-options-${language.languageCode}`, slot: 'controls', variation: 'tertiary', context: 'neutral', size: 'small', children: _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faEllipsisVertical }) }) }), _jsxs(DropdownItem, { slot: 'items', onClick: () => {
                            onDownload({
                                mediaCode,
                                subtitleCode: language.id,
                            });
                        }, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faArrowDownToBracket }) }), _jsx("div", { slot: 'label', children: t('general.download') })] }), _jsxs(DropdownItem, { slot: 'items', onClick: () => {
                            onDelete({
                                mediaCode,
                                subtitleCode: language.id,
                            });
                        }, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faTrashCan }) }), _jsx("div", { slot: 'label', children: t('general.exclude') })] })] }))] }));
};
