// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maUC5huH6YuGZE7cSW2K{padding-top:var(--structure-header-height);min-height:100dvh;margin-left:auto;margin-right:auto;background-color:var(--hc-color-neutral-100)}@media(min-width: 1200px){.maUC5huH6YuGZE7cSW2K{max-width:87.5rem}}`, "",{"version":3,"sources":["webpack://./src/application/Layout/Simple/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,4CAAA,CAEA,0BAPF,sBAQI,iBAAA,CAAA","sourcesContent":[".container {\n  padding-top: var(--structure-header-height);\n  min-height: 100dvh;\n  margin-left: auto;\n  margin-right: auto;\n  background-color: var(--hc-color-neutral-100);\n\n  @media (min-width: 1200px) {\n    max-width: 87.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `maUC5huH6YuGZE7cSW2K`
};
export default ___CSS_LOADER_EXPORT___;
