import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import PlanService from '../../services/PlanService';
const staleTime = 60 * 1000 * 5; //5 min
const usePlan = () => {
    return useQuery({
        queryKey: [QueryKeys.SUBSCRIPTION_PLAN],
        queryFn: PlanService.getSubscriptionPlan,
        staleTime,
    });
};
export default usePlan;
