import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from 'src/application/pages/Library/components/List';
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import { useSearchItems } from 'src/application/hooks/useSearchItems';
import Breadcrumb from 'src/ui/cosmos/Breadcrumb';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchWithNoResultImg from 'public/assets/svg/search_with_no_result.svg';
import { Header } from '../Library/components/Header';
import styles from './styles.module.scss';
const Search = () => {
    const [searchParams] = useSearchParams();
    const value = searchParams.get('value');
    const { t } = useTranslation();
    const { data, isLoading, isPreviousData } = useSearchItems(value);
    const isEmptyResult = !data?.content || data?.content.length === 0;
    const shouldShowLoading = isLoading || isPreviousData;
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb.Root, { children: _jsx(Breadcrumb.Item, { active: true, children: t('library.title') }) }), _jsxs("div", { className: styles.content, children: [_jsx(Header, {}), shouldShowLoading && (_jsx("div", { className: styles.loadingAndEmptyResultWrapper, children: _jsx(Loading, {}) })), isEmptyResult && !shouldShowLoading && (_jsx("div", { className: styles.loadingAndEmptyResultWrapper, children: _jsx(ContentFeedback, { title: t('search.empty_result.title'), description: t('search.empty_result.description'), svg: searchWithNoResultImg }) })), !isEmptyResult && (_jsx(List.Root, { data: data.content, children: (table) => {
                            return (_jsxs(_Fragment, { children: [_jsx(List.Header, { selectedItems: table
                                            .getSelectedRowModel()
                                            .flatRows.map((item) => item.original), areAllSelected: table.getIsAllPageRowsSelected(), handleToggleSelectAll: table.toggleAllPageRowsSelected, clearSelection: table.resetRowSelection }), _jsx(List.Body, { children: table.getRowModel().rows.map((row) => (_jsx(List.Row, { item: row.original, isSelected: row.getIsSelected(), toggleSelect: row.toggleSelected }, row.original.code))) })] }));
                        } }))] })] }));
};
export default Search;
