import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Checkbox } from 'src/ui/Checkbox';
import styles from './styles.module.scss';
const BasicConfirmationModal = NiceModal.create(({ title, description, confirmCheckboxDescription, buttons, loading, onDismiss, }) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const modal = useModal();
    const isConfirmButtonDisabled = Boolean(confirmCheckboxDescription) && !isCheckboxChecked;
    return (_jsxs(Modal, { className: styles.modal, onCloseModal: () => {
            onDismiss?.();
            modal.remove();
        }, opened: modal.visible, role: 'dialog', children: [_jsx(Heading, { slot: 'heading', size: 'small', children: title }), _jsxs("div", { slot: 'description', className: styles.description, children: [_jsx(Paragraph, { size: 'small', className: styles.paragraph, children: description }), confirmCheckboxDescription && (_jsx(Paragraph, { size: 'micro', children: _jsx(Checkbox, { id: 'confirmation_folder_delete_checkbox', onChange: (e) => setIsCheckboxChecked(e.target.checked), label: confirmCheckboxDescription }) }))] }), _jsx(Button, { className: styles.button, slot: 'controls', context: buttons.no.context, variation: buttons.no.color, role: 'button', onClick: () => {
                    modal.resolve(false);
                    modal.hide();
                }, children: buttons.no.title }), _jsx(Button, { "data-testid": 'basic-confirmation-modal-confirm-button', disabled: isConfirmButtonDisabled, className: styles.button, slot: 'controls', variation: buttons.yes.color, context: buttons.yes.context, loading: loading, role: 'button', onClick: () => {
                    modal.resolve(true);
                    modal.hide();
                }, children: buttons.yes.title })] }));
});
export default BasicConfirmationModal;
