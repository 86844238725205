import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading } from 'src/ui/cosmos/Loading';
import AccountStorage from 'src/infrastructure/persistence/accounts/storage';
import { Navigate } from 'react-router-dom';
import useAccounts from '../../hooks/useAccounts';
import styles from './styles.module.scss';
const ManagerAccount = ({ children }) => {
    const { isLoading, data: accounts, selectAccount } = useAccounts();
    if (isLoading)
        return (_jsx("div", { className: styles.container, children: _jsx(Loading, { full: true }) }));
    if (!AccountStorage.currentAccountId) {
        if (accounts?.length === 1) {
            selectAccount(accounts[0].accountOwnerMarketplaceId);
            return _jsx(Navigate, { to: '/', replace: true });
        }
        else {
            return _jsx(Navigate, { to: '/accounts', replace: true });
        }
    }
    return _jsx(_Fragment, { children: children });
};
export default ManagerAccount;
