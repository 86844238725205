// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KC6T8ta99klp46cx7XLt{margin:-0.5rem 0 0 0}.KC6T8ta99klp46cx7XLt .iZC0_s62pVwXAj_i1WbN{border-radius:var(--hc-size-border-large);margin:.5rem 0;height:4.375rem}.KC6T8ta99klp46cx7XLt .iZC0_s62pVwXAj_i1WbN .kmk1BXSio0S0IEDQQ5k4{height:4.375rem;border-radius:var(--hc-size-border-large);width:100%}.KC6T8ta99klp46cx7XLt .iZC0_s62pVwXAj_i1WbN:last-child{margin-bottom:0 !important}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/MediaUpload/components/FileUpload/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAEA,4CACE,yCAAA,CACA,cAAA,CACA,eAAA,CAEA,kEACE,eAAA,CACA,yCAAA,CACA,UAAA,CAGF,uDACE,0BAAA","sourcesContent":[".container {\n  margin: -0.5rem 0 0 0;\n\n  .info {\n    border-radius: var(--hc-size-border-large);\n    margin: .5rem 0;\n    height: 4.375rem;\n\n    .box {\n      height: 4.375rem;\n      border-radius: var(--hc-size-border-large);\n      width: 100%;\n    }\n\n    &:last-child {\n      margin-bottom: 0 !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KC6T8ta99klp46cx7XLt`,
	"info": `iZC0_s62pVwXAj_i1WbN`,
	"box": `kmk1BXSio0S0IEDQQ5k4`
};
export default ___CSS_LOADER_EXPORT___;
