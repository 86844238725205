import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useQuery } from 'src/application/hooks/useRequest';
import DomainService from '../../services/DomainService';
export const useListDomains = ({ medias }) => {
    const isMoreThanOneMedia = medias.length > 1;
    return useQuery({
        queryKey: isMoreThanOneMedia
            ? [QueryKeys.DOMAINS]
            : [QueryKeys.DOMAINS, medias[0].code],
        queryFn: () => {
            return isMoreThanOneMedia
                ? []
                : DomainService.getMediaDomains({
                    code: medias[0].code,
                });
        },
    });
};
