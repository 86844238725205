import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormField } from 'src/ui/cosmosWeb/FormField';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faSearch } from 'src/application/icons/fontAwesome/light';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { MINIMUM_CHARACTERS_REQUIRED } from './constants';
export const LibrarySearch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchParm = searchParams.get('value');
    const [searchedValue, setSearchedValue] = useState(searchParm || '');
    const handleSearchClick = () => {
        if (searchedValue.length < MINIMUM_CHARACTERS_REQUIRED)
            return;
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_SEARCH);
        navigate(`/search?value=${searchedValue}`);
    };
    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    };
    return (_jsx("div", { className: styles.searchContainer, children: _jsxs(FormField, { iconPosition: 'end', className: styles.formField, children: [_jsx("input", { placeholder: t('library.header.search'), type: 'search', "data-testid": 'input-search', onChange: (e) => setSearchedValue(e.target.value), value: searchedValue, onKeyDown: handleOnKeyDown }), _jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', size: 'medium', className: styles.searchButton, onClick: handleSearchClick, "data-testid": 'button-search', children: _jsx(FontAwesomeIcon, { icon: faSearch }) })] }) }));
};
