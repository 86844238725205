import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import validateSizeAndTypeByConfig from 'src/infrastructure/services/Upload/validation/validateSizeAndTypeByConfig';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import { Loading } from 'src/ui/cosmos/Loading';
import { useMediaUpdate } from 'src/domain/Media/hooks/useMediaUpdate';
import { Toast } from 'src/ui/cosmos/Toast';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SubtitleList } from '../../components/List';
import { Form } from '../../components/Form';
import { useSubtitle } from '../../hooks/useSubtitle';
import SubtitleService from '../../services/SubtitleService';
import { INITIAL_FILE_CONFIG_ALLOWED } from '../../constants';
import { SubtitleStatus, SubtitleType } from '../../interfaces/subtitle';
import { SubtitleTypeSelector } from '../../components/SubtitleTypeSelector';
import { chooseTheSubtitleToBeTranslated } from '../../utils';
import { ListItemLoading } from '../../components/ListItemLoading';
import styles from './styles.module.scss';
export const Subtitle = () => {
    const { mediaCode } = useParams();
    const deleteConfirmationModal = useModal('subtitle-deletion-confirmation-modal');
    const { t } = useTranslation();
    const [subtitleFileUploadError, setSubtitleFileUploadError] = useState('');
    const [subtitleType, setSubtitleType] = useState();
    const { mutationAddSubtitle, mutationToggleSubtitleStatus, subtitlesAvailableFromMedia, subtitlesListFromMedia, deleteSubtitle, translateSubtitle, transcribeSubtitle, isUploading, } = useSubtitle({ mediaCode });
    const { updateMedia } = useMediaUpdate();
    useEffect(() => {
        if (mutationAddSubtitle.isError) {
            setSubtitleFileUploadError(String(mutationAddSubtitle.error));
        }
    }, [mutationAddSubtitle]);
    const onAddNewSubtitle = ({ file, selectedLanguage }) => {
        mutationAddSubtitle.mutate({ file, selectedLanguage });
    };
    const handleSelectSubtitleFile = (e, language) => {
        setSubtitleFileUploadError('');
        if (!e.target.files) {
            return;
        }
        const [file] = Array.from(e.target.files);
        const isValidFile = validateSizeAndTypeByConfig.isValid(file, INITIAL_FILE_CONFIG_ALLOWED).valid;
        if (!isValidFile) {
            setSubtitleFileUploadError(t('library.settings.subtitle.errors.invalid_file'));
            return;
        }
        onAddNewSubtitle({ file, selectedLanguage: language });
    };
    const handleTranslateSubtitle = async (language) => {
        const chosenSubtitle = chooseTheSubtitleToBeTranslated(subtitlesListFromMedia.data);
        await translateSubtitle.mutateAsync({
            language,
            subtitleCode: chosenSubtitle?.id,
            mediaCode,
        });
    };
    const handleTranscribeSubtitle = async (videoLanguage) => {
        try {
            await updateMedia.mutateAsync({
                code: mediaCode,
                language: videoLanguage,
            });
            await transcribeSubtitle.mutateAsync({ mediaCode });
        }
        catch (error) {
            Toast.error({
                message: t('library.settings.subtitle.errors.send_subtitle'),
            });
        }
    };
    const handleSubmit = ({ event, language }) => {
        if (subtitleType === SubtitleType.TRANSLATION) {
            handleTranslateSubtitle(language);
        }
        else if (subtitleType === SubtitleType.TRANSCRIPTION) {
            handleTranscribeSubtitle(language);
        }
        else {
            handleSelectSubtitleFile(event, language);
        }
        setSubtitleType(undefined);
    };
    const handleOnDelete = async (subtitleProps) => {
        const confirmed = await deleteConfirmationModal.show();
        if (confirmed) {
            await deleteSubtitle.mutateAsync(subtitleProps);
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SUBTITLE_DELETE);
            deleteConfirmationModal.hide();
        }
    };
    const handleOnDownload = (subtitleProps) => {
        SubtitleService.download(subtitleProps).then((res) => window.open(res));
    };
    const handleOnToggleEnable = (subtitleProps) => {
        mutationToggleSubtitleStatus.mutate(subtitleProps);
        EventPrepAndTrackService.sendDebouncedInteractClick(subtitleProps.subtitleStatus === SubtitleStatus.ACTIVE
            ? IntentEvent.SETTINGS_MEDIA_SUBTITLE_INACTIVE
            : IntentEvent.SETTINGS_MEDIA_SUBTITLE_ACTIVE);
    };
    const handleChooseSubtitleType = (type) => setSubtitleType(subtitleType === type ? undefined : type);
    const thereAreSubtitles = (subtitlesListFromMedia.data && subtitlesListFromMedia.data.length > 0) ??
        false;
    const showForm = subtitleType;
    if (subtitlesListFromMedia.isLoading) {
        return _jsx(Loading, { full: true });
    }
    return (_jsxs(_Fragment, { children: [_jsx(BasicConfirmationModal, { id: 'subtitle-deletion-confirmation-modal', loading: deleteSubtitle.isLoading, title: t('modal.subtitle.delete.one.title'), description: _jsx(Trans, { i18nKey: 'modal.subtitle.delete.one.description', components: { strong: _jsx("strong", {}) } }), confirmCheckboxDescription: t('modal.subtitle.delete.checkbox_label'), buttons: {
                    no: {
                        color: 'tertiary',
                        context: 'neutral',
                        title: t('modal.subtitle.delete.buttons.no'),
                    },
                    yes: {
                        color: 'primary',
                        context: 'danger',
                        title: t('modal.subtitle.delete.buttons.yes'),
                    },
                } }), _jsxs("div", { className: styles.container, children: [_jsxs("header", { children: [_jsx("div", { "data-testid": 'container-paragraph-description', children: _jsx(Paragraph, { "data-testid": 'paragraph-description', className: styles.paragraph, size: 'micro', children: t('library.settings.subtitle.instruction') }) }), _jsx(SubtitleTypeSelector, { subtitleType: subtitleType, onChooseSubtitleType: handleChooseSubtitleType, thereAreSubtitle: thereAreSubtitles }), showForm && (_jsx(Form, { availableSubtitles: subtitlesAvailableFromMedia || [], errorDescription: subtitleFileUploadError, onSubmit: handleSubmit, disabled: mutationAddSubtitle.isLoading, subtitleType: subtitleType }))] }), _jsxs("div", { className: styles.languagesListContainer, children: [thereAreSubtitles && (_jsx(SubtitleList, { mediaCode: mediaCode, languageList: subtitlesListFromMedia.data || [], onChangeEnable: handleOnToggleEnable, onDelete: handleOnDelete, onDownload: handleOnDownload })), isUploading && (_jsx(ListItemLoading, { className: styles.listItemLoading }))] })] })] }));
};
