import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import DomainService from '../../services/DomainService';
export const useAddDomain = ({ medias }) => {
    const queryClient = useQueryClient();
    const isMoreThanOneMedia = medias.length > 1;
    return useMutation({
        mutationFn: ({ extractedDomain }) => DomainService.addDomainsToMedias({
            mediaCodes: medias.map((media) => media.code),
            domains: [extractedDomain],
        }),
        onSuccess: (data) => {
            if (!isMoreThanOneMedia) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.DOMAINS, medias[0].code],
                });
                return;
            }
            queryClient.setQueryData([QueryKeys.DOMAINS], (currentData) => [
                ...(currentData || []),
                ...data,
            ]);
        },
        onError: (error) => {
            console.error(error);
        },
    });
};
