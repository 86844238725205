import { useEffect } from 'react';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import { onAllChangesSaved, onPageHasUnsavedChanges, } from 'src/utils/pageLifeCycleUtils';
export const useConfirmClosePageWhenFileUploading = ({ generalUploadState, }) => {
    useEffect(() => {
        if (generalUploadState === GeneralStatus.UPLOADING) {
            onPageHasUnsavedChanges();
            return;
        }
        onAllChangesSaved();
        return () => {
            onAllChangesSaved();
        };
    }, [generalUploadState]);
};
