// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PArO3Ify_ZNT86fgxfSR{padding:0 0 2rem;word-break:break-word}.PArO3Ify_ZNT86fgxfSR .LPiXOi642Av0aKvTpunX{padding:1.4375rem 0}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Breadcrumb/Root/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,qBAAA,CAEA,4CACE,mBAAA","sourcesContent":[".container {\n  padding: 0 0 2rem;\n  word-break: break-word;\n  \n  .breadcrumb {\n    padding: 1.4375rem 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PArO3Ify_ZNT86fgxfSR`,
	"breadcrumb": `LPiXOi642Av0aKvTpunX`
};
export default ___CSS_LOADER_EXPORT___;
