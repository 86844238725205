import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
const useMediaSettingsLayout = ({ showSaveButton = false, enableSaveButton = false, loadingSaveButton = false, onSave = null, }) => {
    const { setShowSaveButton, setEnableSaveButton, setLoadingSaveButton, onSaveFunction, media, } = useOutletContext();
    useEffect(() => {
        const updateLayout = () => {
            setShowSaveButton(showSaveButton);
            setEnableSaveButton(enableSaveButton);
            setLoadingSaveButton(loadingSaveButton);
            onSaveFunction.current = onSave;
        };
        updateLayout();
    }, [
        enableSaveButton,
        loadingSaveButton,
        onSave,
        onSaveFunction,
        setEnableSaveButton,
        setLoadingSaveButton,
        setShowSaveButton,
        showSaveButton,
    ]);
    return { media };
};
export default useMediaSettingsLayout;
