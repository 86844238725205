import { jsx as _jsx } from "react/jsx-runtime";
import NiceModal from '@ebay/nice-modal-react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { MoveModal } from 'src/domain/Folder/containers/Modal/MoveModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListItem } from '../../components/ListItem';
export const ListMediaItem = ({ media, isSelected, toggleSelect, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (_jsx(ListItem, { item: media, type: 'media', selected: isSelected, onSelect: () => toggleSelect(), onClick: () => {
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_SETTINGS_MEDIA);
            navigate(`/library/media/${media.code}`, {
                state: {
                    referrer: `${location.pathname}${location.search}`,
                },
            });
        }, onMove: () => {
            NiceModal.show(MoveModal, {
                itemsToMove: [media],
            });
        } }));
};
