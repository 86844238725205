import * as FolderEndpoint from 'src/infrastructure/endpoints/folder';
import { convertObjectKeysToCamelCase } from 'src/utils/parse';
class FoldersService {
    async createFolder({ name, parentFolderCode }) {
        return FolderEndpoint.createFolder({
            name,
            parentFolderCode,
        });
    }
    async updateFolder({ name, code }) {
        return FolderEndpoint.updateFolders({ name, code });
    }
    async deleteItems({ itemCodes: folderCodes }) {
        await FolderEndpoint.deleteItems({ itemCodes: folderCodes });
    }
    async getFolderItems({ code, elementPerPage, page, category, }) {
        const { content, folder, ...rest } = await FolderEndpoint.getFolderItems({
            code,
            category,
            elementPerPage,
            page,
        });
        const parsedContent = content.map(({ data, ...rest }) => {
            return convertObjectKeysToCamelCase({
                ...data,
                ...rest,
                belongsToFolder: folder,
            });
        });
        return {
            content: parsedContent,
            folder,
            ...rest,
        };
    }
    async getMainFolderItems({ elementPerPage, page, category, }) {
        const { content, folder, ...rest } = await FolderEndpoint.getMainFolderItems({
            elementPerPage,
            page,
            category,
        });
        const parsedContent = content.map(({ data, ...rest }) => {
            return convertObjectKeysToCamelCase({
                ...data,
                ...rest,
                belongsToFolder: folder,
            });
        });
        return {
            content: parsedContent,
            folder,
            ...rest,
        };
    }
    async moveItemsToFolder({ sourceCodes, targetFolderCode, }) {
        return FolderEndpoint.moveItemsToFolder({
            sourceCodes,
            targetFolderCode,
        });
    }
    async getFolderParents({ code }) {
        const { folder, parents } = await FolderEndpoint.getFolderParents({ code });
        if (parents.length === 0)
            return [{ ...folder, defaultFolder: true }];
        const default_folder_index = parents.length - 1;
        parents[default_folder_index].defaultFolder = true;
        return [folder, ...parents].reverse();
    }
    async searchItems(query) {
        const { content, ...rest } = await FolderEndpoint.searchItems(query);
        const parsedContent = content.map(({ data, ...rest }) => convertObjectKeysToCamelCase({
            ...data,
            ...rest,
        }));
        return {
            content: parsedContent,
            ...rest,
        };
    }
    async getFolderByCode(code) {
        return convertObjectKeysToCamelCase(await FolderEndpoint.getFolderByCode(code));
    }
    async getFolderCount() {
        return FolderEndpoint.getFolderCount();
    }
    async getLatestFolders() {
        return FolderEndpoint.getLatestFolders();
    }
}
export default new FoldersService();
