import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import MediaService from '../../services/MediaService';
export const useMediaCount = () => {
    const getMediaCount = useCallback(async () => {
        const data = await MediaService.getMediaCount();
        return data;
    }, []);
    return useQuery({
        queryKey: [QueryKeys.MEDIA_COUNT],
        queryFn: () => getMediaCount(),
    });
};
export const useMediaDurationCount = () => {
    const getMediaDurationCount = useCallback(async () => {
        const data = await MediaService.getMediaDurationCount();
        return data;
    }, []);
    return useQuery({
        queryKey: [QueryKeys.MEDIA_DURATION_COUNT],
        queryFn: () => getMediaDurationCount(),
    });
};
