// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pLdldxj9PXlfKiQMOLEp{display:flex;width:100%;align-items:center;justify-content:center;height:100%}.pLdldxj9PXlfKiQMOLEp>div{display:flex;flex-direction:column;align-items:center}.pLdldxj9PXlfKiQMOLEp>div .t33dO9fMthVctgq055aQ{width:100%;max-width:25rem;text-align:center}.pLdldxj9PXlfKiQMOLEp>div .t33dO9fMthVctgq055aQ .emZIZFIYfoZgYFCJ00Ru{--hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);--hc-h-margin: var(--hc-size-spacing-5) 0 var(--hc-size-spacing-2)}.pLdldxj9PXlfKiQMOLEp>div .t33dO9fMthVctgq055aQ .FEPTvIllziqQF7wxjrfb{font-size:var(--hc-size-text-medium);color:var(--hc-color-neutral-500)}`, "",{"version":3,"sources":["webpack://./src/ui/ContentError/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,gDACE,UAAA,CACA,eAAA,CAEA,iBAAA,CAEA,sEACE,0DAAA,CACA,kEAAA,CAGF,sEACE,oCAAA,CACA,iCAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n\n  > div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .text {\n      width: 100%;\n      max-width: 25rem;\n\n      text-align: center;\n\n      .heading {\n        --hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);\n        --hc-h-margin: var(--hc-size-spacing-5) 0 var(--hc-size-spacing-2);\n      }\n\n      .description {\n        font-size: var(--hc-size-text-medium);\n        color: var(--hc-color-neutral-500);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pLdldxj9PXlfKiQMOLEp`,
	"text": `t33dO9fMthVctgq055aQ`,
	"heading": `emZIZFIYfoZgYFCJ00Ru`,
	"description": `FEPTvIllziqQF7wxjrfb`
};
export default ___CSS_LOADER_EXPORT___;
