import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Loading } from 'src/ui/cosmos/Loading';
import useAuth from 'src/application/hooks/useAuth';
import styles from './styles.module.scss';
const Logout = () => {
    const { logout } = useAuth();
    useEffect(() => {
        logout(true);
    }, [logout]);
    return (_jsx("div", { className: styles.container, children: _jsx(Loading, { full: true }) }));
};
export default Logout;
