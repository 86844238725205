import { useModal } from '@ebay/nice-modal-react';
import { isAmountFilesLessOrEqualMaxFileAmount, sliceFilesToMaxAmount, } from 'src/domain/Media/modules/MediaUpload/utils/fileUploadUtils';
import BasicAlertModal from 'src/ui/Modal/components/BasicAlertModal';
import { useTranslation } from 'react-i18next';
import { MAX_AMOUNT_OF_FILE_TO_UPLOAD_AT_A_TIME } from '../constants';
export const useFilesAmountToUpload = () => {
    const { t } = useTranslation();
    const alertModal = useModal(BasicAlertModal);
    const checkAndReturnCorrectFilesAmountWithAdvise = (files) => {
        let filesToSend = files;
        if (!isAmountFilesLessOrEqualMaxFileAmount(filesToSend, MAX_AMOUNT_OF_FILE_TO_UPLOAD_AT_A_TIME)) {
            alertModal.show({
                title: t('modal.error_max_files_amount_to_upload.title'),
                description: t('modal.error_max_files_amount_to_upload.description'),
                buttons: {
                    ok: {
                        title: t('modal.error_max_files_amount_to_upload.buttons.ok'),
                    },
                },
            });
            filesToSend = sliceFilesToMaxAmount(filesToSend, MAX_AMOUNT_OF_FILE_TO_UPLOAD_AT_A_TIME);
        }
        return filesToSend;
    };
    return {
        checkAndReturnCorrectFilesAmountWithAdvise,
    };
};
