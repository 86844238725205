// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x9413SbxfWHHJQ4T0QEt{--hc-button-width: 2.125rem;--hc-button-height: 2.125rem;--hc-button-border-radius: 1.25rem;margin-left:var(--hc-size-spacing-4)}`, "",{"version":3,"sources":["webpack://./src/application/Layout/Default/components/HeaderActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,4BAAA,CACA,kCAAA,CACA,oCAAA","sourcesContent":[".button {\n  --hc-button-width: 2.125rem;\n  --hc-button-height: 2.125rem;\n  --hc-button-border-radius: 1.25rem;\n  margin-left: var(--hc-size-spacing-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `x9413SbxfWHHJQ4T0QEt`
};
export default ___CSS_LOADER_EXPORT___;
