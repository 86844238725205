// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PYmII6wUmdddI3Vk0gBH{margin-bottom:1.125rem;position:relative;width:23.875rem;display:flex;justify-content:center}.PYmII6wUmdddI3Vk0gBH .XyJnUdaKpMYvEXcTbfOj{position:absolute;z-index:1;margin:1rem;--hc-tag-padding: 0.125rem var(--hc-size-spacing-3)}`, "",{"version":3,"sources":["webpack://./src/ui/DetailsDrawer/components/DrawerImage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,sBAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CAEA,4CACI,iBAAA,CACA,SAAA,CACA,WAAA,CACA,mDAAA","sourcesContent":[".container{\n    margin-bottom: 1.125rem;\n    position: relative;\n    width: 23.875rem;\n    display: flex;\n    justify-content: center;\n\n    .tag{\n        position: absolute;\n        z-index: 1;\n        margin: 1rem;\n        --hc-tag-padding: 0.125rem var(--hc-size-spacing-3);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PYmII6wUmdddI3Vk0gBH`,
	"tag": `XyJnUdaKpMYvEXcTbfOj`
};
export default ___CSS_LOADER_EXPORT___;
