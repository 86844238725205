import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
export const ProfileMenuLink = ({ active, children, intentEvent, onClick, href, ...rest }) => {
    const handleTrackEvent = (e) => {
        intentEvent &&
            EventPrepAndTrackService.sendDebouncedInteractClick(intentEvent);
        onClick?.(e);
    };
    return (_jsx(Link, { to: href, className: classNames(styles.menuItem, { [styles.active]: active }), onClick: handleTrackEvent, ...rest, children: children }));
};
