const hotmartPlayerAccountIdKey = 'hotmart_player_account_id';
class AccountStorage {
    get currentAccountId() {
        return localStorage.getItem(hotmartPlayerAccountIdKey);
    }
    selectAccount(accountId) {
        localStorage.setItem(hotmartPlayerAccountIdKey, JSON.stringify(accountId));
    }
}
export default new AccountStorage();
