import CardChartContainer from './CardChartContainer';
import CardChartContentGauge from './CardChartContentGauge';
import CardChartContentRetention from './CardChartContentRetention';
import CardChartContentText from './CardChartContentText';
import CardChartHeader from './CardChartHeader';
import CardChartItem from './CardChartItem';
export const CardChart = {
    Container: CardChartContainer,
    Item: CardChartItem,
    Header: CardChartHeader,
    ContentText: CardChartContentText,
    ContentGauge: CardChartContentGauge,
    ContentRetention: CardChartContentRetention,
};
