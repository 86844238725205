import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useTranslation, Trans } from 'react-i18next';
import styles from './styles.module.scss';
export const MoveModalHeader = ({ itemsToMove, currentFolderName, itemsToBeMovedBelongToFolderWithName, goBackToParentFolder, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.heading, slot: 'heading', children: [_jsx("div", { className: styles.title, children: _jsx(Heading, { className: styles.heading, size: 'small', children: _jsx(TextTruncate, { children: _jsx(Trans, { i18nKey: itemsToMove.length > 1
                                ? 'library.modal.move_medias.title.items'
                                : 'library.modal.move_medias.title.media', values: itemsToMove.length > 1
                                ? { amount: itemsToMove.length }
                                : { name: itemsToMove[0].name }, components: { strong: _jsx("strong", {}) } }) }) }) }), itemsToBeMovedBelongToFolderWithName && (_jsxs("div", { className: styles.description, children: [_jsx(Paragraph, { size: 'small', className: styles.currentPlace, children: t('library.modal.move_medias.description') }), _jsx(Tag, { className: styles.tag, children: _jsx(TextTruncate, { children: _jsx(Paragraph, { size: 'nano', children: itemsToBeMovedBelongToFolderWithName }) }) })] })), currentFolderName && (_jsxs("div", { className: styles.folderNavigation, children: [_jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', size: 'large', onClick: goBackToParentFolder, className: styles.backButton, "data-testid": 'button-goback-to-parent', children: _jsx(FontAwesomeIcon, { icon: faArrowLeft }) }), _jsx("span", { children: currentFolderName })] }))] }));
};
