import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from 'src/domain/Media/modules/Subtitle/services/SubtitleService';
import { Toast } from 'src/ui/cosmos/Toast';
import { useTranslation } from 'react-i18next';
export const useTranslateSubtitle = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const onSuccess = (data, variables) => {
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.SUBTITLE, variables.mediaCode],
        });
    };
    const onError = () => {
        Toast.error({
            message: t('library.settings.subtitle.errors.send_subtitle'),
        });
    };
    const translateSubtitle = useMutation({
        mutationFn: ({ mediaCode, subtitleCode, language, }) => SubtitleService.translate({ mediaCode, subtitleCode, language }),
        onSuccess,
        onError,
    });
    return {
        translateSubtitle,
    };
};
