import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from '../../services/FoldersService';
const useFolderCount = () => {
    const getFolderCount = useCallback(async () => {
        const data = await FoldersService.getFolderCount();
        return data;
    }, []);
    return useQuery({
        queryKey: [QueryKeys.FOLDER_COUNT],
        queryFn: () => getFolderCount(),
    });
};
export default useFolderCount;
