import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { useUploadFile } from 'src/application/hooks/useUploadFile';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { debounce } from 'src/utils/debounce';
import { useTranslation } from 'react-i18next';
import { CONFIG_UPLOAD } from '../../constants';
import { NUMBER_OF_RETRIES_WHEN_ERROR, TIME_TO_WAIT_AFTER_UPLOAD, } from './constants';
export const useAddSubtitleToMedia = ({ mediaCode }) => {
    const { t } = useTranslation();
    const { uploadFile } = useUploadFile({
        apiUploadUrl: CONFIG_UPLOAD(mediaCode),
    });
    const queryClient = useQueryClient();
    const mutationAddSubtitle = useMutation({
        mutationFn: async ({ file, selectedLanguage }) => {
            return new Promise((resolve, reject) => {
                uploadFile({
                    file,
                    callbacks: {
                        onSuccess: () => {
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SUBTITLE_UPLOAD);
                            return debounce(resolve, TIME_TO_WAIT_AFTER_UPLOAD)(true);
                        },
                        onFail: () => {
                            return reject(new Error(t('library.settings.subtitle.errors.unknown')));
                        },
                    },
                    options: {
                        metaData: {
                            language: selectedLanguage,
                        },
                    },
                });
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SUBTITLE, mediaCode],
            });
        },
        retry: NUMBER_OF_RETRIES_WHEN_ERROR,
    });
    return {
        mutationAddSubtitle,
    };
};
