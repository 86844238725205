// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qDvYYBU3LtMVOnR_u2_p{display:flex;gap:.5rem}.qDvYYBU3LtMVOnR_u2_p .uGy3199gEtWQDM3ZHYqX{--hc-button-border-radius: 50%;--hc-button-padding: var(--hc-size-spacing-2) var(--hc-size-spacing-2);display:flex;align-items:center;justify-content:center}.qDvYYBU3LtMVOnR_u2_p .uGy3199gEtWQDM3ZHYqX>div{display:flex;align-items:center;justify-content:center}.qDvYYBU3LtMVOnR_u2_p .uGy3199gEtWQDM3ZHYqX>div svg{width:1rem;height:1rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/components/ActionsContainer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,SAAA,CAEA,4CACE,8BAAA,CACA,sEAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,gDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,oDACE,UAAA,CACA,WAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 0.5rem;\n\n  .button {\n    --hc-button-border-radius: 50%;\n    --hc-button-padding: var(--hc-size-spacing-2) var(--hc-size-spacing-2);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    > div {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n\n      svg {\n        width: 1rem;\n        height: 1rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qDvYYBU3LtMVOnR_u2_p`,
	"button": `uGy3199gEtWQDM3ZHYqX`
};
export default ___CSS_LOADER_EXPORT___;
