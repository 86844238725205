import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import ApiError from 'src/infrastructure/services/Api/ApiError';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { Toast } from 'src/ui/cosmos/Toast';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import FoldersService from 'src/domain/Folder/services/FoldersService';
import { useTranslation } from 'react-i18next';
export const useMoveItems = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const moveItem = useMutation({
        mutationFn: ({ folderDestination, items }) => {
            return FoldersService.moveItemsToFolder({
                sourceCodes: items.map((item) => item.code),
                targetFolderCode: folderDestination.code,
            });
        },
        onSuccess: (_, variables) => {
            const { items, folderDestination } = variables;
            const [firstItem] = items;
            const movedItemMessageDetail = {
                target_folder: folderDestination?.name,
            };
            const isMoreThenOneItem = items.length > 1;
            let message = t('library.modal.move_medias.notify.multipleItems', movedItemMessageDetail);
            if (!isMoreThenOneItem) {
                if (firstItem.category === 'folder') {
                    message = t('library.modal.move_medias.notify.one_folder', movedItemMessageDetail);
                }
                else {
                    message = t('library.modal.move_medias.notify.one_media', movedItemMessageDetail);
                }
            }
            Toast.info({ message });
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_MOVE_MEDIA);
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_MEDIA_LIST],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_ITEMS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SEARCH],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.LIBRARY],
            });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
    return {
        moveItem,
    };
};
