import { jsx as _jsx } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from 'src/ui/cosmos/Alert';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
export const LibraryAlert = () => {
    const { i18n } = useTranslation();
    return (_jsx(Alert, { className: `hot-alert--info ${styles.alert}`, dismissible: true, children: _jsx(Trans, { i18nKey: 'library.alert', components: {
                a: (_jsx(Link, { to: String(process.env.NEXT_PUBLIC_LIBRARY_HELP_LINK?.replace('{{lang}}', i18n.language)), target: '_blank', rel: 'noopener noreferrer', "data-testid": 'library-alert-anchor', className: 'hot-alert__link', onClick: () => EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_LINK_ARTICLE_HELP_CLICK) })),
            } }) }));
};
