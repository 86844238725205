// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qW59V17XZshA33fqi_mK{height:2.625rem;width:100%;padding:var(--hc-size-spacing-2) 0 var(--hc-size-spacing-2) var(--hc-size-spacing-4);border:1px solid var(--hc-color-neutral-300);border-radius:.25rem;background-color:#fff;color:var(--hc-color-neutral-800)}`, "",{"version":3,"sources":["webpack://./src/ui/Input/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,oFAAA,CAEA,4CAAA,CACA,oBAAA,CACA,qBAAA,CACA,iCAAA","sourcesContent":[".input {\n  height: 2.625rem;\n  width: 100%;\n  padding: var(--hc-size-spacing-2) 0 var(--hc-size-spacing-2)\n    var(--hc-size-spacing-4);\n  border: 1px solid var(--hc-color-neutral-300);\n  border-radius: 0.25rem;\n  background-color: white;\n  color: var(--hc-color-neutral-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `qW59V17XZshA33fqi_mK`
};
export default ___CSS_LOADER_EXPORT___;
