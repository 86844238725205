// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vVovgEB1ikxM_fpN5ztz{width:100%;padding:1rem 1.5rem 2.75rem 1.5rem;background:var(--hc-color-neutral-0);border-radius:.5rem;box-shadow:0px 1px 2px 0px rgba(0,0,0,.12);min-height:6.5rem;color:var(--hc-color-neutral-700)}.vVovgEB1ikxM_fpN5ztz .wl7VAOA6V5cUlcTClGG7{--hc-h-small-font-size: var(--hc-size-text-extralarge);--hc-h-small-md-font-size: var(--hc-size-text-extralarge);--hc-h-small-lg-font-size: var(--hc-size-text-extralarge);--hc-h-margin: 0}.vVovgEB1ikxM_fpN5ztz .W_QveQeetVsFZsRSxBk6{display:flex;align-items:center;justify-content:center;width:100%;height:100%;gap:.5rem}.vVovgEB1ikxM_fpN5ztz .W_QveQeetVsFZsRSxBk6 .UxEIwVWFAUPxHmkriSlC{width:.0625rem;height:75%;background:var(--hc-color-neutral-200);margin-inline:2rem}`, "",{"version":3,"sources":["webpack://./src/domain/User/Plan/containers/PlanInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,kCAAA,CACA,oCAAA,CACA,mBAAA,CACA,0CAAA,CACA,iBAAA,CACA,iCAAA,CAEA,4CACE,sDAAA,CACA,yDAAA,CACA,yDAAA,CACA,gBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAEA,kEACE,cAAA,CACA,UAAA,CACA,sCAAA,CACA,kBAAA","sourcesContent":[".container {\n  width: 100%;\n  padding: 1rem 1.5rem 2.75rem 1.5rem;\n  background: var(--hc-color-neutral-0);\n  border-radius: 0.5rem;\n  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);\n  min-height: 6.5rem;\n  color: var(--hc-color-neutral-700);\n\n  .title {\n    --hc-h-small-font-size: var(--hc-size-text-extralarge);\n    --hc-h-small-md-font-size: var(--hc-size-text-extralarge);\n    --hc-h-small-lg-font-size: var(--hc-size-text-extralarge);\n    --hc-h-margin: 0;\n  }\n  \n  .content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    gap: 0.5rem;\n\n    .separator {\n      width: .0625rem;\n      height: 75%;\n      background: var(--hc-color-neutral-200);\n      margin-inline: 2rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vVovgEB1ikxM_fpN5ztz`,
	"title": `wl7VAOA6V5cUlcTClGG7`,
	"content": `W_QveQeetVsFZsRSxBk6`,
	"separator": `UxEIwVWFAUPxHmkriSlC`
};
export default ___CSS_LOADER_EXPORT___;
