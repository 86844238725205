// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ieijFPLYl0Yt3OH1KDz6{display:flex;align-items:center;gap:.5rem;margin-top:1rem;word-break:break-word}.ieijFPLYl0Yt3OH1KDz6 .IS4Jo9x3To_ZDXYsQcEQ{--hc-h-margin: 0;font-size:var(--hc-size-text-3extralarge);font-weight:var(--hc-font-weight-light)}`, "",{"version":3,"sources":["webpack://./src/application/Layout/MediaSettingsLayout/components/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CACA,qBAAA,CAEA,4CACE,gBAAA,CACA,yCAAA,CACA,uCAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  margin-top: 1rem;\n  word-break: break-word;\n\n  .mediaName {\n    --hc-h-margin: 0;\n    font-size: var(--hc-size-text-3extralarge);\n    font-weight: var(--hc-font-weight-light);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ieijFPLYl0Yt3OH1KDz6`,
	"mediaName": `IS4Jo9x3To_ZDXYsQcEQ`
};
export default ___CSS_LOADER_EXPORT___;
