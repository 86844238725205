import Api from 'src/infrastructure/services/Api';
const initialMetrics = [
    'ENGAGEMENT',
    'PLAY_RATE',
    'LOADS',
    'VISITORS',
    'VIEWS',
    'HOURS_WATCHED',
];
const getAnalyticsData = async (mediaCode, metrics = initialMetrics) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/reports/metrics?${metrics
            .map((metric) => `metric=${encodeURIComponent(metric)}`)
            .join('&')}`,
    });
    return response.data.metrics;
};
export { getAnalyticsData };
