import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { TextTruncate } from 'src/ui/TextTruncate';
import { MediaThumb } from 'src/ui/MediaThumb';
import { FolderThumb } from 'src/ui/FolderThumb';
import NiceModal from '@ebay/nice-modal-react';
import { MediaPreviewModal } from 'src/domain/Media/components/Modal/MediaPreview';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
export const Card = ({ type, item }) => {
    return (_jsx(Link, { className: styles.card, "data-testid": 'item-card', to: `/library/${type}/${item.code}`, children: _jsxs("div", { children: [type === 'folder' ? (_jsx(FolderThumb, { size: 'small' })) : (_jsx(MediaThumb, { mediaCode: item.code, size: 'small', mediaType: item.type, onClick: (e) => {
                        e.preventDefault();
                        NiceModal.show(MediaPreviewModal, { media: item });
                    } })), _jsx(TextTruncate, { lines: 2, children: _jsx(Paragraph, { strong: true, size: 'micro', children: item.name }) })] }) }));
};
