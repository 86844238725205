import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState, Fragment } from 'react';
import { List } from 'src/ui/cosmosWeb/List';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useTranslation } from 'react-i18next';
import { SubtitleStatus } from '../../interfaces/subtitle';
import { ListItemLoading } from '../ListItemLoading';
import { ListItem } from '../ListItem';
import styles from './styles.module.scss';
export const SubtitleList = ({ mediaCode, languageList, onChangeEnable, onDelete, onDownload, }) => {
    const { t } = useTranslation();
    const listRef = useRef(null);
    const [languages, setLanguages] = useState([]);
    useEffect(() => {
        setLanguages(languageList);
    }, [languageList]);
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, { size: 'micro', strong: true, children: t('library.settings.subtitle.registered') }), _jsx(List, { className: styles.scrollListContainer, variation: 'boxes', ref: listRef, "data-testid": 'list-subtitle', children: languages.map((language) => (_jsx(Fragment, { children: language.status === SubtitleStatus.PROCESSING ? (_jsx(ListItemLoading, {})) : (_jsx(ListItem, { language: language, mediaCode: mediaCode, onDelete: onDelete, onDownload: onDownload, onToggle: onChangeEnable })) }, language.id))) })] }));
};
