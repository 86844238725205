import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useModal } from '@ebay/nice-modal-react';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faFolderArrowUp, faEdit, faTrashAlt, } from 'src/application/icons/fontAwesome/light';
import { faEllipsisVertical, faInfoCircle, } from 'src/application/icons/fontAwesome/regular';
import { useDeleteItems, useRenameFolder } from 'src/domain/Folder/hooks';
import { Dropdown, DropdownItem, DropdownHeader, } from 'src/ui/cosmosWeb/DropDown';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { useDetailsFolder } from 'src/domain/Folder/hooks/useDetailsFolder';
import { Can } from 'src/infrastructure/libs/CASL/can';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const FolderControls = ({ folder, onMove }) => {
    const renameFolderModalId = `rename-folder-modal-${folder.code}`;
    const renameFolderModal = useModal(renameFolderModalId);
    const { t } = useTranslation();
    const { showDetailsFolder } = useDetailsFolder();
    const deleteItems = useDeleteItems();
    const renameFolder = useRenameFolder();
    const onOpenMenuItem = () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_FOLDER);
    };
    const handleRenameFolder = async () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_RENAME_FOLDER);
        const newName = (await renameFolderModal.show());
        await renameFolder.mutateAsync({ folder, name: newName });
        renameFolderModal.hide();
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_RENAME_FOLDER);
    };
    return (_jsxs("div", { children: [_jsx(BasicPromptModal, { id: renameFolderModalId, title: t('general.rename'), value: folder.name, loading: renameFolder.isLoading, buttons: {
                    save: { label: t('general.save') },
                    cancel: { label: t('general.cancel') },
                } }), _jsxs(Dropdown, { className: styles.dropDown, onOpenDropdown: onOpenMenuItem, docked: true, position: 'end', "data-testid": 'item-folder-dropdown', children: [_jsx(IconButton, { type: 'button', slot: 'controls', variation: 'tertiary', context: 'neutral', size: 'small', className: styles.dropDownButton, icon: faEllipsisVertical }), _jsx(DropdownHeader, { slot: 'items', className: styles.dropdownHeaderItem, children: _jsx("div", { slot: 'label', children: t('library.header.more_options') }) }), _jsx(Can, { I: 'list', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'folder-controls-details', onClick: () => showDetailsFolder(folder.code), slot: 'items', children: [_jsx("div", { slot: 'icon', className: styles.detailsIcon, children: _jsx(FontAwesomeIcon, { icon: faInfoCircle }) }), _jsx("div", { slot: 'label', children: t('general.details') })] }) }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(DropdownItem, { slot: 'items', "data-testid": 'folder-control-rename', onClick: handleRenameFolder, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faEdit }) }), _jsx("div", { slot: 'label', children: t('general.rename') })] }) }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'folder-control-move', onClick: () => {
                                onMove();
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_MOVE_FOLDER);
                            }, slot: 'items', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faFolderArrowUp }) }), _jsx("div", { slot: 'label', children: t('general.move_to') })] }) }), _jsx(Can, { I: 'delete', a: 'Media', children: _jsxs(DropdownItem, { slot: 'items', "data-testid": 'folder-control-delete', onClick: () => {
                                deleteItems([folder]);
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_DELETE_FOLDER);
                            }, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faTrashAlt }) }), _jsx("div", { slot: 'label', children: t('general.exclude') })] }) })] })] }));
};
