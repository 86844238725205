import { useState } from 'react';
const useCopyToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);
    const copyToClipboard = (text) => {
        if (window.navigator.clipboard) {
            window.navigator.clipboard
                .writeText(text)
                .then(() => {
                setIsCopied(true);
            })
                .catch((error) => {
                console.error('Failed to copy:', error);
            });
        }
        else {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setIsCopied(true);
        }
    };
    return { isCopied, copyToClipboard };
};
export default useCopyToClipboard;
