// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XnhVensgnbqP4TxMHnBo{width:calc(100% - 1px);height:100%}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartContentRetention/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,WAAA","sourcesContent":[".container {\n  width: calc(100% - 1px);\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XnhVensgnbqP4TxMHnBo`
};
export default ___CSS_LOADER_EXPORT___;
