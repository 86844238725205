// cosmos v3 web components that will be dynamically imported
import '@hotmart-org-ca/cosmos/dist/loading';
import '@hotmart-org-ca/cosmos/dist/progress';
import '@hotmart-org-ca/cosmos/dist/nav/nav.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/alert';
import '@hotmart-org-ca/cosmos/dist/toast';
import '@hotmart-org-ca/cosmos/dist/structure';
import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/menu';
import '@hotmart-org-ca/cosmos/dist/menu-item';
import '@hotmart-org-ca/cosmos/dist/content';
const CosmosComponents = () => {
    return null;
};
export default CosmosComponents;
