import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import styles from './styles.module.scss';
export const ProfileMenuButton = ({ active, children, intentEvent, onClick, ...rest }) => {
    const handleTrackEvent = (e) => {
        intentEvent &&
            EventPrepAndTrackService.sendDebouncedInteractClick(intentEvent);
        onClick?.(e);
    };
    return (_jsx("button", { className: classNames(styles.button, { [styles.active]: active }), onClick: handleTrackEvent, ...rest, children: children }));
};
