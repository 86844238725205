import * as DomainEndpoint from 'src/infrastructure/endpoints/domain';
class DomainService {
    async addDomainsToMedias({ mediaCodes, domains }) {
        return DomainEndpoint.addDomainsToMedias({ mediaCodes, domains });
    }
    async getMediaDomains({ code }) {
        return DomainEndpoint.getMediaDomains({ code });
    }
    async deleteMediaDomains({ mediaDomainCodes }) {
        await DomainEndpoint.deleteMediaDomains({ mediaDomainCodes });
    }
}
export default new DomainService();
