import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { Toast } from 'src/ui/cosmos/Toast';
import ApiError from 'src/infrastructure/services/Api/ApiError';
import { modals } from 'src/ui/Modal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Trans, useTranslation } from 'react-i18next';
import FoldersService from '../../services/FoldersService';
import { translationLabels, MIX_CATEGORY_FOLDER_AND_MEDIA } from './constants';
const useDeleteItems = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const deleteItems = useMutation({
        mutationFn: (itemCodes) => FoldersService.deleteItems({
            itemCodes,
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.LIBRARY],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SEARCH],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FOLDER_ITEMS],
            });
            const message = t('modal.folder.delete.notify.success');
            Toast.success({ message });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
    const getPredominantCategoryFromAllItems = (items) => {
        const [firstItem] = items;
        return String(items.every((item) => item.category === firstItem.category)
            ? firstItem.category
            : MIX_CATEGORY_FOLDER_AND_MEDIA);
    };
    const confirmDeleteItems = useCallback((items) => {
        const itemsIdsToDelete = items.map((item) => item.code);
        const predominantCategory = getPredominantCategoryFromAllItems(items);
        const grammaticalNumbers = items.length > 1 ? 'many' : 'one';
        const labelsFromItemsCategory = translationLabels[predominantCategory]
            ? translationLabels[predominantCategory]
            : translationLabels.folder_and_media;
        const labelsForModalDelete = labelsFromItemsCategory[grammaticalNumbers];
        modals.confirmationDeleteItems({
            title: t(labelsForModalDelete.title),
            description: (_jsx(Trans, { i18nKey: labelsForModalDelete.description, components: { strong: _jsx("strong", {}) } })),
            checkConfirmDescription: t(`modal.library.delete_medias_and_folders.checkbox_label`),
            buttons: {
                yes: {
                    title: t('modal.library.delete_medias_and_folders.buttons.yes'),
                    onClick: async () => {
                        await deleteItems.mutateAsync(itemsIdsToDelete);
                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_DELETE_FOLDER_CONFIRMATION);
                    },
                },
                no: {
                    title: t('modal.library.delete_medias_and_folders.buttons.no'),
                },
            },
        });
    }, [deleteItems, t]);
    return confirmDeleteItems;
};
export default useDeleteItems;
