// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t8Cx7pmRPIX7vhNgiUKb{display:none}._FrH6I6Bzfkw5jKLdGZw{width:100%;--hc-button-border-radius: var(--hc-size-spacing-2)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/components/UploadFileButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,UAAA,CACA,mDAAA","sourcesContent":[".inputFileHidden {\n  display: none;\n}\n\n.sendFileButton {\n  width: 100%;\n  --hc-button-border-radius: var(--hc-size-spacing-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFileHidden": `t8Cx7pmRPIX7vhNgiUKb`,
	"sendFileButton": `_FrH6I6Bzfkw5jKLdGZw`
};
export default ___CSS_LOADER_EXPORT___;
