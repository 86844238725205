// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cE_Hv0AQEMIVplkabgTR{color:var(--hc-color-neutral-700);cursor:pointer;font-size:var(--hc-size-text-medium);font-weight:var(--hc-font-weight-regular);line-height:var(--hc-font-line-height-medium);transition:background-color var(--hc-timing, 500ms) var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1)),color var(--hc-timing, 500ms) var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1));align-items:center;display:flex;width:100%;background-color:rgba(0,0,0,0);gap:var(--hc-size-spacing-4);border:none;padding:0}.cE_Hv0AQEMIVplkabgTR:hover,.cE_Hv0AQEMIVplkabgTR:focus{background-color:var(--hc-color-info-100);border-radius:var(--hc-size-border-radius-medium);color:var(--hc-color-info-500);outline:0}`, "",{"version":3,"sources":["webpack://./src/ui/ProfileDrawer/components/ProfileMenu/ProfileMenuButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iCAAA,CACA,cAAA,CACA,oCAAA,CACA,yCAAA,CACA,6CAAA,CACA,+KAAA,CAGA,kBAAA,CACA,YAAA,CACA,UAAA,CACA,8BAAA,CACA,4BAAA,CACA,WAAA,CACA,SAAA,CAEA,wDAEE,yCAAA,CACA,iDAAA,CACA,8BAAA,CACA,SAAA","sourcesContent":[".button {\n  color: var(--hc-color-neutral-700);\n  cursor: pointer;\n  font-size: var(--hc-size-text-medium);\n  font-weight: var(--hc-font-weight-regular);\n  line-height: var(--hc-font-line-height-medium);\n  transition: background-color var(--hc-timing, 500ms)\n      var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1)),\n    color var(--hc-timing, 500ms) var(--hc-easing, cubic-bezier(0.2, 1, 0.2, 1));\n  align-items: center;\n  display: flex;\n  width: 100%;\n  background-color: transparent;\n  gap: var(--hc-size-spacing-4);\n  border: none;\n  padding: 0;\n\n  &:hover,\n  &:focus {\n    background-color: var(--hc-color-info-100);\n    border-radius: var(--hc-size-border-radius-medium);\n    color: var(--hc-color-info-500);\n    outline: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `cE_Hv0AQEMIVplkabgTR`
};
export default ___CSS_LOADER_EXPORT___;
