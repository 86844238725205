import Api from 'src/infrastructure/services/Api';
const userInfoUrl = `${process.env.USER_INFO_URL}/userprofile/rest/v1/user?onlySupportedLocales=true`;
const typeInfoUrl = `${process.env.TYPE_INFO_URL}/v1/user/type-info`;
const clusterInfoUrl = `${process.env.CLUSTER_INFO_URL}/rest/v1/cluster`;
const productsCountsUrl = `${process.env.PRODUCT_COUNTS_INFO_URL}/product/v1/product/count`;
async function getUserProfile() {
    const { data } = await Api.call({
        method: 'GET',
        url: `${userInfoUrl}`,
    });
    return data;
}
async function getTypeInfo() {
    const { data } = await Api.call({
        method: 'GET',
        url: `${typeInfoUrl}`,
    });
    return data;
}
async function getClusterInfo() {
    const { data } = await Api.call({
        method: 'GET',
        url: `${clusterInfoUrl}`,
    });
    return data;
}
async function getProductCountsInfo() {
    const { data } = await Api.call({
        method: 'GET',
        url: `${productsCountsUrl}`,
    });
    return data;
}
export { getUserProfile, getTypeInfo, getClusterInfo, getProductCountsInfo };
