import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faCircleQuestion } from 'src/application/icons/fontAwesome/regular';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FormField } from 'src/ui/cosmosWeb/FormField';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Input = (props) => {
    return _jsx("input", { ...props });
};
export const Form = ({ value, handleChangeValue, handleAddDomain, loading, displayInvalidMessage = false, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.form, children: [_jsxs(FormField, { className: styles.formField, children: [_jsx("label", { htmlFor: 'domain', slot: 'label', children: _jsxs(Tooltip, { text: t `library.settings.restriction.domain_url_more_info`, children: [t('library.settings.restriction.domain_url'), _jsx("sup", { children: _jsx(FontAwesomeIcon, { icon: faCircleQuestion }) })] }) }), _jsx(Input, { placeholder: t('library.settings.restriction.input_placeholder'), "data-testid": 'restriction-input', type: 'text', id: 'domain', value: value, onChange: (e) => handleChangeValue(e.target.value), invalid: displayInvalidMessage ? 'true' : false }), _jsx("div", { "data-testid": 'domain-form-invalid-message', slot: 'invalid-message', children: t('library.settings.restriction.errors.invalid_url') })] }), _jsx(Button, { "data-testid": 'add-domain-button', loading: loading, disabled: !value, onClick: handleAddDomain, className: classNames(styles.button, {
                    [styles.centralize]: displayInvalidMessage,
                }), children: t('general.add') })] }));
};
