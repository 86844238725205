import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Domain } from 'src/domain/Media/modules/Domain/container/Domain';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const DomainManagerDrawer = NiceModal.create(({ onDismiss, medias }) => {
    const { t } = useTranslation();
    const modal = useModal();
    return (_jsxs(Drawer, { opened: true, onCloseDrawer: () => {
            modal.remove();
            onDismiss?.();
        }, className: styles.drawer, position: 'end', "data-testid": 'domain-manager-drawer', children: [_jsx(Heading, { strong: true, slot: 'heading', size: 'small', children: t('general.restrictions') }), _jsx("div", { slot: 'content', children: _jsx(Domain, { medias: medias }) })] }));
});
