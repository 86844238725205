// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LqdKMHc6vkACyCmHOUh9{display:flex;flex-flow:row wrap;justify-content:center;gap:1rem;width:100%;max-width:100%}.LqdKMHc6vkACyCmHOUh9>div{max-height:9.375rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Analytics/components/CardChart/CardChartContainer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CAEA,0BACE,mBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  gap: 1rem;\n  width: 100%;\n  max-width: 100%;\n\n  & > div {\n    max-height: 9.375rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LqdKMHc6vkACyCmHOUh9`
};
export default ___CSS_LOADER_EXPORT___;
