import { DEFAULT_MESSAGE } from './constants';
const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = DEFAULT_MESSAGE);
};
const onPageHasUnsavedChanges = () => {
    window.addEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
    });
};
const onAllChangesSaved = () => {
    window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
    });
};
export { onPageHasUnsavedChanges, onAllChangesSaved };
